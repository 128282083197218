/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCommentInput = {
  campaign: number,
  sub_campaign: number,
  client: ClientInput,
  media_source: mediaSource,
  comment_type: commentType,
  comment: string,
  placeholder: string,
};

export type ClientInput = {
  code: string,
  country: country,
};

export enum country {
  DK = "DK",
  SE = "SE",
  NO = "NO",
  FI = "FI",
  ALL = "ALL",
  NC = "NC",
  GL = "GL",
}


export enum mediaSource {
  TV = "TV",
  RADIO = "RADIO",
  PRINT = "PRINT",
  DIGITAL = "DIGITAL",
  OOH = "OOH",
  OVERALL = "OVERALL",
  CINEMA = "CINEMA",
}


export enum commentType {
  COMMENT = "COMMENT",
  COVERAGE = "COVERAGE",
  READERS = "READERS",
  FREQUENCY = "FREQUENCY",
  TARGET_GROUP = "TARGET_GROUP",
  OVERALL = "OVERALL",
}


export type CommentResult = CommentWithStatus | Error


export type CommentWithStatus = {
  __typename: "CommentWithStatus",
  statusCode: number,
  comment?: Comment | null,
};

export type StatusCode = {
  __typename: "StatusCode",
  statusCode: number,
};

export type Error = {
  __typename: "Error",
  type: ErrorTypeEnum,
  message: string,
  statusCode: number,
};

export enum ErrorTypeEnum {
  UNAUTHORIZED = "UNAUTHORIZED",
  UNKNOWN = "UNKNOWN",
}


export type linkWithStatus = {
  __typename: "linkWithStatus",
  statusCode: number,
  link?: linkResponse | null,
};

export type linkResponse = {
  __typename: "linkResponse",
  Id?: string | null,
  title?: string | null,
  linkValue?: string | null,
  icon?: string | null,
  image?: string | null,
  clientCodes?:  Array<ClientCodes | null > | null,
};

export type ClientCodes = {
  __typename: "ClientCodes",
  ClientCode?: string | null,
  ClientCountry?: string | null,
};

export type Comment = {
  __typename: "Comment",
  campaign: number,
  sub_campaign: number,
  placeholder: string,
  media_source: mediaSource,
  comment_type: commentType,
  comment: string,
  created_at: string,
  last_edited_at: string,
  created_by: string,
  last_edited_by: string,
  state: commentState,
};

export enum commentState {
  CREATED = "CREATED",
  EDITED = "EDITED",
  REMOVED = "REMOVED",
}


export type EditCommentInput = {
  campaign: number,
  sub_campaign: number,
  client: ClientInput,
  media_source: mediaSource,
  comment_type: commentType,
  created_at: string,
  comment: string,
  placeholder: string,
};

export type RemoveCommentInput = {
  campaign: number,
  sub_campaign: number,
  client: ClientInput,
  media_source: mediaSource,
  comment_type: commentType,
  created_at: string,
  placeholder: string,
};

export type queryInput = {
  query: localQuery,
  views: Array< view | null >,
  jobnumber: string,
  clients?: Array< client | null > | null,
  date: dateDetail,
  user: userDetail,
  clearCache?: clearCacheEnum | null,
};

export type localQuery = {
  metrics?: Array< metric | null > | null,
  dimensions?: Array< string | null > | null,
  filters?: Array< filterDetail | null > | null,
  percentiles?: Array< percentileDetail | null > | null,
  calculatedMetrics?: Array< calculatedMetricsDetail | null > | null,
  detailedDimensions?: Array< detailedDimensionsDetail | null > | null,
  additionalInfo?: Array< string | null > | null,
  nullColumns?: Array< string | null > | null,
  orderBy?: Array< string | null > | null,
  preview: number,
};

export type metric = {
  column: string,
  name: string,
  aggregation: metricFunction,
  id?: string | null,
  decimals?: number | null,
};

export enum metricFunction {
  SUM = "SUM",
  AVG = "AVG",
  MIN = "MIN",
  MAX = "MAX",
}


export type filterDetail = {
  column: string,
  filter?: filter | null,
};

export type filter = {
  filtertype: filtertype,
  datatype: datatype,
  value?: string | null,
  list?: Array< string | null > | null,
  from?: string | null,
  to?: string | null,
};

export enum filtertype {
  EQUAL = "EQUAL",
  LT = "LT",
  LTE = "LTE",
  GT = "GT",
  GTE = "GTE",
  BETWEEN = "BETWEEN",
  IN = "IN",
  NOTIN = "NOTIN",
}


export enum datatype {
  STRING = "STRING",
  NUMBER = "NUMBER",
  DATE = "DATE",
}


export type percentileDetail = {
  x?: percentile | null,
  y?: percentile | null,
  percentile?: Array< number | null > | null,
};

export type percentile = {
  column: string,
  name: string,
};

export type calculatedMetricsDetail = {
  name: string,
  query: string,
  id?: string | null,
  decimals?: number | null,
  userDefined?: boolean | null,
};

export type detailedDimensionsDetail = {
  column: string,
  name: string,
  hidden: boolean,
  id?: string | null,
  rename?: Array< renameDimensionValue | null > | null,
};

export type renameDimensionValue = {
  before: string,
  after: string,
};

export type view = {
  viewName: string,
  database: string,
};

export type client = {
  country: countryEnum,
  client: string,
};

export enum countryEnum {
  DK = "DK",
  SE = "SE",
  NO = "NO",
  FI = "FI",
  ALL = "ALL",
  NC = "NC",
  GL = "GL",
}


export type dateDetail = {
  from?: string | null,
  to?: string | null,
};

export type userDetail = {
  name: string,
  agency?: agencyEnum | null,
  country?: countryEnum | null,
  accessToken?: string | null,
};

export enum agencyEnum {
  OMD = "OMD",
  PHD = "PHD",
  HS = "HS",
  ANNA = "ANNA",
  RESO = "RESO",
}


export enum clearCacheEnum {
  KEEP = "KEEP",
  CLEAR = "CLEAR",
}


export type QueryInfo = {
  __typename: "QueryInfo",
  id: string,
  status: statusEnum,
  submitted?: string | null,
  outputlocation?: string | null,
  bytes_scanned?: string | null,
  user_name?: string | null,
  source?: string | null,
  bucket?: string | null,
  query?: string | null,
  error?: number | null,
  message?: string | null,
};

export enum statusEnum {
  SUBMITTED = "SUBMITTED",
  RUNNING = "RUNNING",
  ERROR = "ERROR",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
}


export type StatusInfo = {
  __typename: "StatusInfo",
  id?: string | null,
  status?: statusEnum | null,
};

export type MetricsInfo = {
  chartType?: string | null,
  metric?: string | null,
  axis?: number | null,
};

export type HttpResponse = {
  __typename: "HttpResponse",
  status?: string | null,
  message?: string | null,
};

export type PublishObject = {
  campaignId: string,
  subCampaignId?: string | null,
  dashboardType: DashboardTypes,
  placeholders: Array< PlaceholderObject | null >,
  clientCode: string,
  clientCountry: string,
};

export enum DashboardTypes {
  TV = "TV",
  RADIO = "RADIO",
  PRINT = "PRINT",
  OOH = "OOH",
  DIGITAL = "DIGITAL",
  FLOWCHART = "FLOWCHART",
  HOME = "HOME",
  CINEMA = "CINEMA",
  BVOD = "BVOD",
  empty = "empty",
}


export type PlaceholderObject = {
  position?: string | null,
  query_id?: string | null,
  query_id_cc?: string | null,
  outputlocation?: string | null,
  outputlocationTotal?: string | null,
  outputlocation_cc?: string | null,
  outputlocationTotal_cc?: string | null,
  title?: string | null,
  metrics?: Array< MetricsInfo | null > | null,
};

export enum Environment {
  DEMO = "DEMO",
  STAGING = "STAGING",
  PRODUCTION = "PRODUCTION",
}


export type UnPublishObject = {
  campaignId?: string | null,
  clientCode?: string | null,
  clientCountry?: string | null,
};

export type responseStatus = {
  __typename: "responseStatus",
  Message?: string | null,
  StatusCode?: number | null,
};

export type addUserClientInput = {
  ClientCode: string,
  ClientCountry: string,
  AccessType: AccessType,
};

export enum AccessType {
  FULL = "FULL",
  READ = "READ",
  READ_PLUS = "READ_PLUS",
  NONE = "NONE",
}


export type UserHttpResponse = {
  __typename: "UserHttpResponse",
  status?: string | null,
  message?: string | null,
  username?: string | null,
};

export type ClientsInput = {
  ClientCode?: string | null,
  ClientCountry: string,
};

export type StatementResponseStatus = {
  __typename: "StatementResponseStatus",
  Message?: string | null,
  StatusCode?: number | null,
  StatementId?: string | null,
};

export type TemplatePlaceholderInput = {
  Location: string,
  Location_cc?: string | null,
  Placeholder: string,
  Placeholder_titel?: string | null,
  OrderIndex?: number | null,
  metrics?: Array< MetricsInfo | null > | null,
};

export type CustomPlaceholderInput = {
  campaignId: number,
  label?: string | null,
  dashboardType: string,
  position: string,
  floatValue?: number | null,
  subtitle?: string | null,
  format?: string | null,
};

export enum FlowchartCampaignStatus {
  CREATED = "CREATED",
  PLANNING = "PLANNING",
  ONGOING = "ONGOING",
  FINISHED = "FINISHED",
  NOT_BRIEFED = "NOT_BRIEFED",
  BRIEFED_FROM_CLIENT = "BRIEFED_FROM_CLIENT",
  BRIEF_COMPLETED = "BRIEF_COMPLETED",
  PLANNING_STARTED = "PLANNING_STARTED",
  PLANNING_DONE = "PLANNING_DONE",
  CAMPAIGN_PARTLY_PUBLISHED = "CAMPAIGN_PARTLY_PUBLISHED",
  CAMPAIGN_PUBLISHED = "CAMPAIGN_PUBLISHED",
}


export enum Severity {
  Warning = "Warning",
  Error = "Error",
}


export enum OverviewStatus {
  Error = "Error",
  InProgress = "InProgress",
  Resolved = "Resolved",
}


export type overviewStatusResponse = {
  __typename: "overviewStatusResponse",
  source: string,
  severity: Severity,
  status: OverviewStatus,
  message?: string | null,
  client?: string | null,
  campaignId?: string | null,
  updatedAt?: string | null,
};

export enum UserMode {
  DEVELOP = "DEVELOP",
  PRESENTATION = "PRESENTATION",
}


export type SwitchUserModeResponse = {
  __typename: "SwitchUserModeResponse",
  Client?: string | null,
  Status?: responseStatus | null,
};

export type BriefColumn = {
  Section: string,
  Name: string,
  Type: HTMLType,
  ValueType: ValueTypeEnum,
  Description: string,
  Required: boolean,
  Placeholder: string,
};

export enum HTMLType {
  TEXTAREA = "TEXTAREA",
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE = "DATE",
  TIME = "TIME",
  CHECKBOX = "CHECKBOX",
  URL = "URL",
  SELECT = "SELECT",
}


export enum ValueTypeEnum {
  StringValue = "StringValue",
  IntValue = "IntValue",
  BooleanValue = "BooleanValue",
  FloatValue = "FloatValue",
}


export type BriefValue = {
  Section: string,
  Label: string,
  Placeholder: string,
  Value: valueTypes,
  Type: HTMLType,
};

export type valueTypes = {
  StringValue?: string | null,
  IntValue?: number | null,
  BooleanValue?: boolean | null,
  FloatValue?: number | null,
};

export type DetailedPlanningColumn = {
  Name: string,
  ValueType: ValueTypeEnum,
  Type: HTMLType,
  Description: string,
  Required: boolean,
  Placeholder: string,
};

export type PlanningSectionsInput = {
  Section: string,
  Rows?: Array< PlanningRowsInput | null > | null,
};

export type PlanningRowsInput = {
  Columns?: Array< DetailedPlanningValue | null > | null,
};

export type DetailedPlanningValue = {
  ColumnId: string,
  Value: valueTypes,
};

export type DetailedPlanningOrderResponse = {
  __typename: "DetailedPlanningOrderResponse",
  tableOrder?: tableOrderResponse | null,
};

export type tableOrderResponse = {
  __typename: "tableOrderResponse",
  rows?:  Array<sectionOrderResponse | null > | null,
};

export type sectionOrderResponse = {
  __typename: "sectionOrderResponse",
  section: string,
  values?:  Array<rowOrderResponse | null > | null,
};

export type rowOrderResponse = {
  __typename: "rowOrderResponse",
  rowId: string,
};

export type tableOrderInput = {
  rows?: Array< sectionOrderInput | null > | null,
};

export type sectionOrderInput = {
  section: string,
  values?: Array< rowOrderInput | null > | null,
};

export type rowOrderInput = {
  rowId: string,
};

export type updateDetailedPlanningInput = {
  RowId: string,
  ColumnId: string,
  Value: valueTypes,
};

export enum DashboardSection {
  TABLE = "TABLE",
  CHART = "CHART",
  IMAGES = "IMAGES",
}


export type QueryOrderInput = {
  QueryId: string,
  Order: number,
};

export type CreateLinkInput = {
  title?: string | null,
  linkValue?: string | null,
  icon?: string | null,
  image?: string | null,
};

export type linkResult = linkWithStatus | Error


export type UpdateLinkInput = {
  title?: string | null,
  linkValue?: string | null,
  icon?: string | null,
  image?: string | null,
};

export type LoggingResponse = {
  __typename: "LoggingResponse",
  Message?: string | null,
  StatusCode?: string | null,
};

export type UserInfo = {
  __typename: "UserInfo",
  PK?: string | null,
  Name?: string | null,
};

export type DataSourcesInfo_DEPRECATED = {
  __typename: "DataSourcesInfo_DEPRECATED",
  source_name?: string | null,
  sources?:  Array<SourcesInfo | null > | null,
};

export type SourcesInfo = {
  __typename: "SourcesInfo",
  name?: string | null,
  view?: string | null,
  metrics?:  Array<MetricInfo | null > | null,
  dimensions?: Array< string | null > | null,
};

export type MetricInfo = {
  __typename: "MetricInfo",
  name?: string | null,
  format?: FormatEnum | null,
  decimals?: number | null,
};

export enum FormatEnum {
  NUMBER = "NUMBER",
  PERCENT = "PERCENT",
  DATE = "DATE",
}


export type DataSourcesInfo = {
  __typename: "DataSourcesInfo",
  source_name?: string | null,
  metrics?:  Array<Metrics | null > | null,
  dimensions?:  Array<Dimensions | null > | null,
};

export type Metrics = {
  __typename: "Metrics",
  metric?: string | null,
  description?: string | null,
  view?: Array< string | null > | null,
};

export type Dimensions = {
  __typename: "Dimensions",
  dimension?: string | null,
  description?: string | null,
  view?: Array< string | null > | null,
};

export type ClientLookUpList = {
  __typename: "ClientLookUpList",
  OrganizationId?: string | null,
  Country?: string | null,
  ClientCodes?: Array< string | null > | null,
};

export type ClientLookUpListBeta = {
  __typename: "ClientLookUpListBeta",
  Organization?: string | null,
  OrganizationId?: string | null,
  Country?: string | null,
  ClientCodes?: Array< string | null > | null,
};

export type campaigns = {
  __typename: "campaigns",
  foundCampaigns?:  Array<foundCampaigns | null > | null,
  notFoundCampaigns?:  Array<notFoundCampaigns | null > | null,
};

export type foundCampaigns = {
  __typename: "foundCampaigns",
  campaignId?: number | null,
  name?: string | null,
};

export type notFoundCampaigns = {
  __typename: "notFoundCampaigns",
  client?: string | null,
  country?: string | null,
};

export type campaignsV2 = {
  __typename: "campaignsV2",
  PK?: string | null,
  SK?: string | null,
  campaignId?: number | null,
  campaignName?: string | null,
  clientCode?: string | null,
  clientCountry?: string | null,
  comments?: string | null,
  endDate?: string | null,
  startDate?: string | null,
  deleted?: string | null,
  additionalInfo?: string | null,
  additionalInfo2?: string | null,
  createdBy?: string | null,
  briefedBudget?: number | null,
};

export type subCampaignResponse = {
  __typename: "subCampaignResponse",
  subcampaignId?: number | null,
  name?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  mediaGroup?: string | null,
};

export type campaignInfo = {
  __typename: "campaignInfo",
  foundCampaigns?:  Array<foundCampaignsInfo | null > | null,
};

export type foundCampaignsInfo = {
  __typename: "foundCampaignsInfo",
  startDate?: string | null,
  endDate?: string | null,
  createdBy?: string | null,
  comments?: string | null,
  additionalInfo?: string | null,
  additionalInfo2?: string | null,
  campaignId?: number | null,
  name?: string | null,
  clientCode?: clientCode | null,
};

export type clientCode = {
  __typename: "clientCode",
  client?: string | null,
  country?: string | null,
};

export type UserQuery = {
  __typename: "UserQuery",
  campaign_id?: string | null,
  position?: string | null,
  query_id?: string | null,
  query_id_cc?: string | null,
  title?: string | null,
  metrics?:  Array<MetricsResponse | null > | null,
};

export type MetricsResponse = {
  __typename: "MetricsResponse",
  chartType?: string | null,
  metric?: string | null,
  axis?: number | null,
};

export type PublishedOutput = {
  __typename: "PublishedOutput",
  campaign_id?: string | null,
  placeholders?:  Array<Placeholder | null > | null,
};

export type Placeholder = {
  __typename: "Placeholder",
  position?: string | null,
  query_id?: string | null,
  query_id_cc?: string | null,
  outputlocation?: string | null,
  outputlocationTotal?: string | null,
  outputlocation_cc?: string | null,
  outputlocationTotal_cc?: string | null,
  title?: string | null,
  dashboardType?: DashboardTypes | null,
  publishedAt?: string | null,
  publishedBy?: string | null,
  metrics?:  Array<MetricsResponse | null > | null,
};

export type publishedCampaignsResponse = {
  __typename: "publishedCampaignsResponse",
  campaignId?: string | null,
  subCampaignId?: string | null,
};

export type publishedCampaignsResponse_v2 = {
  __typename: "publishedCampaignsResponse_v2",
  campaignId?: string | null,
  subCampaignId?: string | null,
  published?: publishedType | null,
};

export type publishedType = {
  __typename: "publishedType",
  TV?: boolean | null,
  RADIO?: boolean | null,
  PRINT?: boolean | null,
  OOH?: boolean | null,
  DIGITAL?: boolean | null,
  CINEMA?: boolean | null,
};

export enum UserType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}


export enum StatementType {
  Access = "Access",
  Paddington = "Paddington",
  Grant = "Grant",
}


export type getUserType = {
  __typename: "getUserType",
  Username?: string | null,
  Name?: string | null,
  Family_name?: string | null,
  Email?: string | null,
  HasFullAccess?:  Array<Clients | null > | null,
  HasReadAccess?:  Array<Clients | null > | null,
  HasNoAccess?:  Array<Clients | null > | null,
};

export type Clients = {
  __typename: "Clients",
  ClientCode?: string | null,
  ClientCountry?: string | null,
};

export type LimitType = {
  ClientLimit: number,
  ClientLimitStart: number,
};

export type getUserTypeV2Response = {
  __typename: "getUserTypeV2Response",
  Data?:  Array<getUserTypeV2 | null > | null,
  Meta?: MetaInfoResponse | null,
};

export type getUserTypeV2 = {
  __typename: "getUserTypeV2",
  Username?: string | null,
  Name?: string | null,
  Family_name?: string | null,
  Email?: string | null,
  Access?:  Array<userAccessType | null > | null,
};

export type userAccessType = {
  __typename: "userAccessType",
  Level?: string | null,
  Clients?:  Array<Clients | null > | null,
};

export type MetaInfoResponse = {
  __typename: "MetaInfoResponse",
  Clients?: ClientMetaInfo | null,
};

export type ClientMetaInfo = {
  __typename: "ClientMetaInfo",
  returned?: number | null,
  total?: number | null,
  start_index?: number | null,
};

export type StatementsFromClient = {
  __typename: "StatementsFromClient",
  Comment?: CommentStatements | null,
  Queries?: QueriesStatement | null,
};

export type CommentStatements = {
  __typename: "CommentStatements",
  READ?: boolean | null,
  WRITE?: boolean | null,
};

export type QueriesStatement = {
  __typename: "QueriesStatement",
  READ?: boolean | null,
  CREATE?: boolean | null,
  PUBLISH?: boolean | null,
  UNPUBLISH?: boolean | null,
};

export enum ActionEnum {
  WRITE = "WRITE",
  CREATE = "CREATE",
  READ = "READ",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
  LIST = "LIST",
  PUBLISH = "PUBLISH",
  UNPUBLISH = "UNPUBLISH",
  DISPLAY = "DISPLAY",
}


export type UserIsAuthorizedType = {
  __typename: "UserIsAuthorizedType",
  Client?: Clients | null,
  Authorized?: boolean | null,
};

export type AudienceReportInfo = {
  __typename: "AudienceReportInfo",
  campaignId?: string | null,
  outputlocation?: string | null,
};

export type PortalDashboardResponse = {
  __typename: "PortalDashboardResponse",
  activities?:  Array<PortalDashboardItem | null > | null,
  insights?:  Array<PortalDashboardItem | null > | null,
};

export type PortalDashboardItem = {
  __typename: "PortalDashboardItem",
  name?: string | null,
  dashboard?: string | null,
  url?: string | null,
  internal_launch?: string | null,
  external_launch?: string | null,
  accessible?: boolean | null,
  sortOrder?: number | null,
  color?: string | null,
  description?: string | null,
};

export type UserConcerns = {
  __typename: "UserConcerns",
  Organization?: string | null,
  OrganizationId?: string | null,
};

export enum S3Method {
  put_object = "put_object",
  get_object = "get_object",
}


export type PreSignedUrlRsponse = {
  __typename: "PreSignedUrlRsponse",
  status?: number | null,
  message?: string | null,
  url?: string | null,
};

export type getInsightsDashboardsResponse = {
  __typename: "getInsightsDashboardsResponse",
  ClientCode?: string | null,
  ClientCountry?: string | null,
  Resources?:  Array<ResourceResponse | null > | null,
};

export type ResourceResponse = {
  __typename: "ResourceResponse",
  Dashboard?: string | null,
  Access?: boolean | null,
};

export type UserStatementType = {
  __typename: "UserStatementType",
  Users?:  Array<UserWithActionListStatement | null > | null,
  Clients?:  Array<ClientCodes | null > | null,
};

export type UserWithActionListStatement = {
  __typename: "UserWithActionListStatement",
  Username?: string | null,
  Name?: string | null,
  Family_name?: string | null,
  Email?: string | null,
  Statements?:  Array<ActionListStatement | null > | null,
};

export type ActionListStatement = {
  __typename: "ActionListStatement",
  Service?: string | null,
  Resource?: string | null,
  Action?: Array< string | null > | null,
  ClientCode?: string | null,
  ClientCountry?: string | null,
  Environment?: string | null,
};

export type ListTemplateResponse = {
  __typename: "ListTemplateResponse",
  Name?: string | null,
  Id?: string | null,
  CreatedBy?: string | null,
  CreatedAt?: string | null,
  LastEditedBy?: string | null,
  LastEditedAt?: string | null,
};

export type ClientCode = {
  client: string,
  country: string,
};

export type TemplateResponse = {
  __typename: "TemplateResponse",
  Name?: string | null,
  Id?: string | null,
  CreatedBy?: string | null,
  CreatedAt?: string | null,
  LastEditedBy?: string | null,
  LastEditedAt?: string | null,
  Placeholders?:  Array<TemplatePlaceholder | null > | null,
};

export type TemplatePlaceholder = {
  __typename: "TemplatePlaceholder",
  Placeholder?: string | null,
  Placeholder_titel?: string | null,
  S3Location?: string | null,
  S3Location_cc?: string | null,
  OrderIndex?: number | null,
  Metrics?:  Array<MetricsResponse | null > | null,
};

export type getCampaignsNewResponse = {
  __typename: "getCampaignsNewResponse",
  foundCampaigns?:  Array<campaignResponse | null > | null,
  notFoundCampaigns?:  Array<campaignResponse | null > | null,
};

export type campaignResponse = {
  __typename: "campaignResponse",
  campaign?: string | null,
  campaignId?: number | null,
  ClientCode?: string | null,
  ClientCountry?: string | null,
  ClientName?: string | null,
};

export type publishedCampaignResponse = {
  __typename: "publishedCampaignResponse",
  campaignId?: string | null,
  subCampaignId?: string | null,
  dashboardType?: DashboardTypes | null,
};

export type CustomPlaceholderValue = {
  __typename: "CustomPlaceholderValue",
  campaignId?: number | null,
  label?: string | null,
  dashboardType?: string | null,
  position?: string | null,
  floatValue?: number | null,
  status?: CustomPlaceholderValueStatusEnum | null,
  createdBy?: string | null,
  createdAt?: string | null,
  format?: string | null,
  subtitle?: string | null,
};

export enum CustomPlaceholderValueStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
}


export type flowchartCampaign = {
  __typename: "flowchartCampaign",
  campaignId: number,
  campaign: string,
  startDate: string,
  endDate: string,
  ClientCode: string,
  ClientCountry: string,
  ClientName: string,
  status: string,
  createdBy: string,
  createdAt?: string | null,
  briefed_amount?: number | null,
  briefed_currency?: string | null,
  briefed_type?: string | null,
  briefed_vat_info?: string | null,
  additionalInfo?: string | null,
  additionalInfo2?: string | null,
  comment?: string | null,
  tagIds?: Array< number | null > | null,
  recommended_budget_ctc?: number | null,
  approved_budget?: number | null,
  actual_fee?: number | null,
  planned_fee?: number | null,
};

export type flowchartCampaignWithType = {
  __typename: "flowchartCampaignWithType",
  campaignId: number,
  campaign: string,
  startDate: string,
  endDate: string,
  ClientCode: string,
  ClientCountry: string,
  ClientName: string,
  status: string,
  dashboardType: Array< DashboardTypes | null >,
  createdBy: string,
  createdAt?: string | null,
  briefed_amount?: number | null,
  briefed_currency?: string | null,
  briefed_type?: string | null,
  briefed_vat_info?: string | null,
  additionalInfo?: string | null,
  additionalInfo2?: string | null,
  comment?: string | null,
  budget?: string | null,
  tagIds?: Array< number | null > | null,
  recommended_budget_ctc?: number | null,
  approved_budget?: number | null,
  actual_fee?: number | null,
  planned_fee?: number | null,
};

export type flowchartClient = {
  __typename: "flowchartClient",
  campaignId: string,
  datasource: string,
  client: string,
  name: string,
  type?: string | null,
  status?: string | null,
};

export type briefTemplateResponse = {
  __typename: "briefTemplateResponse",
  template?: string | null,
  templateId?: string | null,
  version?: number | null,
  createdAt?: string | null,
  createdBy?: string | null,
  sections?:  Array<briefTemplateSection | null > | null,
};

export type briefTemplateSection = {
  __typename: "briefTemplateSection",
  sectionName?: string | null,
  columns?:  Array<briefTemplateSectionColumns | null > | null,
};

export type briefTemplateSectionColumns = {
  __typename: "briefTemplateSectionColumns",
  name?: string | null,
  label?: string | null,
  description?: string | null,
  type?: HTMLType | null,
  id?: string | null,
  required?: boolean | null,
  placeholder?: string | null,
  valueType?: string | null,
  value?: ValueResponse | null,
  subColumns?:  Array<briefTemplateSubColumn | null > | null,
};

export type ValueResponse = {
  __typename: "ValueResponse",
  StringValue?: string | null,
  IntValue?: number | null,
  BooleanValue?: boolean | null,
  FloatValue?: number | null,
};

export type briefTemplateSubColumn = {
  __typename: "briefTemplateSubColumn",
  name?: string | null,
  label?: string | null,
  description?: string | null,
  id?: string | null,
  type?: string | null,
  required?: boolean | null,
  valueType?: string | null,
  value?: ValueResponse | null,
  placeholder?: string | null,
};

export type ClientTagsResponse = {
  __typename: "ClientTagsResponse",
  tagDescription?: string | null,
  tagGroup?: string | null,
  tagId?: number | null,
  tagName?: string | null,
};

export type TagsResponse = {
  __typename: "TagsResponse",
  Client?: string | null,
  Country?: string | null,
  Tags?:  Array<ClientTagsResponse | null > | null,
};

export type BriefSection = {
  Section: string,
  Label?: string | null,
};

export type getBreifResponse = {
  __typename: "getBreifResponse",
  id?: string | null,
  createdAt?: string | null,
  createdBy?: string | null,
  type?: HTMLType | null,
  valueType?: string | null,
  sectionName?: string | null,
  label?: string | null,
  stringValue?: string | null,
  intValue?: number | null,
  booleanValue?: boolean | null,
  floatValue?: number | null,
};

export type detailedPlanningResponse = {
  __typename: "detailedPlanningResponse",
  templateId?: string | null,
  templateName?: string | null,
  columns?:  Array<detailedPlanningColumns | null > | null,
  rows?:  Array<detailedPlanningRows | null > | null,
};

export type detailedPlanningColumns = {
  __typename: "detailedPlanningColumns",
  name?: string | null,
  id?: string | null,
  type?: HTMLType | null,
  valueType?: string | null,
};

export type detailedPlanningRows = {
  __typename: "detailedPlanningRows",
  section?: string | null,
  values?:  Array<detailedPlanningSections | null > | null,
};

export type detailedPlanningSections = {
  __typename: "detailedPlanningSections",
  rowId?: string | null,
  columns?:  Array<detailedPlanningValues | null > | null,
};

export type detailedPlanningValues = {
  __typename: "detailedPlanningValues",
  columnId?: string | null,
  valueType?: string | null,
  value?: ValueResponse | null,
};

export type QueryOrderResponse = {
  __typename: "QueryOrderResponse",
  QueryId: string,
  Order: number,
};

export type linkListsResponse = {
  __typename: "linkListsResponse",
  order?: Array< string | null > | null,
  links?:  Array<linkResponse | null > | null,
};

export type ClientProjectLevelResponse = {
  __typename: "ClientProjectLevelResponse",
  ClientCode?: string | null,
  ClientCountry?: string | null,
  Level?: string | null,
  Status?: string | null,
  Updated_at?: string | null,
  Updated_by?: string | null,
};

export type CreateCommentMutationVariables = {
  comment: CreateCommentInput,
};

export type CreateCommentMutation = {
  createComment: ( {
      __typename: "CommentWithStatus",
      statusCode: number,
      comment?:  {
        __typename: string,
        campaign: number,
        sub_campaign: number,
        placeholder: string,
        media_source: mediaSource,
        comment_type: commentType,
        comment: string,
        created_at: string,
        last_edited_at: string,
        created_by: string,
        last_edited_by: string,
        state: commentState,
      } | null,
    } | {
      __typename: "Error",
      type: ErrorTypeEnum,
      message: string,
      statusCode: number,
    }
  ) | null,
};

export type EditCommentMutationVariables = {
  comment: EditCommentInput,
};

export type EditCommentMutation = {
  editComment: ( {
      __typename: "CommentWithStatus",
      statusCode: number,
      comment?:  {
        __typename: string,
        campaign: number,
        sub_campaign: number,
        placeholder: string,
        media_source: mediaSource,
        comment_type: commentType,
        comment: string,
        created_at: string,
        last_edited_at: string,
        created_by: string,
        last_edited_by: string,
        state: commentState,
      } | null,
    } | {
      __typename: "Error",
      type: ErrorTypeEnum,
      message: string,
      statusCode: number,
    }
  ) | null,
};

export type RemoveCommentMutationVariables = {
  comment: RemoveCommentInput,
};

export type RemoveCommentMutation = {
  removeComment: ( {
      __typename: "CommentWithStatus",
      statusCode: number,
      comment?:  {
        __typename: string,
        campaign: number,
        sub_campaign: number,
        placeholder: string,
        media_source: mediaSource,
        comment_type: commentType,
        comment: string,
        created_at: string,
        last_edited_at: string,
        created_by: string,
        last_edited_by: string,
        state: commentState,
      } | null,
    } | {
      __typename: "Error",
      type: ErrorTypeEnum,
      message: string,
      statusCode: number,
    }
  ) | null,
};

export type StartQueryMutationVariables = {
  query: queryInput,
};

export type StartQueryMutation = {
  startQuery?:  {
    __typename: "QueryInfo",
    id: string,
    status: statusEnum,
    submitted?: string | null,
    outputlocation?: string | null,
    bytes_scanned?: string | null,
    user_name?: string | null,
    source?: string | null,
    bucket?: string | null,
    query?: string | null,
    error?: number | null,
    message?: string | null,
  } | null,
};

export type SetStatusMutationVariables = {
  id: string,
  status?: statusEnum | null,
};

export type SetStatusMutation = {
  setStatus?:  {
    __typename: "StatusInfo",
    id?: string | null,
    status?: statusEnum | null,
  } | null,
};

export type StoreQueriesMutationVariables = {
  campaign_id: string,
  position: string,
  query_id: string,
  query_id_cc?: string | null,
  title?: string | null,
  metrics?: Array< MetricsInfo | null > | null,
};

export type StoreQueriesMutation = {
  storeQueries?:  {
    __typename: "HttpResponse",
    status?: string | null,
    message?: string | null,
  } | null,
};

export type PublishOutputLocationMutationVariables = {
  publishInput: PublishObject,
  Environment: Environment,
};

export type PublishOutputLocationMutation = {
  publishOutputLocation?: string | null,
};

export type PublishOutputLocation_v2MutationVariables = {
  publishInput: PublishObject,
  Environment: Environment,
};

export type PublishOutputLocation_v2Mutation = {
  publishOutputLocation_v2?: string | null,
};

export type UnPublishMutationVariables = {
  unPublishInput: UnPublishObject,
};

export type UnPublishMutation = {
  unPublish?: string | null,
};

export type UnPublishCampaignMutationVariables = {
  campaign_id: string,
  dashboardType: DashboardTypes,
  clientCode?: string | null,
  clientCountry?: string | null,
  subCampaignId?: string | null,
};

export type UnPublishCampaignMutation = {
  unPublishCampaign?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type AddExternalUserMutationVariables = {
  Email: string,
  Name: string,
  Family_name: string,
  Organization_id: string,
  Clients: Array< addUserClientInput | null >,
  Environment: Environment,
  Agency?: string | null,
  WelcomeUrl?: string | null,
  Dashboards?: Array< string | null > | null,
};

export type AddExternalUserMutation = {
  addExternalUser?:  {
    __typename: "UserHttpResponse",
    status?: string | null,
    message?: string | null,
    username?: string | null,
  } | null,
};

export type AddInternalUserMutationVariables = {
  Email: string,
  Environment: Environment,
  Clients: Array< addUserClientInput | null >,
  Dashboards?: Array< string | null > | null,
};

export type AddInternalUserMutation = {
  addInternalUser?:  {
    __typename: "UserHttpResponse",
    status?: string | null,
    message?: string | null,
    username?: string | null,
  } | null,
};

export type RemoveUserMutationVariables = {
  Username: string,
  Clients: Array< ClientsInput | null >,
  Environment: Environment,
};

export type RemoveUserMutation = {
  removeUser?:  {
    __typename: "HttpResponse",
    status?: string | null,
    message?: string | null,
  } | null,
};

export type StoreAudienceReportMutationVariables = {
  campaign_id: string,
  outputlocation: string,
};

export type StoreAudienceReportMutation = {
  storeAudienceReport?: string | null,
};

export type DeleteStoredQueryMutationVariables = {
  campaign_id: string,
  Environment: Environment,
  placeholder?: string | null,
};

export type DeleteStoredQueryMutation = {
  deleteStoredQuery?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type UpdateUserAccessMutationVariables = {
  Environment: Environment,
  Username: string,
  Clients: Array< addUserClientInput | null >,
};

export type UpdateUserAccessMutation = {
  updateUserAccess?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type AddDashboardAccessMutationVariables = {
  Clients: Array< ClientsInput | null >,
  Resource: string,
  Environment: Environment,
  Username: string,
};

export type AddDashboardAccessMutation = {
  addDashboardAccess?:  Array< {
    __typename: "StatementResponseStatus",
    Message?: string | null,
    StatusCode?: number | null,
    StatementId?: string | null,
  } | null > | null,
};

export type RemoveDashboardAccessMutationVariables = {
  Clients: Array< ClientsInput | null >,
  Resource: string,
  Environment: Environment,
  Username: string,
};

export type RemoveDashboardAccessMutation = {
  removeDashboardAccess?:  Array< {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null > | null,
};

export type SaveTemplatesMutationVariables = {
  ClientCode: string,
  ClientCountry: string,
  Environment: Environment,
  TemplateName: string,
  Placeholders: Array< TemplatePlaceholderInput | null >,
};

export type SaveTemplatesMutation = {
  saveTemplates?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type SaveTemplates_v2MutationVariables = {
  ClientCode: string,
  ClientCountry: string,
  Environment: Environment,
  TemplateName: string,
  Placeholders: Array< TemplatePlaceholderInput | null >,
};

export type SaveTemplates_v2Mutation = {
  saveTemplates_v2?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type UpdateTemplateMutationVariables = {
  TemplateId: string,
  TemplateName: string,
  Placeholders: Array< TemplatePlaceholderInput | null >,
};

export type UpdateTemplateMutation = {
  updateTemplate?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type UpdateTemplate_v2MutationVariables = {
  TemplateId: string,
  TemplateName: string,
  Placeholders: Array< TemplatePlaceholderInput | null >,
};

export type UpdateTemplate_v2Mutation = {
  updateTemplate_v2?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type DeleteTemplateMutationVariables = {
  TemplateId: string,
};

export type DeleteTemplateMutation = {
  deleteTemplate?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type SaveCustomValueMutationVariables = {
  CustomValue: CustomPlaceholderInput,
};

export type SaveCustomValueMutation = {
  saveCustomValue?:  {
    __typename: "HttpResponse",
    status?: string | null,
    message?: string | null,
  } | null,
};

export type CreateFlowchartCampaignMutationVariables = {
  campaignId: string,
  campaignName: string,
  startDate: string,
  endDate: string,
  clientCode: string,
  clientCountry: string,
  status?: FlowchartCampaignStatus | null,
};

export type CreateFlowchartCampaignMutation = {
  createFlowchartCampaign?:  {
    __typename: "HttpResponse",
    status?: string | null,
    message?: string | null,
  } | null,
};

export type SetOverviewStatusMutationVariables = {
  source: string,
  severity: Severity,
  status: OverviewStatus,
  message?: string | null,
  client?: string | null,
  campaignId?: string | null,
};

export type SetOverviewStatusMutation = {
  setOverviewStatus:  {
    __typename: "overviewStatusResponse",
    source: string,
    severity: Severity,
    status: OverviewStatus,
    message?: string | null,
    client?: string | null,
    campaignId?: string | null,
    updatedAt?: string | null,
  },
};

export type UpdateBriefedBudgetMutationVariables = {
  clientCode: string,
  clientCountry: string,
  campaignId: number,
  budget: number,
};

export type UpdateBriefedBudgetMutation = {
  updateBriefedBudget?:  {
    __typename: "HttpResponse",
    status?: string | null,
    message?: string | null,
  } | null,
};

export type UpdateBriefedBudget_v2MutationVariables = {
  campaignId: number,
  amount: number,
  currency: string,
  brief_type: string,
  vat_info: boolean,
  Environment: Environment,
};

export type UpdateBriefedBudget_v2Mutation = {
  updateBriefedBudget_v2?:  {
    __typename: "HttpResponse",
    status?: string | null,
    message?: string | null,
  } | null,
};

export type CreateCampaignMutationVariables = {
  name: string,
  startDate: string,
  endDate: string,
  clientCode: string,
  clientCountry: string,
  comments?: string | null,
  additionalInfo?: string | null,
  additionalInfo2?: string | null,
};

export type CreateCampaignMutation = {
  createCampaign?:  {
    __typename: "HttpResponse",
    status?: string | null,
    message?: string | null,
  } | null,
};

export type UpdateCampaignMutationVariables = {
  campaignId: number,
  clientCode: string,
  clientCountry: string,
  name?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  budget?: number | null,
  comments?: string | null,
  additionalInfo?: string | null,
  additionalInfo2?: string | null,
};

export type UpdateCampaignMutation = {
  updateCampaign?:  {
    __typename: "HttpResponse",
    status?: string | null,
    message?: string | null,
  } | null,
};

export type UpdateCampaign_V2MutationVariables = {
  CampaignId: number,
  Name?: string | null,
  StartDate?: string | null,
  EndDate?: string | null,
  Comments?: string | null,
  AdditionalInfo?: string | null,
  AdditionalInfo2?: string | null,
};

export type UpdateCampaign_V2Mutation = {
  updateCampaign_V2?:  {
    __typename: "HttpResponse",
    status?: string | null,
    message?: string | null,
  } | null,
};

export type SwitchUserModeMutationVariables = {
  Organization_id: string,
  Environment: Environment,
  UserMode: UserMode,
};

export type SwitchUserModeMutation = {
  switchUserMode?:  Array< {
    __typename: "SwitchUserModeResponse",
    Client?: string | null,
    Status?:  {
      __typename: "responseStatus",
      Message?: string | null,
      StatusCode?: number | null,
    } | null,
  } | null > | null,
};

export type CreateBriefTemplateMutationVariables = {
  TemplateName: string,
  OrganizationId: string,
  Environment: Environment,
  Columns: Array< BriefColumn | null >,
};

export type CreateBriefTemplateMutation = {
  createBriefTemplate?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type CreateBriefMutationVariables = {
  CampaignId: number,
  TemplateId: string,
  Environment: Environment,
  Values: Array< BriefValue | null >,
};

export type CreateBriefMutation = {
  createBrief?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type CreateDetailedPlanningTemplateMutationVariables = {
  TemplateName: string,
  OrganizationId: string,
  Environment: Environment,
  Columns: Array< DetailedPlanningColumn | null >,
};

export type CreateDetailedPlanningTemplateMutation = {
  createDetailedPlanningTemplate?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type CreateDetailedPlanningMutationVariables = {
  CampaignId: number,
  TemplateId: string,
  Environment: Environment,
  Values: Array< PlanningSectionsInput | null >,
};

export type CreateDetailedPlanningMutation = {
  createDetailedPlanning?:  {
    __typename: "DetailedPlanningOrderResponse",
    tableOrder?:  {
      __typename: "tableOrderResponse",
      rows?:  Array< {
        __typename: "sectionOrderResponse",
        section: string,
        values?:  Array< {
          __typename: "rowOrderResponse",
          rowId: string,
        } | null > | null,
      } | null > | null,
    } | null,
  } | null,
};

export type CreateDetailedPlanningOrderMutationVariables = {
  CampaignId: number,
  Environment: Environment,
  tableOrder?: tableOrderInput | null,
};

export type CreateDetailedPlanningOrderMutation = {
  createDetailedPlanningOrder?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type DeleteDetailedPlanningMutationVariables = {
  CampaignId: number,
  Environment: Environment,
  RowIds: Array< string | null >,
};

export type DeleteDetailedPlanningMutation = {
  deleteDetailedPlanning?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type UpdateDetailedPlanningMutationVariables = {
  CampaignId: number,
  Environment: Environment,
  Columns: Array< updateDetailedPlanningInput | null >,
};

export type UpdateDetailedPlanningMutation = {
  updateDetailedPlanning?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type UpdateCampaignStatusMutationVariables = {
  CampaignId: number,
  Status: FlowchartCampaignStatus,
};

export type UpdateCampaignStatusMutation = {
  updateCampaignStatus?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type UpdateCampaignFeeMutationVariables = {
  CampaignId: number,
  PlannedFee?: number | null,
  ActualFee?: number | null,
};

export type UpdateCampaignFeeMutation = {
  updateCampaignFee?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type UpdateStoredQueriesOrderMutationVariables = {
  CampaignId: number,
  DashboardType: mediaSource,
  DashboardSection: DashboardSection,
  Order: Array< QueryOrderInput | null >,
};

export type UpdateStoredQueriesOrderMutation = {
  updateStoredQueriesOrder?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type UpgradeClientAccessMutationVariables = {
  Clients: Array< ClientsInput | null >,
  Current_Level: AccessType,
  Upgraded_Level: AccessType,
  Environment?: Environment | null,
};

export type UpgradeClientAccessMutation = {
  upgradeClientAccess?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type DowngradeClientAccessMutationVariables = {
  Clients: Array< ClientsInput | null >,
  Environment?: Environment | null,
};

export type DowngradeClientAccessMutation = {
  downgradeClientAccess?:  {
    __typename: "responseStatus",
    Message?: string | null,
    StatusCode?: number | null,
  } | null,
};

export type CreateLinkMutationVariables = {
  input: CreateLinkInput,
  Organization_id: string,
  Environment: Environment,
  clientCodes?: Array< string | null > | null,
};

export type CreateLinkMutation = {
  createLink: ( {
      __typename: "linkWithStatus",
      statusCode: number,
      link?:  {
        __typename: string,
        Id?: string | null,
        title?: string | null,
        linkValue?: string | null,
        icon?: string | null,
        image?: string | null,
        clientCodes?:  Array< {
          __typename: string,
          ClientCode?: string | null,
          ClientCountry?: string | null,
        } | null > | null,
      } | null,
    } | {
      __typename: "Error",
      type: ErrorTypeEnum,
      message: string,
      statusCode: number,
    }
  ),
};

export type DeleteLinkMutationVariables = {
  Organization_id: string,
  Id: string,
  Environment: Environment,
};

export type DeleteLinkMutation = {
  deleteLink: ( {
      __typename: "linkWithStatus",
      statusCode: number,
      link?:  {
        __typename: string,
        Id?: string | null,
        title?: string | null,
        linkValue?: string | null,
        icon?: string | null,
        image?: string | null,
        clientCodes?:  Array< {
          __typename: string,
          ClientCode?: string | null,
          ClientCountry?: string | null,
        } | null > | null,
      } | null,
    } | {
      __typename: "Error",
      type: ErrorTypeEnum,
      message: string,
      statusCode: number,
    }
  ),
};

export type UpdateLinkMutationVariables = {
  input: UpdateLinkInput,
  Organization_id: string,
  Id: string,
  Environment: Environment,
};

export type UpdateLinkMutation = {
  updateLink: ( {
      __typename: "linkWithStatus",
      statusCode: number,
      link?:  {
        __typename: string,
        Id?: string | null,
        title?: string | null,
        linkValue?: string | null,
        icon?: string | null,
        image?: string | null,
        clientCodes?:  Array< {
          __typename: string,
          ClientCode?: string | null,
          ClientCountry?: string | null,
        } | null > | null,
      } | null,
    } | {
      __typename: "Error",
      type: ErrorTypeEnum,
      message: string,
      statusCode: number,
    }
  ),
};

export type RemoveClientCodeMutationVariables = {
  Organization_id: string,
  id: string,
  clientCodes?: Array< string | null > | null,
  Environment?: Environment | null,
};

export type RemoveClientCodeMutation = {
  removeClientCode: ( {
      __typename: "linkWithStatus",
      statusCode: number,
      link?:  {
        __typename: string,
        Id?: string | null,
        title?: string | null,
        linkValue?: string | null,
        icon?: string | null,
        image?: string | null,
        clientCodes?:  Array< {
          __typename: string,
          ClientCode?: string | null,
          ClientCountry?: string | null,
        } | null > | null,
      } | null,
    } | {
      __typename: "Error",
      type: ErrorTypeEnum,
      message: string,
      statusCode: number,
    }
  ),
};

export type AddClientCodeMutationVariables = {
  Organization_id: string,
  id: string,
  Environment?: Environment | null,
  clientCodes?: Array< string | null > | null,
};

export type AddClientCodeMutation = {
  addClientCode?:  {
    __typename: "linkWithStatus",
    statusCode: number,
    link?:  {
      __typename: "linkResponse",
      Id?: string | null,
      title?: string | null,
      linkValue?: string | null,
      icon?: string | null,
      image?: string | null,
      clientCodes?:  Array< {
        __typename: "ClientCodes",
        ClientCode?: string | null,
        ClientCountry?: string | null,
      } | null > | null,
    } | null,
  } | null,
};

export type UpdateOrderListMutationVariables = {
  Organization_id: string,
  Environment?: Environment | null,
  orderList?: Array< string | null > | null,
};

export type UpdateOrderListMutation = {
  updateOrderList?:  {
    __typename: "linkWithStatus",
    statusCode: number,
    link?:  {
      __typename: "linkResponse",
      Id?: string | null,
      title?: string | null,
      linkValue?: string | null,
      icon?: string | null,
      image?: string | null,
      clientCodes?:  Array< {
        __typename: "ClientCodes",
        ClientCode?: string | null,
        ClientCountry?: string | null,
      } | null > | null,
    } | null,
  } | null,
};

export type UpdatePlannedMediaBudgetMutationVariables = {
  campaignId: number,
  budget: number,
};

export type UpdatePlannedMediaBudgetMutation = {
  updatePlannedMediaBudget?:  {
    __typename: "HttpResponse",
    status?: string | null,
    message?: string | null,
  } | null,
};

export type LogActivityMutationVariables = {
  Username: string,
  Action: string,
  Environment: Environment,
  Service: string,
  Url?: string | null,
  Ip?: string | null,
};

export type LogActivityMutation = {
  logActivity?:  {
    __typename: "LoggingResponse",
    Message?: string | null,
    StatusCode?: string | null,
  } | null,
};

export type GetInfoQuery = {
  getInfo?:  Array< {
    __typename: "UserInfo",
    PK?: string | null,
    Name?: string | null,
  } | null > | null,
};

export type GetDataSourcesDEPRECATEDQueryVariables = {
  campaignId?: string | null,
};

export type GetDataSourcesDEPRECATEDQuery = {
  getDataSourcesDEPRECATED?:  Array< {
    __typename: "DataSourcesInfo_DEPRECATED",
    source_name?: string | null,
    sources?:  Array< {
      __typename: "SourcesInfo",
      name?: string | null,
      view?: string | null,
      metrics?:  Array< {
        __typename: "MetricInfo",
        name?: string | null,
        format?: FormatEnum | null,
        decimals?: number | null,
      } | null > | null,
      dimensions?: Array< string | null > | null,
    } | null > | null,
  } | null > | null,
};

export type GetDataSourcesQueryVariables = {
  campaignId?: string | null,
};

export type GetDataSourcesQuery = {
  getDataSources?:  Array< {
    __typename: "DataSourcesInfo",
    source_name?: string | null,
    metrics?:  Array< {
      __typename: "Metrics",
      metric?: string | null,
      description?: string | null,
      view?: Array< string | null > | null,
    } | null > | null,
    dimensions?:  Array< {
      __typename: "Dimensions",
      dimension?: string | null,
      description?: string | null,
      view?: Array< string | null > | null,
    } | null > | null,
  } | null > | null,
};

export type GetCommentsQueryVariables = {
  campaign: number,
  sub_campaign: number,
  media_source: mediaSource,
  comment_type: commentType,
  placeholder: string,
  show_removed?: boolean | null,
};

export type GetCommentsQuery = {
  getComments?:  Array< {
    __typename: "Comment",
    campaign: number,
    sub_campaign: number,
    placeholder: string,
    media_source: mediaSource,
    comment_type: commentType,
    comment: string,
    created_at: string,
    last_edited_at: string,
    created_by: string,
    last_edited_by: string,
    state: commentState,
  } | null > | null,
};

export type GetCampaignCommentsQueryVariables = {
  campaign: number,
  comment_type: commentType,
  media_source: mediaSource,
  placeholder?: string | null,
  sub_campaign?: number | null,
};

export type GetCampaignCommentsQuery = {
  getCampaignComments?:  Array< {
    __typename: "Comment",
    campaign: number,
    sub_campaign: number,
    placeholder: string,
    media_source: mediaSource,
    comment_type: commentType,
    comment: string,
    created_at: string,
    last_edited_at: string,
    created_by: string,
    last_edited_by: string,
    state: commentState,
  } | null > | null,
};

export type GetClientListQueryVariables = {
  Organization_id: string,
};

export type GetClientListQuery = {
  getClientList?:  Array< {
    __typename: "ClientLookUpList",
    OrganizationId?: string | null,
    Country?: string | null,
    ClientCodes?: Array< string | null > | null,
  } | null > | null,
};

export type GetClientListBetaQuery = {
  getClientListBeta?:  Array< {
    __typename: "ClientLookUpListBeta",
    Organization?: string | null,
    OrganizationId?: string | null,
    Country?: string | null,
    ClientCodes?: Array< string | null > | null,
  } | null > | null,
};

export type GetCampaignsQueryVariables = {
  clients: string,
  country: country,
  from?: string | null,
  to?: string | null,
};

export type GetCampaignsQuery = {
  getCampaigns?:  {
    __typename: "campaigns",
    foundCampaigns?:  Array< {
      __typename: "foundCampaigns",
      campaignId?: number | null,
      name?: string | null,
    } | null > | null,
    notFoundCampaigns?:  Array< {
      __typename: "notFoundCampaigns",
      client?: string | null,
      country?: string | null,
    } | null > | null,
  } | null,
};

export type GetCampaignsV2QueryVariables = {
  clients: string,
  country: country,
  from?: string | null,
  to?: string | null,
  deleted?: boolean | null,
  desc?: boolean | null,
  Environment?: Environment | null,
};

export type GetCampaignsV2Query = {
  getCampaignsV2?:  Array< {
    __typename: "campaignsV2",
    PK?: string | null,
    SK?: string | null,
    campaignId?: number | null,
    campaignName?: string | null,
    clientCode?: string | null,
    clientCountry?: string | null,
    comments?: string | null,
    endDate?: string | null,
    startDate?: string | null,
    deleted?: string | null,
    additionalInfo?: string | null,
    additionalInfo2?: string | null,
    createdBy?: string | null,
    briefedBudget?: number | null,
  } | null > | null,
};

export type GetSubCampaignsQueryVariables = {
  campaignId: string,
  media?: string | null,
};

export type GetSubCampaignsQuery = {
  getSubCampaigns?:  Array< {
    __typename: "subCampaignResponse",
    subcampaignId?: number | null,
    name?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    mediaGroup?: string | null,
  } | null > | null,
};

export type GetCampaignInfoQueryVariables = {
  campaignIds: string,
};

export type GetCampaignInfoQuery = {
  getCampaignInfo?:  {
    __typename: "campaignInfo",
    foundCampaigns?:  Array< {
      __typename: "foundCampaignsInfo",
      startDate?: string | null,
      endDate?: string | null,
      createdBy?: string | null,
      comments?: string | null,
      additionalInfo?: string | null,
      additionalInfo2?: string | null,
      campaignId?: number | null,
      name?: string | null,
      clientCode?:  {
        __typename: "clientCode",
        client?: string | null,
        country?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type GetQueryQueryVariables = {
  id?: string | null,
};

export type GetQueryQuery = {
  getQuery?:  {
    __typename: "QueryInfo",
    id: string,
    status: statusEnum,
    submitted?: string | null,
    outputlocation?: string | null,
    bytes_scanned?: string | null,
    user_name?: string | null,
    source?: string | null,
    bucket?: string | null,
    query?: string | null,
    error?: number | null,
    message?: string | null,
  } | null,
};

export type GetQueriesQueryVariables = {
  campaign_id: string,
};

export type GetQueriesQuery = {
  getQueries?:  Array< {
    __typename: "UserQuery",
    campaign_id?: string | null,
    position?: string | null,
    query_id?: string | null,
    query_id_cc?: string | null,
    title?: string | null,
    metrics?:  Array< {
      __typename: "MetricsResponse",
      chartType?: string | null,
      metric?: string | null,
      axis?: number | null,
    } | null > | null,
  } | null > | null,
};

export type GetStoredQueryQueryVariables = {
  campaign_id: string,
  position: string,
};

export type GetStoredQueryQuery = {
  getStoredQuery?:  {
    __typename: "UserQuery",
    campaign_id?: string | null,
    position?: string | null,
    query_id?: string | null,
    query_id_cc?: string | null,
    title?: string | null,
    metrics?:  Array< {
      __typename: "MetricsResponse",
      chartType?: string | null,
      metric?: string | null,
      axis?: number | null,
    } | null > | null,
  } | null,
};

export type GetPublishedOutputQueryVariables = {
  clientCode: string,
  clientCountry: string,
  campaign_id: string,
  dashboardType: DashboardTypes,
  subCampaignId?: string | null,
  position?: string | null,
};

export type GetPublishedOutputQuery = {
  getPublishedOutput?:  {
    __typename: "PublishedOutput",
    campaign_id?: string | null,
    placeholders?:  Array< {
      __typename: "Placeholder",
      position?: string | null,
      query_id?: string | null,
      query_id_cc?: string | null,
      outputlocation?: string | null,
      outputlocationTotal?: string | null,
      outputlocation_cc?: string | null,
      outputlocationTotal_cc?: string | null,
      title?: string | null,
      dashboardType?: DashboardTypes | null,
      publishedAt?: string | null,
      publishedBy?: string | null,
      metrics?:  Array< {
        __typename: "MetricsResponse",
        chartType?: string | null,
        metric?: string | null,
        axis?: number | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetPublishedCampaignsQueryVariables = {
  campaigns: Array< string | null >,
  dashboardType: DashboardTypes,
};

export type GetPublishedCampaignsQuery = {
  getPublishedCampaigns?:  Array< {
    __typename: "publishedCampaignsResponse",
    campaignId?: string | null,
    subCampaignId?: string | null,
  } | null > | null,
};

export type GetPublishedCampaigns_v2QueryVariables = {
  campaigns: Array< string | null >,
};

export type GetPublishedCampaigns_v2Query = {
  getPublishedCampaigns_v2?:  Array< {
    __typename: "publishedCampaignsResponse_v2",
    campaignId?: string | null,
    subCampaignId?: string | null,
    published?:  {
      __typename: "publishedType",
      TV?: boolean | null,
      RADIO?: boolean | null,
      PRINT?: boolean | null,
      OOH?: boolean | null,
      DIGITAL?: boolean | null,
      CINEMA?: boolean | null,
    } | null,
  } | null > | null,
};

export type GetUsersQueryVariables = {
  Environment: Environment,
  Organization_id: string,
  UserType: UserType,
  StatementType: StatementType,
};

export type GetUsersQuery = {
  getUsers?:  Array< {
    __typename: "getUserType",
    Username?: string | null,
    Name?: string | null,
    Family_name?: string | null,
    Email?: string | null,
    HasFullAccess?:  Array< {
      __typename: "Clients",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null > | null,
    HasReadAccess?:  Array< {
      __typename: "Clients",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null > | null,
    HasNoAccess?:  Array< {
      __typename: "Clients",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null > | null,
  } | null > | null,
};

export type GetUsersV2QueryVariables = {
  Environment: Environment,
  Organization_id: string,
  UserType: UserType,
  StatementType: StatementType,
  Limit?: LimitType | null,
};

export type GetUsersV2Query = {
  getUsersV2?:  {
    __typename: "getUserTypeV2Response",
    Data?:  Array< {
      __typename: "getUserTypeV2",
      Username?: string | null,
      Name?: string | null,
      Family_name?: string | null,
      Email?: string | null,
      Access?:  Array< {
        __typename: "userAccessType",
        Level?: string | null,
        Clients?:  Array< {
          __typename: "Clients",
          ClientCode?: string | null,
          ClientCountry?: string | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    Meta?:  {
      __typename: "MetaInfoResponse",
      Clients?:  {
        __typename: "ClientMetaInfo",
        returned?: number | null,
        total?: number | null,
        start_index?: number | null,
      } | null,
    } | null,
  } | null,
};

export type GetUserQueryVariables = {
  Environment: Environment,
  Organization_id: string,
  StatementType: StatementType,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "getUserType",
    Username?: string | null,
    Name?: string | null,
    Family_name?: string | null,
    Email?: string | null,
    HasFullAccess?:  Array< {
      __typename: "Clients",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null > | null,
    HasReadAccess?:  Array< {
      __typename: "Clients",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null > | null,
    HasNoAccess?:  Array< {
      __typename: "Clients",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null > | null,
  } | null,
};

export type GetUserV2QueryVariables = {
  Environment: Environment,
  Organization_id: string,
  StatementType: StatementType,
};

export type GetUserV2Query = {
  getUserV2?:  {
    __typename: "getUserTypeV2",
    Username?: string | null,
    Name?: string | null,
    Family_name?: string | null,
    Email?: string | null,
    Access?:  Array< {
      __typename: "userAccessType",
      Level?: string | null,
      Clients?:  Array< {
        __typename: "Clients",
        ClientCode?: string | null,
        ClientCountry?: string | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetStatementsFromClientQueryVariables = {
  ClientCountry: string,
  ClientCode: string,
  Environment: Environment,
};

export type GetStatementsFromClientQuery = {
  getStatementsFromClient?:  {
    __typename: "StatementsFromClient",
    Comment?:  {
      __typename: "CommentStatements",
      READ?: boolean | null,
      WRITE?: boolean | null,
    } | null,
    Queries?:  {
      __typename: "QueriesStatement",
      READ?: boolean | null,
      CREATE?: boolean | null,
      PUBLISH?: boolean | null,
      UNPUBLISH?: boolean | null,
    } | null,
  } | null,
};

export type UserIsAuthorizedQueryVariables = {
  Clients?: Array< ClientsInput | null > | null,
  Resource: string,
  Environment: Environment,
  Action: ActionEnum,
};

export type UserIsAuthorizedQuery = {
  userIsAuthorized?:  Array< {
    __typename: "UserIsAuthorizedType",
    Client?:  {
      __typename: "Clients",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null,
    Authorized?: boolean | null,
  } | null > | null,
};

export type UserIsAuthorizedMultiQueryVariables = {
  Organization_id: string,
  Resource: string,
  Service: string,
  StatementType: StatementType,
  Environment: Environment,
  Action: ActionEnum,
};

export type UserIsAuthorizedMultiQuery = {
  userIsAuthorizedMulti?:  Array< {
    __typename: "UserIsAuthorizedType",
    Client?:  {
      __typename: "Clients",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null,
    Authorized?: boolean | null,
  } | null > | null,
};

export type GetAudienceReportQueryVariables = {
  campaignId: string,
};

export type GetAudienceReportQuery = {
  getAudienceReport?:  {
    __typename: "AudienceReportInfo",
    campaignId?: string | null,
    outputlocation?: string | null,
  } | null,
};

export type GetPortalDashboardsQueryVariables = {
  Organization_id: string,
  Environment: Environment,
  StatementType: StatementType,
};

export type GetPortalDashboardsQuery = {
  getPortalDashboards?:  {
    __typename: "PortalDashboardResponse",
    activities?:  Array< {
      __typename: "PortalDashboardItem",
      name?: string | null,
      dashboard?: string | null,
      url?: string | null,
      internal_launch?: string | null,
      external_launch?: string | null,
      accessible?: boolean | null,
      sortOrder?: number | null,
      color?: string | null,
      description?: string | null,
    } | null > | null,
    insights?:  Array< {
      __typename: "PortalDashboardItem",
      name?: string | null,
      dashboard?: string | null,
      url?: string | null,
      internal_launch?: string | null,
      external_launch?: string | null,
      accessible?: boolean | null,
      sortOrder?: number | null,
      color?: string | null,
      description?: string | null,
    } | null > | null,
  } | null,
};

export type GetConcernsQueryVariables = {
  Environment: Environment,
  Country?: country | null,
};

export type GetConcernsQuery = {
  getConcerns?:  Array< {
    __typename: "UserConcerns",
    Organization?: string | null,
    OrganizationId?: string | null,
  } | null > | null,
};

export type GetPreSignedUrlQueryVariables = {
  ClientMethod: S3Method,
  Client: ClientInput,
  Environment: Environment,
  FileName: string,
  ContentType?: string | null,
  Exp?: number | null,
};

export type GetPreSignedUrlQuery = {
  getPreSignedUrl?:  {
    __typename: "PreSignedUrlRsponse",
    status?: number | null,
    message?: string | null,
    url?: string | null,
  } | null,
};

export type GetInsightsDashboardsQueryVariables = {
  Environment: Environment,
  Organization_id: string,
};

export type GetInsightsDashboardsQuery = {
  getInsightsDashboards?:  Array< {
    __typename: "getInsightsDashboardsResponse",
    ClientCode?: string | null,
    ClientCountry?: string | null,
    Resources?:  Array< {
      __typename: "ResourceResponse",
      Dashboard?: string | null,
      Access?: boolean | null,
    } | null > | null,
  } | null > | null,
};

export type GetInsightsAccessQueryVariables = {
  Environment: Environment,
  Organization_id: string,
  UserType: UserType,
};

export type GetInsightsAccessQuery = {
  getInsightsAccess?:  {
    __typename: "UserStatementType",
    Users?:  Array< {
      __typename: "UserWithActionListStatement",
      Username?: string | null,
      Name?: string | null,
      Family_name?: string | null,
      Email?: string | null,
      Statements?:  Array< {
        __typename: "ActionListStatement",
        Service?: string | null,
        Resource?: string | null,
        Action?: Array< string | null > | null,
        ClientCode?: string | null,
        ClientCountry?: string | null,
        Environment?: string | null,
      } | null > | null,
    } | null > | null,
    Clients?:  Array< {
      __typename: "ClientCodes",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null > | null,
  } | null,
};

export type ListTemplatesQueryVariables = {
  ClientCode: string,
  ClientCountry: string,
  Environment: Environment,
  CreatedBy?: string | null,
};

export type ListTemplatesQuery = {
  listTemplates?:  Array< {
    __typename: "ListTemplateResponse",
    Name?: string | null,
    Id?: string | null,
    CreatedBy?: string | null,
    CreatedAt?: string | null,
    LastEditedBy?: string | null,
    LastEditedAt?: string | null,
  } | null > | null,
};

export type ListTemplates_v2QueryVariables = {
  Organization_id: string,
  Environment: Environment,
  Client?: ClientCode | null,
  CreatedBy?: string | null,
};

export type ListTemplates_v2Query = {
  listTemplates_v2?:  Array< {
    __typename: "ListTemplateResponse",
    Name?: string | null,
    Id?: string | null,
    CreatedBy?: string | null,
    CreatedAt?: string | null,
    LastEditedBy?: string | null,
    LastEditedAt?: string | null,
  } | null > | null,
};

export type GetTemplateByIdQueryVariables = {
  TemplateId: string,
};

export type GetTemplateByIdQuery = {
  getTemplateById?:  {
    __typename: "TemplateResponse",
    Name?: string | null,
    Id?: string | null,
    CreatedBy?: string | null,
    CreatedAt?: string | null,
    LastEditedBy?: string | null,
    LastEditedAt?: string | null,
    Placeholders?:  Array< {
      __typename: "TemplatePlaceholder",
      Placeholder?: string | null,
      Placeholder_titel?: string | null,
      S3Location?: string | null,
      S3Location_cc?: string | null,
      OrderIndex?: number | null,
      Metrics?:  Array< {
        __typename: "MetricsResponse",
        chartType?: string | null,
        metric?: string | null,
        axis?: number | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetOrganizationCampaignsQueryVariables = {
  Organization_id: string,
  from?: string | null,
  to?: string | null,
};

export type GetOrganizationCampaignsQuery = {
  getOrganizationCampaigns?:  {
    __typename: "getCampaignsNewResponse",
    foundCampaigns?:  Array< {
      __typename: "campaignResponse",
      campaign?: string | null,
      campaignId?: number | null,
      ClientCode?: string | null,
      ClientCountry?: string | null,
      ClientName?: string | null,
    } | null > | null,
    notFoundCampaigns?:  Array< {
      __typename: "campaignResponse",
      campaign?: string | null,
      campaignId?: number | null,
      ClientCode?: string | null,
      ClientCountry?: string | null,
      ClientName?: string | null,
    } | null > | null,
  } | null,
};

export type GetPublishedCampaignQueryVariables = {
  campaign_id: string,
  dashboardType?: DashboardTypes | null,
};

export type GetPublishedCampaignQuery = {
  getPublishedCampaign?:  Array< {
    __typename: "publishedCampaignResponse",
    campaignId?: string | null,
    subCampaignId?: string | null,
    dashboardType?: DashboardTypes | null,
  } | null > | null,
};

export type GetCustomValueQueryVariables = {
  campaignId: number,
  dashboardType: string,
  position: string,
};

export type GetCustomValueQuery = {
  getCustomValue?:  {
    __typename: "CustomPlaceholderValue",
    campaignId?: number | null,
    label?: string | null,
    dashboardType?: string | null,
    position?: string | null,
    floatValue?: number | null,
    status?: CustomPlaceholderValueStatusEnum | null,
    createdBy?: string | null,
    createdAt?: string | null,
    format?: string | null,
    subtitle?: string | null,
  } | null,
};

export type GetCustomValueHistoryQueryVariables = {
  campaignId: number,
  dashboardType: mediaSource,
  position: string,
};

export type GetCustomValueHistoryQuery = {
  getCustomValueHistory?:  Array< {
    __typename: "CustomPlaceholderValue",
    campaignId?: number | null,
    label?: string | null,
    dashboardType?: string | null,
    position?: string | null,
    floatValue?: number | null,
    status?: CustomPlaceholderValueStatusEnum | null,
    createdBy?: string | null,
    createdAt?: string | null,
    format?: string | null,
    subtitle?: string | null,
  } | null > | null,
};

export type GetFlowchartCampaignsPerClientQueryVariables = {
  clientCode: string,
  clientCountry: string,
  from: string,
};

export type GetFlowchartCampaignsPerClientQuery = {
  getFlowchartCampaignsPerClient?:  Array< {
    __typename: "flowchartCampaign",
    campaignId: number,
    campaign: string,
    startDate: string,
    endDate: string,
    ClientCode: string,
    ClientCountry: string,
    ClientName: string,
    status: string,
    createdBy: string,
    createdAt?: string | null,
    briefed_amount?: number | null,
    briefed_currency?: string | null,
    briefed_type?: string | null,
    briefed_vat_info?: string | null,
    additionalInfo?: string | null,
    additionalInfo2?: string | null,
    comment?: string | null,
    tagIds?: Array< number | null > | null,
    recommended_budget_ctc?: number | null,
    approved_budget?: number | null,
    actual_fee?: number | null,
    planned_fee?: number | null,
  } | null > | null,
};

export type GetFlowchartCampaignsQueryVariables = {
  Organization_id: string,
  from: string,
};

export type GetFlowchartCampaignsQuery = {
  getFlowchartCampaigns?:  Array< {
    __typename: "flowchartCampaignWithType",
    campaignId: number,
    campaign: string,
    startDate: string,
    endDate: string,
    ClientCode: string,
    ClientCountry: string,
    ClientName: string,
    status: string,
    dashboardType: Array< DashboardTypes | null >,
    createdBy: string,
    createdAt?: string | null,
    briefed_amount?: number | null,
    briefed_currency?: string | null,
    briefed_type?: string | null,
    briefed_vat_info?: string | null,
    additionalInfo?: string | null,
    additionalInfo2?: string | null,
    comment?: string | null,
    budget?: string | null,
    tagIds?: Array< number | null > | null,
    recommended_budget_ctc?: number | null,
    approved_budget?: number | null,
    actual_fee?: number | null,
    planned_fee?: number | null,
  } | null > | null,
};

export type GetFlowchartQueryVariables = {
  Organization_id: string,
  endDate: string,
};

export type GetFlowchartQuery = {
  getFlowchart?:  Array< {
    __typename: "flowchartCampaign",
    campaignId: number,
    campaign: string,
    startDate: string,
    endDate: string,
    ClientCode: string,
    ClientCountry: string,
    ClientName: string,
    status: string,
    createdBy: string,
    createdAt?: string | null,
    briefed_amount?: number | null,
    briefed_currency?: string | null,
    briefed_type?: string | null,
    briefed_vat_info?: string | null,
    additionalInfo?: string | null,
    additionalInfo2?: string | null,
    comment?: string | null,
    tagIds?: Array< number | null > | null,
    recommended_budget_ctc?: number | null,
    approved_budget?: number | null,
    actual_fee?: number | null,
    planned_fee?: number | null,
  } | null > | null,
};

export type GetOverviewStatusQueryVariables = {
  source?: string | null,
  client?: string | null,
  campaignId?: string | null,
  Resolved?: boolean | null,
};

export type GetOverviewStatusQuery = {
  getOverviewStatus:  Array< {
    __typename: "overviewStatusResponse",
    source: string,
    severity: Severity,
    status: OverviewStatus,
    message?: string | null,
    client?: string | null,
    campaignId?: string | null,
    updatedAt?: string | null,
  } | null >,
};

export type GetCampaignTypeQueryVariables = {
  client: string,
  country: string,
  from: string,
};

export type GetCampaignTypeQuery = {
  getCampaignType?:  Array< {
    __typename: "flowchartClient",
    campaignId: string,
    datasource: string,
    client: string,
    name: string,
    type?: string | null,
    status?: string | null,
  } | null > | null,
};

export type GetBriefTemplateQueryVariables = {
  Environment: Environment,
  OrganizationId: string,
  Version?: number | null,
};

export type GetBriefTemplateQuery = {
  getBriefTemplate?:  {
    __typename: "briefTemplateResponse",
    template?: string | null,
    templateId?: string | null,
    version?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    sections?:  Array< {
      __typename: "briefTemplateSection",
      sectionName?: string | null,
      columns?:  Array< {
        __typename: "briefTemplateSectionColumns",
        name?: string | null,
        label?: string | null,
        description?: string | null,
        type?: HTMLType | null,
        id?: string | null,
        required?: boolean | null,
        placeholder?: string | null,
        valueType?: string | null,
        value?:  {
          __typename: "ValueResponse",
          StringValue?: string | null,
          IntValue?: number | null,
          BooleanValue?: boolean | null,
          FloatValue?: number | null,
        } | null,
        subColumns?:  Array< {
          __typename: "briefTemplateSubColumn",
          name?: string | null,
          label?: string | null,
          description?: string | null,
          id?: string | null,
          type?: string | null,
          required?: boolean | null,
          valueType?: string | null,
          value?:  {
            __typename: "ValueResponse",
            StringValue?: string | null,
            IntValue?: number | null,
            BooleanValue?: boolean | null,
            FloatValue?: number | null,
          } | null,
          placeholder?: string | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetClientTagsQueryVariables = {
  ClientCode: string,
  ClientCountry: string,
  Environment: Environment,
};

export type GetClientTagsQuery = {
  getClientTags?:  Array< {
    __typename: "ClientTagsResponse",
    tagDescription?: string | null,
    tagGroup?: string | null,
    tagId?: number | null,
    tagName?: string | null,
  } | null > | null,
};

export type GetTagsQueryVariables = {
  Organization_id: string,
  Environment: Environment,
};

export type GetTagsQuery = {
  getTags?:  Array< {
    __typename: "TagsResponse",
    Client?: string | null,
    Country?: string | null,
    Tags?:  Array< {
      __typename: "ClientTagsResponse",
      tagDescription?: string | null,
      tagGroup?: string | null,
      tagId?: number | null,
      tagName?: string | null,
    } | null > | null,
  } | null > | null,
};

export type GetCampaignTagsQueryVariables = {
  campaignId: number,
  Environment: Environment,
};

export type GetCampaignTagsQuery = {
  getCampaignTags?:  Array< {
    __typename: "ClientTagsResponse",
    tagDescription?: string | null,
    tagGroup?: string | null,
    tagId?: number | null,
    tagName?: string | null,
  } | null > | null,
};

export type GetBriefQueryVariables = {
  CampaignId: number,
  Environment: Environment,
  BriefSection?: BriefSection | null,
};

export type GetBriefQuery = {
  getBrief?:  Array< {
    __typename: "getBreifResponse",
    id?: string | null,
    createdAt?: string | null,
    createdBy?: string | null,
    type?: HTMLType | null,
    valueType?: string | null,
    sectionName?: string | null,
    label?: string | null,
    stringValue?: string | null,
    intValue?: number | null,
    booleanValue?: boolean | null,
    floatValue?: number | null,
  } | null > | null,
};

export type GetBrief_v2QueryVariables = {
  CampaignId: number,
  OrganizationId: string,
  Environment: Environment,
};

export type GetBrief_v2Query = {
  getBrief_v2?:  {
    __typename: "briefTemplateResponse",
    template?: string | null,
    templateId?: string | null,
    version?: number | null,
    createdAt?: string | null,
    createdBy?: string | null,
    sections?:  Array< {
      __typename: "briefTemplateSection",
      sectionName?: string | null,
      columns?:  Array< {
        __typename: "briefTemplateSectionColumns",
        name?: string | null,
        label?: string | null,
        description?: string | null,
        type?: HTMLType | null,
        id?: string | null,
        required?: boolean | null,
        placeholder?: string | null,
        valueType?: string | null,
        value?:  {
          __typename: "ValueResponse",
          StringValue?: string | null,
          IntValue?: number | null,
          BooleanValue?: boolean | null,
          FloatValue?: number | null,
        } | null,
        subColumns?:  Array< {
          __typename: "briefTemplateSubColumn",
          name?: string | null,
          label?: string | null,
          description?: string | null,
          id?: string | null,
          type?: string | null,
          required?: boolean | null,
          valueType?: string | null,
          value?:  {
            __typename: "ValueResponse",
            StringValue?: string | null,
            IntValue?: number | null,
            BooleanValue?: boolean | null,
            FloatValue?: number | null,
          } | null,
          placeholder?: string | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetFlowchartCampaignsPerClient_v2QueryVariables = {
  clientCode: string,
  clientCountry: string,
  from: string,
  deleted?: boolean | null,
};

export type GetFlowchartCampaignsPerClient_v2Query = {
  getFlowchartCampaignsPerClient_v2?:  Array< {
    __typename: "flowchartCampaign",
    campaignId: number,
    campaign: string,
    startDate: string,
    endDate: string,
    ClientCode: string,
    ClientCountry: string,
    ClientName: string,
    status: string,
    createdBy: string,
    createdAt?: string | null,
    briefed_amount?: number | null,
    briefed_currency?: string | null,
    briefed_type?: string | null,
    briefed_vat_info?: string | null,
    additionalInfo?: string | null,
    additionalInfo2?: string | null,
    comment?: string | null,
    tagIds?: Array< number | null > | null,
    recommended_budget_ctc?: number | null,
    approved_budget?: number | null,
    actual_fee?: number | null,
    planned_fee?: number | null,
  } | null > | null,
};

export type GetDetailedPlanningQueryVariables = {
  CampaignId: number,
  OrganizationId: string,
  Environment: Environment,
};

export type GetDetailedPlanningQuery = {
  getDetailedPlanning?:  {
    __typename: "detailedPlanningResponse",
    templateId?: string | null,
    templateName?: string | null,
    columns?:  Array< {
      __typename: "detailedPlanningColumns",
      name?: string | null,
      id?: string | null,
      type?: HTMLType | null,
      valueType?: string | null,
    } | null > | null,
    rows?:  Array< {
      __typename: "detailedPlanningRows",
      section?: string | null,
      values?:  Array< {
        __typename: "detailedPlanningSections",
        rowId?: string | null,
        columns?:  Array< {
          __typename: "detailedPlanningValues",
          columnId?: string | null,
          valueType?: string | null,
          value?:  {
            __typename: "ValueResponse",
            StringValue?: string | null,
            IntValue?: number | null,
            BooleanValue?: boolean | null,
            FloatValue?: number | null,
          } | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetDetailedPlanningRowQueryVariables = {
  CampaignId: number,
  Environment: Environment,
  RowId: string,
};

export type GetDetailedPlanningRowQuery = {
  getDetailedPlanningRow?:  {
    __typename: "detailedPlanningRows",
    section?: string | null,
    values?:  Array< {
      __typename: "detailedPlanningSections",
      rowId?: string | null,
      columns?:  Array< {
        __typename: "detailedPlanningValues",
        columnId?: string | null,
        valueType?: string | null,
        value?:  {
          __typename: "ValueResponse",
          StringValue?: string | null,
          IntValue?: number | null,
          BooleanValue?: boolean | null,
          FloatValue?: number | null,
        } | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetStoredQueryOrderQueryVariables = {
  CampaignId: number,
  DashboardType: mediaSource,
  DashboardSection: DashboardSection,
};

export type GetStoredQueryOrderQuery = {
  getStoredQueryOrder?:  Array< {
    __typename: "QueryOrderResponse",
    QueryId: string,
    Order: number,
  } | null > | null,
};

export type GetAllLinksByOrgQueryVariables = {
  Organization_id: string,
  Environment: Environment,
};

export type GetAllLinksByOrgQuery = {
  getAllLinksByOrg?:  {
    __typename: "linkListsResponse",
    order?: Array< string | null > | null,
    links?:  Array< {
      __typename: "linkResponse",
      Id?: string | null,
      title?: string | null,
      linkValue?: string | null,
      icon?: string | null,
      image?: string | null,
      clientCodes?:  Array< {
        __typename: "ClientCodes",
        ClientCode?: string | null,
        ClientCountry?: string | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetCustomLinkQueryVariables = {
  ItemId: string,
  sortKey?: string | null,
  OrganizationId: string,
  Environment: Environment,
};

export type GetCustomLinkQuery = {
  getCustomLink?:  {
    __typename: "linkResponse",
    Id?: string | null,
    title?: string | null,
    linkValue?: string | null,
    icon?: string | null,
    image?: string | null,
    clientCodes?:  Array< {
      __typename: "ClientCodes",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null > | null,
  } | null,
};

export type GetCustomLinksBatchQueryVariables = {
  ItemId: string,
  OrganizationId: string,
  Environment: Environment,
};

export type GetCustomLinksBatchQuery = {
  getCustomLinksBatch?:  {
    __typename: "linkResponse",
    Id?: string | null,
    title?: string | null,
    linkValue?: string | null,
    icon?: string | null,
    image?: string | null,
    clientCodes?:  Array< {
      __typename: "ClientCodes",
      ClientCode?: string | null,
      ClientCountry?: string | null,
    } | null > | null,
  } | null,
};

export type GetProjectLevelQueryVariables = {
  Clients: Array< ClientsInput | null >,
  Environment: Environment,
};

export type GetProjectLevelQuery = {
  getProjectLevel?:  Array< {
    __typename: "ClientProjectLevelResponse",
    ClientCode?: string | null,
    ClientCountry?: string | null,
    Level?: string | null,
    Status?: string | null,
    Updated_at?: string | null,
    Updated_by?: string | null,
  } | null > | null,
};

export type SubscribeToUpdatedStatusSubscriptionVariables = {
  id?: string | null,
};

export type SubscribeToUpdatedStatusSubscription = {
  subscribeToUpdatedStatus?:  {
    __typename: "StatusInfo",
    id?: string | null,
    status?: statusEnum | null,
  } | null,
};

export type OverviewStatusSubscriptionVariables = {
  source?: string | null,
  client?: string | null,
  campaignId?: string | null,
};

export type OverviewStatusSubscription = {
  overviewStatus?:  {
    __typename: "overviewStatusResponse",
    source: string,
    severity: Severity,
    status: OverviewStatus,
    message?: string | null,
    client?: string | null,
    campaignId?: string | null,
    updatedAt?: string | null,
  } | null,
};
