import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from "highcharts/highcharts-3d";
import { useTheme } from 'styled-components';


Highcharts.SVGRenderer.prototype.symbols['c-rect'] = function (x: any, y: any, w: any, h: any) {
    return ['M', x, y + h / 2, 'L', x + w, y + h / 2];
};

highcharts3d(Highcharts);
type Props = {
    data: any;
    title: string
};

const colorArray = ["#7D98AB", "#DBBC8E", "#A9C8C0", "#C6ADAE", "#698296", "#A3B6C4", "#E5D0AF", "#C2D8D3", "#95A7B5", "#AE8A8C"]


function SummaryChartsContainer(props: Props) {
    const theData = [] as any;
    const theAxis = [] as any;
    const theme = useTheme()
    const isCostPerMedia = props.title === "Cost per media"

    let newData = props.data
    // data is a list of objects, each object has two properties media_source and actual_cost.
    // i need to go through the list and if  there is BVOD and TV media_source, i need to create a new object with the sum of the actual_cost of both and the property media_source should be "VIDEO"
    // if there is only one of them, i just need to rename the media_source to "VIDEO"
    if (isCostPerMedia && (props.data.some((e: any) => e.media_source === "BVOD") || props.data.some((e: any) => e.media_source === "TV"))) {
        const dataWithVideo = props.data?.map((e: any) => {
            if (e.media_source === "BVOD" || e.media_source === "TV") {
                return { media_source: "VIDEO", actual_cost: e.actual_cost }
            } else {
                return e
            }
        })
        const dataWithVideoSum = dataWithVideo.reduce((acc: any, obj: any) => {
            if (obj.media_source === "VIDEO") {
                acc += obj.actual_cost
            }
            return acc
        }, 0)
        const dataWithVideoSumObject = { media_source: "VIDEO", actual_cost: dataWithVideoSum }
        const dataWithVideoSumArray = dataWithVideo.filter((e: any) => e.media_source !== "VIDEO")
        dataWithVideoSumArray.push(dataWithVideoSumObject)
        newData = dataWithVideoSumArray
    } else {
        newData = props.data
    }




    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [index, [key, value]] of Object.entries(newData[0]).entries()) {


        if (typeof value === 'number' || value === "Infinity" || value === "NaN" || key === "Start date" || key === "End date" || value === null) {
            const yaxis = {
                min: 0,
                labels: {
                    format: '{value}',
                    style: {
                        color: colorArray[theData.length],
                    },
                    enabled: false
                },
                title: {
                    text: key,
                    style: {
                        color: colorArray[theData.length],
                    },
                    enabled: false,
                },
                gridLineWidth: 0,

            };

            const dataSeries = newData.map((e: any) => {
                return (e[key as keyof typeof e] === undefined) || (e[key as keyof typeof e] === "NaN") ? null : e[key as keyof typeof e]
            })


            const ser = {
                name: key,
                type: "column",
                data: isCostPerMedia ? dataSeries : dataSeries,
                color: colorArray[theData.length],
                yAxis: 0,
                size: "100%",
            };

            theData.push(ser);
            theAxis.push(yaxis);

        }

    }



    const categ = isCostPerMedia ? [] : [] as any;

    newData.forEach((object: any) => {
        Object.entries(object).forEach(([key, value]) => {
            if (typeof value === 'string' && value !== "Infinity" && key !== "Start date" && key !== "End date" && value !== "NaN") {
                categ.push(value);
            }
        });
    });


    const [options, setOptions] = useState<any>()

    const setOptionsObject = () => {
        return {
            chart: {
                backgroundColor: 'transparent',
                zoomType: 'xy',
                type: "column",
                style: {
                    fontFamily: "fontRegular"
                },
                /*      width: "100&",
                     height: 150 */
            },
            title: {
                text: props.title,
                align: 'left',
                style: {
                    color: theme.primaryGray,
                    fontFamily: "fontRegular",
                    fontSize: "14px"
                }
            },

            yAxis:
                theAxis,
            xAxis: {
                categories: categ,
                crosshair: true,
                gridLineWidth: 0,
                labels: {
                    style: {
                        color: theme.primaryGray,
                    }
                },

            },
            tooltip: {
                useHTML: true,
                followPointer: true,
                shared: false,
                // Highcharts.TooltipFormatterContextObject
                formatter: function (this: any) {
                    let cat = categ;
                    const x = this.point.x;
                    const a = this.y;
                    const b = this.series.processedYData.reduce((a: any, b: any) => a + b, 0)
                    const bvod = props.data.find((e: any) => e.media_source === "BVOD") ? props.data.find((e: any) => e.media_source === "BVOD").actual_cost : 0
                    const tv = props.data.find((e: any) => e.media_source === "TV") ? props.data.find((e: any) => e.media_source === "TV").actual_cost : 0

                    return cat[x] === "VIDEO" ? '<b>' + cat[x] + '</b><br>'
                        + this.series.name + " : " + this.y + "<br> (" + (a / b * 100).toFixed(5) + "%)<br>"
                        + "<b>BVOD:</b> " + bvod + "<br> <b>TV:</b>: " + tv
                        : '<b>' + cat[x] + '</b><br>' + this.series.name + " : " + this.y + "<br> (" + (a / b * 100).toFixed(5) + "%)"
                },
            },

            plotOptions: {
                column: {
                    groupPadding: 0.1,
                    pointPadding: 0,
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        overflow: 'none',
                        formatter: function (this: any) {
                            return Intl.NumberFormat('en-DK', { notation: 'compact' }).format(this.y)
                        },
                        style: {
                            color: theme.primaryGray,
                            textOutline: 'transparent'
                        }
                    }
                },
            },
            series: theData,
            credits: { enabled: false },

            legend: {
                enabled: false,
                labelFormatter: function (this: any) {

                    return this.name
                },
                itemStyle: {
                    color: theme.primaryGray,
                    fontWeight: "normal",
                    font: "fontMedium"
                }
            },

        }
    }





    useEffect(() => {
        setOptions(null)
        setTimeout(() => {
            setOptions(setOptionsObject())
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    return (
        <HighchartsReact containerProps={{ style: { height: "100%", width: "100%" } }} highcharts={Highcharts} options={options} ref={chartComponentRef} {...props} allowChartUpdate={true} />
    );
}

export default SummaryChartsContainer;