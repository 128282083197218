/* eslint-disable react-hooks/exhaustive-deps */
import { linkListsResponse, GetAllLinksByOrgQuery, StatementType, GetUserV2Query } from 'API';
import { API } from 'aws-amplify';
import { useTab } from 'customHooks/useParameters';
import { getAllLinksByOrg, getUserV2 } from 'graphql/queries';
import { createContext, useState, useContext, useEffect } from 'react';
import APIContext from './APIContext';
import { useClientContext } from './ClientContext';



type PortalContextProps = {
  drawerIsOpen: boolean,
  setDrawerIsOpen: (arg: boolean) => void
  editCampaign: boolean,
  setEditCampaign: (arg: boolean) => void
  budgeBrief: budgeBrief | null
  setBudgetBrief: (arg: budgeBrief | null) => void
  budgetCTC: budgetPlan | null
  setBudgetCTC: (arg: budgetPlan | null) => void
  budgetApproved: budgetPlan | null
  setBudgetApproved: (arg: budgetPlan | null) => void
  budgetListPerRender: budgetListClient[]
  setBudgetListPerRender: (arg: budgetListClient[]) => void,
  linksResponse: linkListsResponse | undefined,
  fetchLinks: () => void,
  copyOfTab: string | null, 
  setCopyOfTab: (arg: string | null) => void
}

export type budgeBrief = {
  budget: number
  currency: string
}

export type budgetPlan = {
  budget: number
}

export type budgetListClient = {
  campaignId: string,
  budget: number,
  currency?: string,
}


export const PortalContext = createContext<PortalContextProps | undefined>(undefined)


export const PortalContextProvider = (props: any) => {
  const { insights, userAccess, activeOrganization, checkPaddington } = useClientContext();
  const { getEnvironment } = useContext(APIContext);
  const [drawerIsOpen12, setDrawerIsOpen] = useState(false)
  // const [drawerType, setDrawerType] = useState<any>()
  const [editCampaign, setEditCampaign] = useState(false)
  const [budgeBrief, setBudgetBrief] = useState(null as null | budgeBrief)
  const [budgetCTC, setBudgetCTC] = useState(null as null | budgetPlan)
  const [budgetApproved, setBudgetApproved] = useState(null as null | budgetPlan)
  const [budgetListPerRender, setBudgetListPerRender] = useState([] as budgetListClient[])

  const [avaliableCLientCodes, setAvaliableClientCodes] = useState([] as string[])
  const isAgency = userAccess?.fullAccess
  const [copyOfTab, setCopyOfTab] = useState<string | null>(null)

  const [tab] = useTab()
  const drawerIsOpen = tab === "brief" || tab === "plan" || tab === "summary" ? true : false



  const [linksResponse, setLinksResponse] = useState<linkListsResponse | undefined>(undefined)
  async function fetchLinks(listofcodes?: string[]) {
    setLinksResponse(undefined)
    try {
      const response = await API.graphql({
        query: getAllLinksByOrg,
        variables: {
          Organization_id: activeOrganization?.OrganizationId,
          Environment: getEnvironment,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS"
      }) as { data: GetAllLinksByOrgQuery }
      const data = response.data.getAllLinksByOrg
      if (data) {
        const filterArray = listofcodes ?? avaliableCLientCodes
        // console.log("LINKSDATA", data)
        // console.log("FILTERED", filterArray, data)
        const filteredLinksBasedOnClientCodes: linkListsResponse = {
          __typename: "linkListsResponse",
          links: data.links?.filter(link =>
            link?.clientCodes?.some(clientCode => filterArray.includes(clientCode?.ClientCode as string))
          ),
          order: data.order
        }
        // console.log("FILTERED LINKS", filteredLinksBasedOnClientCodes)
        setLinksResponse(filteredLinksBasedOnClientCodes as linkListsResponse)
      }
    } catch (error) {
      console.error("Error fetching links", error)
    }
  }




  const checkCodeAccess = async () => {
    try {
      const response = (await API.graphql({
        query: getUserV2,
        variables: {
          Environment: getEnvironment, // Environment.DEMO,
          Organization_id: activeOrganization?.OrganizationId,
          StatementType: StatementType.Access,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetUserV2Query };
      // console.log("ACCESS USER", response)
      const data = response.data.getUserV2
      if (data) {
        if (isAgency) {
          const fullAccess = data?.Access?.find(a => a?.Level?.includes("FULL"))?.Clients ?? []
          const readAccess = data?.Access?.find(a => a?.Level?.includes("READ_ACCESS"))?.Clients ?? []
          const readPlusAccess = data?.Access?.find(a => a?.Level?.includes("READ_PLUS_ACCESS"))?.Clients ?? []
          const joinedAccess = [...fullAccess, ...readAccess, ...readPlusAccess]
          const uniqueAccess = Array.from(new Set(joinedAccess))
          const uniqueAccessClientCodes = uniqueAccess.map(a => a?.ClientCode ?? "")
          // console.log("FILTERED", uniqueAccessClientCodes)
          setAvaliableClientCodes(uniqueAccess.map(a => a?.ClientCode ?? ""))
          fetchLinks(uniqueAccessClientCodes)

        } else if (!isAgency) {
          const readPlusAccess = data?.Access?.find(a => a?.Level?.includes("READ_ACCESS"))?.Clients ?? []
          const uniqueAccess = Array.from(new Set(readPlusAccess))
          const uniqueAccessClientCodes = uniqueAccess.map(a => a?.ClientCode ?? "")
          setAvaliableClientCodes(uniqueAccessClientCodes)
          fetchLinks(uniqueAccessClientCodes)
        }
      }




    } catch (e) {
      console.log('ERROR IN FETCH DASHBOARDS', e);
    }
  }

  useEffect(() => {
    if (activeOrganization?.OrganizationId && isAgency !== undefined) {
      checkCodeAccess()
    }
  }, [activeOrganization, isAgency])


  return (
    <PortalContext.Provider
      value={{
        drawerIsOpen,
        setDrawerIsOpen,
        editCampaign,
        setEditCampaign,
        budgeBrief,
        setBudgetBrief,
        budgetCTC,
        setBudgetCTC,
        budgetApproved,
        setBudgetApproved,
        budgetListPerRender,
        setBudgetListPerRender,
        linksResponse,
        fetchLinks,
        copyOfTab, 
        setCopyOfTab
      }}
    >
      {props.children}
    </PortalContext.Provider>
  );



};


export const usePortalContext = () => {
  const context = useContext(PortalContext)
  if (context === undefined) {
    throw new Error("context needs to be provided in portal context")
  }
  return context
}