import { set } from "lodash";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type IdResult = [string, (value: string) => void, () => void];


export const useClientCode = (): IdResult => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParam = "client";
  const getId = (): string => {
    const clientCode = searchParams.get(searchParam);
    // console.log("this is the client code", clientCode);

    return clientCode ?? "none";
  };

  const setId = (value: string): void => {
    const newSearchParams = new URLSearchParams();
    // if '#' is in the string, change it to '%23'
    /*   const valueToSet = value.includes("#") ? value.replace("#", "%23") : value; */
    newSearchParams.set(searchParam, value);
    /*     console.log("this is the value", value, valueToSet, newSearchParams); */

    // setSearchParams("client", value.toString());

    /*    newSearchParams.delete("campaignID");
       newSearchParams.delete("subcampaignID");
       setSearchParams(newSearchParams);
   
   
    */
    setSearchParams(newSearchParams);
  };

  const removeId = useCallback((): void => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete(searchParam);
    setSearchParams(newSearchParams);
  }, [searchParams, searchParam, setSearchParams]);





  return [getId(), setId, removeId];
};






export const useCampaignId = (): IdResult => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParam = "campaignID";

  const getId = (): string => {
    const param = searchParams.get(searchParam) ?? "none";
    // console.log("SUBCAMPAIGN", param)
    return param;
  };


  const setId = (value: string): void => {
    const newSearchParams = new URLSearchParams(searchParams.toString());

    newSearchParams.set(searchParam, value);
    newSearchParams.delete("subcampaignID");
    setSearchParams(newSearchParams);
  };

  const removeId = (): void => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete(searchParam);
    setSearchParams(newSearchParams);
  };


  return [getId(), setId, removeId];
};




export const useSubCampaignId = (): IdResult => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParam = "subcampaignID";

  const getId = (): string => {
    const param = searchParams.get(searchParam) ?? "none";


    // console.log("this is the param", param);

    return param;
  };

  // console.log("this is the param", searchParam, getId());

  const setId = (value: string): void => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set(searchParam, value);
    setSearchParams(newSearchParams);
  };

  const removeId = (): void => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete(searchParam);
    setSearchParams(newSearchParams);
  };

  return [getId(), setId, removeId];


};


export const useTab = (): IdResult => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParam = "tab";

  const getId = (): string => {
    const param = searchParams.get(searchParam) ?? "none";
    // console.log("this is the param", param);

    return param;
  };

  // console.log("this is the param", searchParam, getId());

  const setId = (value: string): void => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set(searchParam, value);
    setSearchParams(newSearchParams);
  };

  const removeId = (): void => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete(searchParam);
    setSearchParams(newSearchParams);
  };

  return [getId(), setId, removeId];
};
