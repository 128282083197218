/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, forwardRef, useEffect, useState } from 'react';
import font from "../assets/GTWalsheimProRegular.ttf";
import { useCampaignContext } from './CampaignContext';
import { TVJSON, OOHPRINT } from 'customHooks/useDataQuery';
import { orderList2 } from 'components/newSetup/SortingPlaceHolderList';
import ReactPDF, { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, pdf, Font } from '@react-pdf/renderer';
import { API, a } from 'aws-amplify';
import { getQueries, getTemplateById } from 'graphql/queries';
import { GetQueriesQuery, GetTemplateByIdQuery, UserQuery } from 'API';
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';
import pptxgen from "pptxgenjs";



import omdlogo from "../assets/lightomd.png"
import phdlogo from "../assets/ppt_assets/PHD_pptlogo.png"
import hslogo from "../assets/lightheartScienceNoBckg.png"
import pptbackground from "../assets/ppt_assets/templateImage.png"
import omgO from "../assets/ppt_assets/bigO.png"
import { chart } from 'highcharts';
import { useSlideDefinition } from 'methods/masterSlidesDefinitions';




const InputContext = createContext({

  TVKPI: {} as TVJSON,
  RADIOKPI: {} as TVJSON,
  OOHKPI: {} as OOHPRINT,
  PRINTKPI: {} as OOHPRINT,
  CINEMAKPI: {} as OOHPRINT,
  setNetworkList: (arg: any) => { },
  networkList: [],
  setTVKPI: (arg: any) => { },
  setRADIOKPI: (arg: any) => { },
  setOOHKPI: (arg: any) => { },
  setPRINTKPI: (arg: any) => { },
  setCINEMAKPI: (arg: any) => { },
  order2Table: [] as orderList2[],
  setOrder2Table: (arg: any) => { },
  order2Chart: [] as orderList2[],
  setOrder2Chart: (arg: any) => { },
  setCapture: (arg: boolean) => { },
  capture: false,
  updatePPT: (arg: any) => { },
});

export type PDFelementType = {
  placeholder: string | undefined,
  image: string | undefined,
  title: string,
  comment: string | undefined,
  captured: boolean,
  columns: any,
  data: any,
}

export const InputContextProvider = (props: any) => {
  const { activeCampaign, dashType, activeSubCampaign } = useCampaignContext();

  const [TVKPI, setTVKPI] = useState({} as TVJSON);
  const [RADIOKPI, setRADIOKPI] = useState({} as TVJSON);
  const [OOHKPI, setOOHKPI] = useState({} as OOHPRINT);
  const [PRINTKPI, setPRINTKPI] = useState({} as OOHPRINT);
  const [CINEMAKPI, setCINEMAKPI] = useState({} as OOHPRINT);
  const [networkList, setNetworkList] = useState<any>([]);
  const [order2Table, setOrder2Table] = useState<orderList2[]>([]);
  const [order2Chart, setOrder2Chart] = useState<orderList2[]>([]);
  let pres = new pptxgen();
  // const [bigO, setBigO] = useState<string>('');
  const { REACT_APP_AGENCY } = process.env;
  // const [backgroundImage, setbackgroundImage] = useState<string>('');

  const convertToBase64 = async (imageUrl: string, setBase64String: (base64: string) => void) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          const base64 = reader.result.replace('data:', '').replace(/^.+,/, '');

          setBase64String(base64);

        } else {
          console.error('Error: reader.result is not a string');
        }
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error converting image to base64:', error);
    }
  };
  // const logo = REACT_APP_AGENCY === "hs" ? convertToBase64(hslogo) : REACT_APP_AGENCY === "phd" ? convertToBase64(phdlogo) : omdlogo
  const [logo, setLogo] = useState<string>("")

  useEffect(() => {
    // convertToBase64(pptbackground, setbackgroundImage);
    // convertToBase64(omgO, setBigO);
    if (REACT_APP_AGENCY === "hs") {
      convertToBase64(hslogo, setLogo)
    } else if (REACT_APP_AGENCY === "phd") {
      convertToBase64(phdlogo, setLogo)
    } else {
      setLogo(omdlogo)
    }
  }, [])


  const [slideDef] = useSlideDefinition(REACT_APP_AGENCY as string)
  pres.layout = "LAYOUT_WIDE";
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  const todayDate = `${day}-${month}-${year}`;





  Font.register({
    family: "FamilyName",
    format: "truetype",
    src: font
  });


  const styles = StyleSheet.create({
    page: { padding: "20px"/* , fontFamily: "FamilyName"  */ },
    table: { width: "auto" },
    coverPage: { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" },
    coverText: { fontSize: 28, marginBottom: 20, textAlign: 'center', display: "flex", justifyContent: "center", alignItems: "center" },
    coverImage: { width: "100px", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }

  });


  useEffect(() => {
    setNetworkList([]);
  }, [dashType, activeCampaign, activeSubCampaign]);


  const [pdfElements, setPdfElements] = useState<PDFelementType[]>([])
  const arrayofSnapshots = [] as PDFelementType[]



  const [pptElements, setPPTElements] = useState<any[]>([{} as any])
  const arrayofSnapshotsPPT = [] as any[]
  const updatePPT = (element: any) => {
    const found = pptElements.find((pptElement) => pptElement.placeholder === element.placeholder)
    if (found) {
      arrayofSnapshotsPPT.push(element)
      if (
        arrayofSnapshotsPPT.length > 0 &&
        (
          (dashType === "DIGITAL" && arrayofSnapshotsPPT.length === pptElements.length) ||
          (dashType === "TV" && arrayofSnapshotsPPT.length === 7) ||
          (dashType === "RADIO" && arrayofSnapshotsPPT.length === 7) ||
          (dashType === "OOH" && arrayofSnapshotsPPT.length === 1) ||
          (dashType === "PRINT" && arrayofSnapshotsPPT.length === 1) ||
          (dashType === "CINEMA" && arrayofSnapshotsPPT.length === 1)
        )
      ) {

        pres.defineSlideMaster(slideDef.chartSlide);
        pres.defineSlideMaster(slideDef.tableSlide);
        pres.defineSlideMaster(slideDef.titleSlide);

        const introSlide = pres.addSlide({ masterName: "MASTER_SLIDE" })

        REACT_APP_AGENCY === "omd" && introSlide.addImage({ data: REACT_APP_AGENCY === "omd" ? logo : `image/png;base64,${logo}`, placeholder: "logo" })




        arrayofSnapshotsPPT.forEach(element => {

          if (element.columns === undefined) {
            if (element.placeholder !== "OOH1.1" && element.placeholder !== "Cinema1.1" && element.placeholder !== "Print1.1") {
              const checkIfAllTypesAreTheSame = element.theData.map((e: any) => e.type).every((val: any, i: any, arr: any) => val === arr[0])
              const isAnyStacked = element.theData.map((e: any) => e.originalType).some((val: any) => val === "stacked")

              const allTypesAreTheSame = checkIfAllTypesAreTheSame

              const options = {
                showTitle: false,
                title: "",
                chartArea: { fill: { color: REACT_APP_AGENCY === "omd" ? '#fafafa' : '#F7F7F6' } },
                x: REACT_APP_AGENCY === "omd" ? 0.5 : REACT_APP_AGENCY === "phd" ? 4.3 : 4.7,
                y: 1.5,
                w: 8,
                h: 4.25,
                showLabel: false,
                showLeaderLines: false,
                showLegend: true,
                legendPos: "b" as "b" | "l" | "r" | "t" | "tr" | undefined,
                showValue: true,
                dataLabelColor: '#8b8b8b',
                dataLabelFontSize: 10,
                catAxisLabelColor: '#8b8b8b',
                catAxisLabelFontSize: 10,
                serGridLine: { style: "none" as "none" | "solid" | "dash" | "dot" | undefined },
                valGridLine: { style: "none" as "none" | "solid" | "dash" | "dot" | undefined },
                valAxisLabelColor: '#8b8b8b',
                valAxisLabelFontSize: 10,

              }

              const slide = pres.addSlide({ masterName: "MASTER_SLIDE_CHART" });
              slide.addText(element.title, { placeholder: "title" });
              slide.addText(element.comment, { placeholder: "comment", fill: { color: '#fafafa' } });
              const dataChart = [] as any

              if (element.theData.length < 2) {

                const dataChartObject = {
                  name: element.theData[0].name,
                  labels: element.categories,
                  values: element.theData[0].data,
                }
                dataChart.push(dataChartObject)

                slide.addChart(element.theData[0].type === "column" ? "bar" : element.theData[0].type, dataChart,
                  { ...options, chartColors: element.theData[0].type === "pie" ? ["#7D98AB", "#A9C8C0", "#DBBC8E", "#C6ADAE", "#698296", "#A3B6C4", "#E5D0AF", "#C2D8D3", "#95A7B5", "#AE8A8C"] : ["#7D98AB"] },)
              } else if ((element.theData.length > 1 && allTypesAreTheSame && !isAnyStacked)) {

                const chD = element.theData.map((e: any, i: number) => {
                  return {
                    name: e.name,
                    labels: element.categories,
                    values: e.data,
                  }



                })

                slide.addChart(element.theData[0].type === "column" ? "bar" : element.theData[0].type, chD,
                  { ...options, chartColors: ["#7D98AB", "#A9C8C0", "#DBBC8E", "#C6ADAE", "#698296", "#A3B6C4", "#E5D0AF", "#C2D8D3", "#95A7B5", "#AE8A8C"] },
                )

              } else {
                const chD = element.theData.map((e: any, i: number) => {
                  return {
                    type: e.type === "column" ? pres.ChartType.bar : e.type === "scatter" ? "line" : pres.ChartType[e.type as keyof typeof pres.ChartType],
                    data: [{
                      name: e.name,
                      labels: element.categories,
                      values: e.data,
                    }],
                    options: i === 0 ? {
                      chartColors: [e.color],
                    } : {
                      chartColors: e.marker ? [e.marker.lineColor] : [e.color],
                      secondaryValAxis: element.theData[0].originalType === "stacked" ? false : true,
                      secondaryCatAxis: element.theData[0].originalType === "stacked" ? false : true,
                      showLabel: false,
                      showLegend: true,
                      showValue: true,
                      catAxisLineShow: false,
                      serAxisLineShow: false,
                      dataLabelBkgrdColors: false,
                      padding: 10,



                    }


                  }
                })

                const opt = element.theData.length === 1 || element.theData[0].originalType === "stacked" ? options :
                  element.theData.some((e: any) => e.type === "scatter") ? {
                    ...options,
                    lineSize: 0,
                    lineDataSymbolSize: 20,
                    lineDataSymbol: "dash",
                    lineDataSymbolLineSize: 1,

                    lineDataSymbolLineColor: REACT_APP_AGENCY === "omd" ? "#FF0000" : REACT_APP_AGENCY === "hs" ? "#2CB890" : "#38164f",

                    catAxes: [
                      { catAxisTitle: 'Primary Category Axis', catAxisHidden: false },
                      { catAxisTitle: 'Secondary Category Axis', catAxisHidden: true }
                    ],
                    valAxes: [
                      { valAxisTitle: 'Primary Value Axis', valAxisHidden: false },
                      { valAxisTitle: 'Secondary Value Axis', valAxisHidden: false },
                    ],
                  }

                    : {
                      ...options,
                      catAxes: [
                        { catAxisTitle: 'Primary Category Axis', catAxisHidden: false },
                        { catAxisTitle: 'Secondary Category Axis', catAxisHidden: true }
                      ],
                      valAxes: [
                        { valAxisTitle: 'Primary Value Axis', valAxisHidden: false },
                        { valAxisTitle: 'Secondary Value Axis', valAxisHidden: false },
                      ],
                    }

                slide.addChart(chD, opt as any)
              }
            } else {
              //TODO: THIS WORKS TO ADD CHART AS IMAGE TO PPT
              const slide = pres.addSlide();
              const base64image = element.image
              var img = document.createElement("img")
              img.setAttribute("src", base64image)
              slide.addText(element.title, { x: 0, y: 0, w: "100%", h: 0.5, fontSize: 15 });
              slide.addImage({ path: element.image, x: 0.6, y: 0.6, w: 12, h: element.ratioMultiplier  /* , sizing: { type: "contain", w: 12, h: element.ratioMultiplier } */ });
            }




          } else {
            const nekiid = `id${element.placeholder.replace(/\./g, '0')}`
            pres.tableToSlides(nekiid,
              {
                masterSlideName: "MASTER_SLIDE_TABLE",
                // x: 0.5,
                y: REACT_APP_AGENCY === "omd" ? 1.5 : 2.5,
                h: "90%",

                addText: {
                  text: element.title,
                  options: {
                    placeholder: "title",
                  }
                },
              }

            )
          }
        }
        )
        pres.writeFile({ fileName: "test" });
        setCapture(false)

      }



    }
  }

  const [capture, setCapture] = useState(false)
  const fetchStoredQueries = async () => {
    try {
      const response = (await API.graphql({
        query: getQueries,
        variables: {
          campaign_id: activeCampaign?.campaignId?.toString(),
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetQueriesQuery };
      const storedQueries = response.data.getQueries;

      const filterStoredQueriesBasedOnDashType = (listofQueries: UserQuery[]) => {

        const queriesList = listofQueries.filter((q) => {
          const position = q?.position || "";
          return !(
            position.startsWith("3") ||
            // position.startsWith("TV3") ||
            // position.startsWith("Radio3") ||
            position.startsWith("OOH3") ||
            position.startsWith("Print3") ||
            position.startsWith("Cinema3")
          );
        });
        if (dashType === "TV") {
          return queriesList?.filter((query) => query?.position?.startsWith("TV"))
        }
        if (dashType === "RADIO") {
          return queriesList?.filter((query) => query?.position?.startsWith("Radio"))
        }
        if (dashType === "OOH") {
          return queriesList?.filter((query) => query?.position?.startsWith("OOH"))
        }
        if (dashType === "PRINT") {
          return queriesList?.filter((query) => query?.position?.startsWith("Print"))
        }
        if (dashType === "CINEMA") {
          return queriesList?.filter((query) => query?.position?.startsWith("Cinema"))
        }
        if (dashType === "DIGITAL") {
          return queriesList?.filter((query) => query?.position?.startsWith("1") || query?.position?.startsWith("2"))
        }
      }



      const filteredStoredQueries = filterStoredQueriesBasedOnDashType(storedQueries as UserQuery[])



      const PDFElementss = filteredStoredQueries?.map(element => {
        return {
          placeholder: element?.position as string,
          image: undefined,
          title: element?.title === "Add title here" ? "" : element?.title as string,
          comment: undefined,
          captured: false,
          columns: undefined,
          data: undefined
        } as PDFelementType

      })
      setPdfElements(PDFElementss as PDFelementType[])
      setPPTElements(PDFElementss as PDFelementType[])





    } catch (e) {
      console.log('ERROR FETCHING QUARIES', e);
    }
  };

  const getTitles = (placeholder: string) => {
    if (placeholder === "TV1.1" || placeholder === "Radio1.1") {
      return "Station Split"
    } else if (placeholder === "TV1.2" || placeholder === "Radio1.2") {
      return "1+ Reach"
    } else if (placeholder === "TV1.3" || placeholder === "Radio1.3") {
      return "TRP Per Week"
    } else if (placeholder === "TV1.4" || placeholder === "Radio1.4") {
      return "Position in Block"
    } else if (placeholder === "TV2.1" || placeholder === "Radio2.1") {
      return "Spot Variation"
    } else if (placeholder === "TV2.2" || placeholder === "Radio2.2") {
      return "Timeslots"
    } else if (placeholder === "OOH1.1" || placeholder === "Print1.1" || placeholder === "Cinema1.1") {
      return "Overview"
    } else {
      return "Add title here"
    }
  }

  async function fetchTemplateById(templateID: any) {
    setPPTElements([])
    try {
      const response = (await API.graphql({
        query: getTemplateById,
        variables: {
          TemplateId: templateID
        },
        authMode: "AMAZON_COGNITO_USER_POOLS"
      })) as { data: GetTemplateByIdQuery }

      const placeholders = response.data.getTemplateById?.Placeholders
      if (placeholders) {
        const PPTElements = placeholders.map(element => {
          return {
            placeholder: element?.Placeholder as string,
            image: undefined,
            title: getTitles(element?.Placeholder as string),
            comment: undefined,
            captured: false,
            columns: undefined,
            data: undefined
          } as PDFelementType
        })
        setPPTElements(PPTElements as PDFelementType[])


      }

    } catch (e) {
      console.log("ERROR FETCHING TEMPLATE BY ID", e);

    }
  }

  useEffect(() => {
    if (activeCampaign) {
      if (dashType === "DIGITAL") {
        fetchStoredQueries();
      } else if (activeCampaign && dashType === "TV") {
        fetchTemplateById("c9614b9a-ed63-4a1c-9227-7d2c62247469")
      } else if (activeCampaign && dashType === "RADIO") {
        fetchTemplateById("26126d4e-f347-4441-b36c-7ab7d3598f47")
      } else if (activeCampaign && dashType === "OOH") {
        fetchTemplateById("7974c060-bad2-4048-9ce8-f9dbcc1a0753")
      } else if (activeCampaign && dashType === "PRINT") {
        fetchTemplateById("fd723399-b637-408c-a77a-d69104022dde")
      } else if (activeCampaign && dashType === "CINEMA") {
        fetchTemplateById("fd723399-b637-408c-a77a-kdfkgmsdfkgmsdf")
      }
    }
  }, [activeCampaign]);



  return (
    <InputContext.Provider
      value={{
        TVKPI,
        RADIOKPI,
        OOHKPI,
        PRINTKPI,
        CINEMAKPI,
        setNetworkList,
        networkList,
        setTVKPI,
        setRADIOKPI,
        setOOHKPI,
        setPRINTKPI,
        setCINEMAKPI,
        order2Table,
        setOrder2Table,
        order2Chart,
        setOrder2Chart,
        setCapture,
        capture,
        updatePPT
      }}
    >
      {props.children}
    </InputContext.Provider>
  );
};

export default InputContext;
