// import * as React from 'react';
// import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  useRoutes,
  Navigate,
  useLocation,
  Route,
  Routes,
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from 'react-router-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { AuthContextProvider } from './context/AuthContext';
import { ClientContextProvider } from './context/ClientContext';
import { APIContextProvider } from './context/APIContext';
import { CampaignContextProvider } from './context/CampaignContext';
import { EnrichedContextProvider } from './context/EnrichedContext';
import { InputContextProvider } from 'context/InputContext';
import { CommentsContextProvider } from 'context/CommentsContext';
import { PublishContextProvider } from 'context/PublishContext';
// import LoginPage from './components/Portal/LoginPage';
import { ClientStatementsContextProvider } from 'context/ClientStatementsContext';
import { createRoot } from 'react-dom/client';
// import UserAdmin from 'components/UserAdmin/UserAdmin';
import { StrictMode } from 'react';
import { TemplateContextProvider } from 'context/TemplateContext';
import { PortalContextProvider } from 'context/PortalContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import UserAdmin2 from 'components/UserAdmin/UserAdmin2';

const homepage = '/reporting';

const routes = createBrowserRouter([
  // { path: `${homepage}`, element: <App /> },
  // { path: '/', element: <Navigate to={homepage} replace /> },
  { path: `${homepage}/onlinePortal`, element: <App /> },
  { path: `${homepage}/userAdmin`, element: <UserAdmin2 /> },
  { path: `${homepage}/TVPortal`, element: <App /> },
  { path: `${homepage}/radioPortal`, element: <App /> },
  { path: `${homepage}/OOHPortal`, element: <App /> },
  { path: `${homepage}/printPortal`, element: <App /> },
  { path: `${homepage}/portal`, element: <App /> },
]);



let router2 = createBrowserRouter([
  {
    path: '/',
    Component() {
      return (
        <APIContextProvider>
          <AuthContextProvider>
            <ClientContextProvider>
              <CampaignContextProvider>
                <ClientStatementsContextProvider>
                  <TemplateContextProvider>
                    <PublishContextProvider>
                      <InputContextProvider>
                        <CommentsContextProvider>
                          <EnrichedContextProvider>
                            <PortalContextProvider>
                              <QueryClientProvider client={queryClient}>
                                <Outlet />
                              </QueryClientProvider>
                            </PortalContextProvider>
                          </EnrichedContextProvider>
                        </CommentsContextProvider>
                      </InputContextProvider>
                    </PublishContextProvider>
                  </TemplateContextProvider>
                </ClientStatementsContextProvider>
              </CampaignContextProvider>
            </ClientContextProvider>
          </AuthContextProvider>
        </APIContextProvider>
      );
    },
    children: [
      { path: `${homepage}`, element: <App /> },
      { path: '/', element: <Navigate to={homepage} replace /> },
      { path: `${homepage}/onlinePortal`, element: <App /> },
      { path: `${homepage}/userAdmin`, element: <UserAdmin2 /> },
      { path: `${homepage}/TVPortal`, element: <App /> },
      { path: `${homepage}/radioPortal`, element: <App /> },
      { path: `${homepage}/OOHPortal`, element: <App /> },
      { path: `${homepage}/printPortal`, element: <App /> },
      { path: `${homepage}/portal`, element: <App /> },
      { path: `${homepage}/cinemaPortal`, element: <App /> },
    ],
  },
]);

// const AllRoutes = () =>
//   useRoutes([
//     { path: `${homepage}`, element: <App /> },
//     { path: '/', element: <Navigate to={homepage} replace /> },
//     { path: `${homepage}/onlinePortal`, element: <App /> },
//     { path: `${homepage}/userAdmin`, element: <UserAdmin2 /> },
//     { path: `${homepage}/TVPortal`, element: <App /> },
//     { path: `${homepage}/radioPortal`, element: <App /> },
//     { path: `${homepage}/OOHPortal`, element: <App /> },
//     { path: `${homepage}/printPortal`, element: <App /> },
//     { path: `${homepage}/portal`, element: <App /> },
//     { path: `${homepage}/cinemaPortal`, element: <App /> },
//   ]);

const container = document.getElementById('root');
const root = createRoot(container!);
const queryClient = new QueryClient();

root.render(
  <StrictMode>
    <RouterProvider router={router2}></RouterProvider>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
