import React, { useContext, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from "highcharts/highcharts-3d";
import { queryInput } from 'API';

import styled, { useTheme } from 'styled-components';
import { MidRow } from 'components/Main/table/TableStyles';
import PreviewModal from 'components/Main/table/PreviewModal';
import { useHTML2Canvas } from 'customHooks/useHTML2Canvas';
import { CommentsContext } from 'context/CommentsContext';
import InputContext from 'context/InputContext';
import HCExporting from "highcharts/modules/exporting";



Highcharts.SVGRenderer.prototype.symbols['c-rect'] = function (x: any, y: any, w: any, h: any) {
    return ['M', x, y + h / 2, 'L', x + w, y + h / 2];
};

highcharts3d(Highcharts);
type Props = {
    data: any;
    page: any;
    title: any
    setTitle(title: any): any
    componentID: any,
    currentQuery: any;
    currentConversionsQuery: any;
    handleChanges(secondQuery: any, currentConversionsQueryCOPY: any): any;
    conversionsData: any;
    chartMetrics: any;
    setChartMetrics(chartMetric: any): any;



};

const colorArray = ["#7D98AB", "#A9C8C0", "#DBBC8E", "#C6ADAE", "#698296", "#A3B6C4", "#E5D0AF", "#C2D8D3", "#95A7B5", "#AE8A8C"]
const GraphsContainer = styled.div`
  input {
font-size: 12px;
padding: 8px;
border-radius: 7px;
width: 20vw;
border: 1px solid ${({ theme }) => theme.border};
color: ${({ theme }) => theme.primaryGray};
  }
  textarea:focus,input:focus {
    outline: 1px solid ${({ theme }) => theme.primaryGray};
}

select {
    border-radius: 7px;
    padding: 3px;
        font-family: 'FontRegular', sans-serif;
}
option {
    font-size: 14px;
    padding: 3px;
    font-family: 'FontRegular', sans-serif;
}
label {
    color: ${({ theme }) => theme.primaryGray};
}
.title {
    font-family: "FontRegular";
    font-size: 1.2vw;
    margin: 1vw 0;
    margin-left: 1vw;
}
`

function ChartsContainer(props: Props) {
    const { commentsList } = useContext(CommentsContext);
    const { capture, updatePPT } = useContext(InputContext)
    const theData = [] as any;
    const theAxis = [] as any;
    const categ = [] as any;
    const theme = useTheme()
    const tempDataCopy = JSON.parse(JSON.stringify(props.data))
    const mostkeys = tempDataCopy.sort(
        (a: any, b: any) => Object.keys(b).length - Object.keys(a).length
    )[0];
    const [TV11dropdown, setTV11dropdown] = useState("price_per_TRP")
    let TV11data: any




    //const metricsCount = Object.values(mostkeys).filter((el: any) => typeof (el) === "number")
    const metricsCount = [];
    const dimensionCount = [];
    const dimensionsKeys = []
    for (const [key, value] of Object.entries(mostkeys)) {
        if (typeof value === "number" || key === "Start date" || key === "End date" || value === "Infinity" || value === "NaN" || value === null) {
            metricsCount.push(value)
        } else if (typeof value === 'string' && value !== "Infinity" && key !== "Start date" && key !== "End date" && value !== "NaN") {
            dimensionCount.push(value)
            dimensionsKeys.push(key)
        }
    }



    if (dimensionCount.length < 2 || (dimensionCount.length === 2 && dimensionsKeys.includes("year_week") && dimensionsKeys.includes("data_source"))) {
        const sortedObj = Object.entries(mostkeys).sort((a, b) => {
            const typeA = typeof a[1];
            const typeB = typeof b[1];

            if ((typeA === 'string' && a[1] !== "NaN") && typeB !== 'string') {
                return -1; // string values come before non-string values
            } else if (typeA !== 'string' && (typeB === 'string' && a[1] !== "NaN")) {
                return 1; // non-string values come after string values
            } else {
                return 0; // types are the same or both non-string
            }
        });

        const mostKeysSorted = Object.fromEntries(sortedObj);
        const checkChartMetrics = (k: string) => {
            const check = props.chartMetrics.find((el: any) => el.metric === k)
            if (check) {
                if (check.chartType === "stacked") {
                    return "column"
                } else {

                    return check.chartType
                }
            }

        }

        const checkChartMetricsOriginal = (k: string) => {
            const check = props.chartMetrics.find((el: any) => el.metric === k)
            if (check) {


                return check.chartType

            }

        }
        if (dimensionCount.length < 2) {
            for (const [index, [key, value]] of Object.entries(mostKeysSorted).entries()) {


                if (typeof value === 'number' || value === "Infinity" || value === "NaN" || key === "Start date" || key === "End date" || value === null) {
                    const yaxis = {
                        min: 0,
                        labels: {
                            format: '{value}',
                            style: {
                                color: colorArray[theData.length],
                            },
                            enabled: false
                        },
                        title: {
                            text: key,
                            style: {
                                color: colorArray[theData.length],
                            },
                            enabled: false,
                        },
                        gridLineWidth: 0,

                    };

                    const dataSeries = props.data.map((e: any) => {
                        return (e[key as keyof typeof e] === undefined) || (e[key as keyof typeof e] === "NaN") ? null : e[key as keyof typeof e]
                    })

                    const ser = {
                        name: key,
                        type: checkChartMetrics(key),
                        originalType: checkChartMetricsOriginal(key),
                        data: dataSeries,
                        color: colorArray[theData.length],
                        // yAxis: props.chartMetrics[index - 1].axis,
                        // yAxis: props.chartMetrics[index - 1].axis,
                        yAxis: props.chartMetrics.find((el: any) => el.metric === key)?.axis,
                        center: [(theData.length + 0.3) * 200, null],
                        size: "100%",
                        percentage: 22,
                    };

                    theData.push(ser);
                    theAxis.push(yaxis);

                    if (theData.length === 1) {
                        theData[0].center = ["50%", null]
                        theData[0].size = "100%"
                    } else if (theData.length === 2) {
                        theData[0].center = ["25%", null]
                        theData[0].size = "60%"
                        theData[1].center = ["75%", null]
                        theData[1].size = "60%"
                    } else if (theData.length === 3) {
                        theData[0].center = ["15%", null]
                        theData[0].size = "35%"
                        theData[1].center = ["50%", null]
                        theData[1].size = "35%"
                        theData[2].center = ["85%", null]
                        theData[2].size = "35%"
                    } else if (theData.length === 4) {
                        theData[0].center = ["15%", null]
                        theData[1].center = ["40%", null]
                        theData[2].center = ["65%", null]
                        theData[3].center = ["90%", null]
                        theData[0].size = "30%"
                        theData[1].size = "30%"
                        theData[2].size = "30%"
                        theData[3].size = "30%"
                    }
                }

                if (theAxis[1]) {
                    theAxis[1].opposite = true;
                }

            }
        } else {
            // console.log(props.data)
            const fullData = props.data
            const mostKeys = fullData.sort(
                (a: any, b: any) => Object.keys(b).length - Object.keys(a).length
            )[0];
            // find the key of the property that has a value that is not a string
            const metricKey = Object.keys(mostKeys).find((el: any) => typeof mostKeys[el] !== "string")

            const groupedData = fullData.reduce((r: any, a: any) => {
                r[a.data_source] = [...r[a.data_source] || [], a];
                return r;
            }, {});
            const dataSources = Object.keys(groupedData)
            const dataSourcesArray = dataSources.map((source: any) => {
                const sourceData = groupedData[source]
                const sourceImpressions = sourceData.map((el: any) => el[metricKey as keyof typeof el])
                theData.push({
                    name: source,
                    data: sourceImpressions,
                    type: "line",
                    color: colorArray[theData.length],
                })

                return {
                    name: source,
                    data: sourceImpressions
                }
            })


            const longestData = dataSourcesArray.reduce((a: any, b: any) => a.data.length > b.data.length ? a : b)
            const longestDataName = longestData.name
            const yearweeks = groupedData[longestDataName].map((el: any) => el.year_week)
            categ.push(...yearweeks)
            theAxis.push({
                title: {
                    text: metricKey
                }
            })

            // props.setChartMetrics([{
            //     __typename: "MetricsResponse",
            //     chartType: "line",
            //     metric: "impressions",
            //     axis: 0,
            // }])


        }
    }

    if (dimensionCount.length < 2) {


        props.data.forEach((object: any) => {
            Object.entries(object).forEach(([key, value]) => {
                if (typeof value === 'string' && value !== "Infinity" && key !== "Start date" && key !== "End date" && value !== "NaN") {
                    categ.push(value);
                }
            });
        });



    }



    const [options, setOptions] = useState<any>()
    useEffect(() => {

        // setChartValue(props.chartMetrics[0].chartType)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    options && console.log("options", props.componentID, options, props.chartMetrics)

    const setOptionsObject = (type: any) => {


        if (type === "column") {
            return {
                chart: {
                    backgroundColor: 'white',
                    zoomType: 'xy',
                    type: "column",
                    style: {
                        fontFamily: "fontRegular"
                    }
                },
                title: {
                    text: '',
                },

                yAxis:
                    theAxis,
                xAxis: {
                    categories: categ,
                    crosshair: true,
                    gridLineWidth: 0,
                    labels: {
                        style: {
                            color: theme.primaryGray,
                        }
                    },

                },
                tooltip: {
                    useHTML: true,
                    followPointer: true,
                    shared: false,
                    // Highcharts.TooltipFormatterContextObject
                    formatter: function (this: any) {
                        let cat = categ;
                        const x = this.point.x;
                        const a = this.y;
                        const b = this.series.processedYData.reduce((a: any, b: any) => a + b, 0)
                        return '<b>' + cat[x] + '</b><br>' + this.series.name + " : " + this.y + "<br> (" + (a / b * 100).toFixed(5) + "%)"
                    },
                },

                plotOptions: {
                    column: {
                        pointPadding: 0.1,
                        distance: -60,
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            crop: false,
                            overflow: 'none',
                            formatter: function (this: any) {
                                return Intl.NumberFormat('en-DK', { notation: 'compact' }).format(this.y)
                            },
                            style: {
                                color: theme.primaryGray,
                                textOutline: 'transparent'
                            }
                        }
                    },
                },
                series: theData,
                credits: { enabled: false },
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 500,
                            },
                            chartOptions: {
                                legend: {
                                    floating: false,
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    x: 0,
                                    y: 0,
                                },
                                yAxis: [
                                    {
                                        labels: {
                                            align: 'left',
                                            x: 0,
                                            y: -6,
                                        },
                                        showLastLabel: false,
                                    },
                                    {
                                        visible: false,
                                    },
                                ],
                            },
                        },
                    ],
                },
                legend: {
                    enabled: true,
                    labelFormatter: function (this: any) {

                        return this.name
                    },
                    itemStyle: {
                        color: theme.primaryGray,
                        fontWeight: "normal",
                        font: "fontMedium"
                    }
                },

            }
        } else if (type === "pie") {
            return {
                chart: {
                    backgroundColor: 'white',
                    zoomType: 'xy',
                    type: "pie",
                    style: {
                        fontFamily: "fontRegular"
                    }
                },
                title: {
                    text: '',
                },

                yAxis:
                    theAxis,
                xAxis: {
                    categories: categ,
                    crosshair: true,
                    gridLineWidth: 0,
                    labels: {
                        style: {
                            color: theme.primaryGray,
                        }
                    },
                },
                tooltip: {
                    useHTML: true,
                    followPointer: true,
                    shared: false,
                    // Highcharts.TooltipFormatterContextObject
                    formatter: function (this: any) {
                        let cat = categ;
                        const x = this.point.x;
                        const a = this.y;
                        const b = this.series.processedYData.reduce((a: any, b: any) => a + b, 0)

                        return '<b>' + cat[x] + '</b><br>' + this.series.name + " : " + this.y + "<br> (" + (a / b * 100).toFixed(5) + "%)"
                    },
                },

                plotOptions: {

                    pie: {
                        dataLabels: {
                            allowPointSelect: true,
                            // distance: "-30%",
                            enabled: true,
                            allowOverlap: false,
                            align: "center",
                            style: {
                                color: theme.primaryGray,
                                textOutline: 'transparent',
                                border: "1px solid red"
                            },
                            // Highcharts.PointLabelObject
                            formatter: function (this: any) {
                                // let cat = categ;
                                const a = this.y;
                                const b = this.series.processedYData.reduce((a: any, b: any) => a + b, 0)
                                //  const x = this.point.x;
                                const test = this?.y;
                                if (test as number > 0) {
                                    return /* cat[x] + ":<br>" + */ Intl.NumberFormat('en-DK', { notation: 'compact' }).format(this.y) + " (" + (a / b * 100).toFixed(0) + "%)";
                                }
                            },
                        },
                        showInLegend: {
                            enable: true,
                            formatter: function (this: any) {
                                let cat = categ;
                                const x = this.point.x;
                                const test = this?.y;
                                if (test as number > 0) {
                                    return cat[x] + ":<br>" + this.y;
                                }
                            },
                        },
                        depth: 35,
                        colors: colorArray,
                        borderWidth: 0,

                    },

                },
                series: theData,
                credits: { enabled: false },
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 500,
                            },
                            chartOptions: {
                                legend: {
                                    floating: false,
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    x: 0,
                                    y: 0,
                                },
                                yAxis: [
                                    {
                                        labels: {
                                            align: 'left',
                                            x: 0,
                                            y: -6,
                                        },
                                        showLastLabel: false,
                                    },
                                    {
                                        visible: false,
                                    },
                                ],
                            },
                        },
                    ],
                },
                legend: {
                    enabled: true,
                    labelFormatter: function (this: any) {
                        var legendIndex = this.index;
                        var legendNamePie = this.series?.chart?.axes[0].categories[legendIndex];
                        return legendNamePie
                    },
                    itemStyle: {
                        color: theme.primaryGray,
                        fontWeight: "normal",
                        font: "fontMedium"
                    }
                },


            }
        } else if (type === "line") {
            return {
                chart: {
                    backgroundColor: 'white',
                    zoomType: 'xy',
                    //    type: "line",
                    style: {
                        fontFamily: "fontRegular"
                    }
                },
                title: {
                    text: '',
                },

                yAxis:
                    theAxis,
                xAxis: {
                    categories: categ,
                    crosshair: true,
                    gridLineWidth: 0,
                    labels: {
                        style: {
                            color: theme.primaryGray,
                        }
                    },
                },
                tooltip: {
                    useHTML: true,
                    followPointer: true,
                    shared: false,
                    // Highcharts.TooltipFormatterContextObject
                    formatter: function (this: any) {
                        let cat = categ;
                        const x = this.point.x;
                        const a = this.y;
                        const b = this.series.processedYData.reduce((a: any, b: any) => a + b, 0)

                        return '<b>' + cat[x] + '</b><br>' + this.series.name + " : " + this.y + "<br> (" + (a / b * 100).toFixed(5) + "%)"
                    },
                },

                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true,
                            crop: false,
                            overflow: 'none',
                            formatter: function (this: any) {
                                return Intl.NumberFormat('en-DK', { notation: 'compact' }).format(this.y)
                            },
                            style: {
                                color: theme.primaryGray,
                                textOutline: 'transparent'
                            }
                        }
                    },
                },
                series: theData,
                credits: { enabled: false },
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 500,
                            },
                            chartOptions: {
                                legend: {
                                    floating: false,
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    x: 0,
                                    y: 0,
                                },
                                yAxis: [
                                    {
                                        labels: {
                                            align: 'left',
                                            x: 0,
                                            y: -6,
                                        },
                                        showLastLabel: false,
                                    },
                                    {
                                        visible: false,
                                    },
                                ],
                            },
                        },
                    ],
                },
                legend: {
                    enabled: true,
                    labelFormatter: function (this: any) {

                        return this.name
                    },
                    itemStyle: {
                        color: theme.primaryGray,
                        fontWeight: "normal",
                        font: "fontMedium"
                    }
                },


            }
        } else if (type === "stacked") {
            return {

                chart: {
                    backgroundColor: 'white',
                    zoomType: 'xy',
                    type: "column",
                    style: {
                        fontFamily: "fontRegular"
                    }
                },
                title: {
                    text: '',
                },

                yAxis:
                    theAxis,
                xAxis: {
                    categories: categ,
                    crosshair: true,
                    gridLineWidth: 0,
                    labels: {
                        style: {
                            color: theme.primaryGray,
                        }
                    },
                },
                tooltip: {
                    useHTML: true,
                    shared: true,
                    followPointer: true,
                    // Highcharts.TooltipFormatterContextObject
                    /* formatter: function (this: any) {
                        let cat = categ;
                        const x = this.point.x;
                        const a = this.y;
                        const b = this.series.processedYData.reduce((a: any, b: any) => a + b, 0)

                        return '<b>' + cat[x] + '</b><br>' + this.series.name + " : " + this.y + "<br> (" + (a / b * 100).toFixed(5) + "%)"
                    }, */
                },

                plotOptions: {

                    column: {
                        pointPadding: 0.1,
                        distance: -60,
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            crop: false,
                            overflow: 'none',
                            formatter: function (this: any) {
                                return Intl.NumberFormat('en-DK', { notation: 'compact' }).format(this.y)
                            },
                            style: {
                                color: theme.primaryGray,
                                textOutline: 'transparent'
                            }
                        }
                    },
                    series: {
                        stacking: "normal"
                    }


                },
                series: theData.map((el: any) => {
                    el.yAxis = 0;
                    return el
                }),
                credits: { enabled: false },
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 500,
                            },
                            chartOptions: {
                                legend: {
                                    floating: false,
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    x: 0,
                                    y: 0,
                                },
                                yAxis: [
                                    {
                                        labels: {
                                            align: 'left',
                                            x: 0,
                                            y: -6,
                                        },
                                        showLastLabel: false,
                                    },
                                    {
                                        visible: false,
                                    },
                                ],
                            },
                        },
                    ],
                },
                legend: {
                    enabled: true,
                    labelFormatter: function (this: any) {
                        return this.name
                    },
                    itemStyle: {
                        color: theme.primaryGray,
                        fontWeight: "normal",
                        font: "fontMedium"
                    }
                },


            }
        } else if (type === "bullet") {
            return {
                chart: {
                    backgroundColor: 'white',
                    zoomType: 'xy',
                    type: "bullet",
                    style: {
                        fontFamily: "fontRegular"
                    }
                },
                title: {
                    text: '',
                },

                yAxis:
                {
                    gridLineWidth: 0,
                    title: null,
                    labels: {
                        format: '{value}',
                        style: {
                            color: colorArray[theData.length],
                        },
                        enabled: true
                    }
                },
                xAxis: {
                    categories: categ,
                    crosshair: true,
                    gridLineWidth: 0,
                    labels: {
                        style: {
                            color: theme.primaryGray,
                        }
                    },

                },
                tooltip: {
                    useHTML: true,
                    followPointer: true,
                    shared: false,
                    // Highcharts.TooltipFormatterContextObject
                    pointFormat: "<b>{point.y}</b> (with target at {point.target})"
                },

                plotOptions: {
                    series: {
                        pointPadding: 0.25,
                        borderWidth: 0,
                        color: colorArray[4],
                        targetOptions: {
                            width: "100%"
                        }
                    }
                },
                series: [{
                    data: TV11data[TV11dropdown],
                    targetOptions: {
                        color: theme.brand
                    },
                }],
                credits: { enabled: false },
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 500,
                            },
                            chartOptions: {
                                legend: {
                                    floating: false,
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom',
                                    x: 0,
                                    y: 0,
                                },
                                yAxis: [
                                    {
                                        labels: {
                                            align: 'left',
                                            x: 0,
                                            y: -6,
                                        },
                                        showLastLabel: false,
                                    },
                                    {
                                        visible: false,
                                    },
                                ],
                            },
                        },
                    ],
                },
                legend: {
                    enabled: true,
                    labelFormatter: function (this: any) {

                        return this.name
                    },
                    itemStyle: {
                        color: theme.primaryGray,
                        fontWeight: "normal",
                        font: "fontMedium"
                    }
                },

            }
        }
    }

    const [chartValue, setChartValue] = useState()
    const handleChartType = (index: any, type: any) => {
        setChartValue(type)
        const newChartMetrics = [...props.chartMetrics]
        if (type === "stacked") {
            newChartMetrics.map((el: any) => el.chartType = "stacked")
            props.setChartMetrics(newChartMetrics)
        } else {
            newChartMetrics[index].chartType = type
            props.setChartMetrics(newChartMetrics)
        }
    }

    const handleYAxis = (index: any, value: any) => {
        const newChartMetrics = [...props.chartMetrics]
        newChartMetrics[index].axis = Number(value)
        props.setChartMetrics(newChartMetrics)
    }

    useEffect(() => {

        setOptions(null)
        setTimeout(() => {
            if (props.chartMetrics.length === 1) {
                setOptions(setOptionsObject(props.chartMetrics[0].chartType))
            } else if (chartValue === "stacked" || props.chartMetrics[0].chartType === "stacked") {
                setOptions(setOptionsObject("stacked"))
            } else {
                setOptions(setOptionsObject("column"))
            }


        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chartMetrics, TV11dropdown])



    const [edit, setEdit] = useState(false)

    const handleSave = () => {
        setEdit(!edit)
    }


    // CHANGE ORDER
    const [currentQueryCOPY, setCurrentQueryCOPY] = useState<any>(undefined)
    const [mainReference, setMainReference] = useState<queryInput | undefined>(undefined)
    const [currentConversionsQueryCOPY, setCurrentConversionsQueryCOPY] = useState<queryInput | undefined>(undefined)
    const [hidden, setHidden] = useState(false)
    const [clickedHeader, setClickedHeader] = useState()
    const [activeObject, setActiveObject] = useState<any>()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [testObject, setTestObject] = useState<any>(() => {
        if (props.currentQuery !== undefined) {
            const testingStuff = JSON.parse(JSON.stringify(props.currentQuery))?.query.metrics
            testingStuff?.map((metric: any) => Object.assign(metric, { active: true }))
            return testingStuff
        }
    })


    useEffect(() => {
        if (props.currentQuery) {
            setCurrentQueryCOPY(JSON.parse(JSON.stringify(props.currentQuery)))
            setMainReference(JSON.parse(JSON.stringify(props.currentQuery)))
        }
    }, [props.currentQuery])


    // CREATE CONVERSIONS QUERY COPY
    useEffect(() => {
        if (props.currentConversionsQuery) {
            setCurrentConversionsQueryCOPY(JSON.parse(JSON.stringify(props.currentConversionsQuery)))
        }
    }, [props.currentConversionsQuery])


    const [items, setItems] = useState<any>();

    useEffect(() => {
        if (currentQueryCOPY !== null && currentQueryCOPY !== undefined) {
            setItems(Object.keys(mostkeys))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQueryCOPY])


    const openClose = (value: any) => {
        setActiveObject({})

        let QcalcMetrics = currentQueryCOPY?.query.calculatedMetrics
        let QMetrics = testObject
        let QDimensions = currentQueryCOPY?.query.detailedDimensions


        if (QcalcMetrics?.find((x: any) => x.name === value) !== undefined) {
            setActiveObject(QcalcMetrics.find((x: any) => x.name === value))
        } else if (QMetrics?.find((x: any) => x.name === value) !== undefined) {
            setActiveObject(QMetrics.find((x: any) => x.name === value))
        } else if (QMetrics?.find((x: any) => x.column === value) !== undefined) {
            setActiveObject(QMetrics.find((x: any) => x.column === value))
        } else if (QDimensions?.find((x: any) => x.name === value) !== undefined) {
            setActiveObject(QDimensions.find((x: any) => x.name === value))
        } else if (QDimensions?.find((x: any) => x.column === value) !== undefined) {
            setActiveObject(QDimensions.find((x: any) => x.column === value))
        }
        setHidden(!hidden)
        setClickedHeader(value)
    }

    // COMPARE CURRENTQUERY WITH COPY
    useEffect(() => {

        if (props.currentConversionsQuery === undefined && currentQueryCOPY !== undefined) {
            if (JSON.stringify(props.currentQuery) !== JSON.stringify(currentQueryCOPY)) {
                // console.log("CHANGES", props.currentQuery, currentQueryCOPY)
                // currentQueryCOPY?.query.calculatedMetrics.forEach((el: any) => {
                //     const foundSameCalcMetric = props.currentQuery?.query.calculatedMetrics.find((elCurrent: any) => elCurrent.query === el.query)
                //     if (foundSameCalcMetric) {
                //         console.log("FOUND SAME CALC METRIC", foundSameCalcMetric, el)
                //         if (foundSameCalcMetric.name !== el.name) {
                //             const newChartMEtrics = [...props.chartMetrics]
                //             const index = newChartMEtrics.findIndex((ele: any) => ele.metric === foundSameCalcMetric.name)
                //             console.log("INDEX", index)
                //             newChartMEtrics[index].metric = el.name
                //             props.setChartMetrics(newChartMEtrics)
                //         }
                //     }
                // }
                // )
                props.handleChanges(currentQueryCOPY, currentConversionsQueryCOPY)
            }
        } else if (currentConversionsQueryCOPY !== undefined && currentQueryCOPY !== undefined) {
            if ((JSON.stringify(props.currentQuery) !== JSON.stringify(currentQueryCOPY)) || (JSON.stringify(props.currentConversionsQuery) !== JSON.stringify(currentConversionsQueryCOPY))) {

                props.handleChanges(currentQueryCOPY, currentConversionsQueryCOPY)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentConversionsQueryCOPY, currentQueryCOPY, hidden])


    // console.log("options", options)
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const chartRef: any = useRef();
    // console.log("options", options)


    const returnObject = useHTML2Canvas(capture, chartRef.current, props.componentID as string, props.title, commentsList?.find(el => el.placeholder === props.title)?.comment ?? "")
    useEffect(() => {
        if (returnObject) {
            // updatePDFelements(returnObject);

            const tobeReturned = {
                ...returnObject,
                theData: theData,
                categories: categ,

            }

            updatePPT(tobeReturned);
        }
    }, [returnObject])



    return (
        <GraphsContainer className='graphsContainer'>
            <div className='chartsContainerTitle' style={{ height: "34px", textAlign: "left", width: "100%", padding: "0" }}>
                {!edit &&
                    <h1 className='title' style={{ width: "100%", margin: "0px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                        onClick={() => props.page === 6 ? setEdit(!edit) : null}
                        title={props.title === "Add title here" && props.page === 7 ? "" : props.title}>
                        {props.title === "Add title here" && props.page === 7 ? "" : props.title}
                    </h1>}
                {edit &&
                    <h1 className='title' style={{ marginLeft: "0px" }}>
                        <input value={props.title} style={{}} type="text" onChange={(e) => props.setTitle(e.target.value)} />
                        <button style={{ width: "fit-content" }} type='button' onClick={handleSave}>SAVE</button>
                    </h1>}
            </div>

            {props.page === 6 && items !== undefined &&
                <div style={{ display: "flex", flexDirection: "row", marginBottom: "1vw", marginTop: "1vw", textAlign: "center", width: "100%" }}>
                    {items.map((headerName: any) => (
                        <button style={{ fontSize: "0.8rem", width: "fit-content", marginRight: ".3vw", padding: ".6vw" }} key={headerName} onClick={(event) => openClose(headerName)} name={headerName} value={headerName} type='button'>{headerName}</button>
                    ))
                    }
                </div>}
            <div style={{ width: "100%" }}>
                {props.componentID === "TV1.1" && <div style={{ display: "inline-block" }}>
                    <label></label>
                    <select onChange={(e) => setTV11dropdown(e.target.value)}>
                        <option value="price_per_TRP">Pris pr. TRP</option>
                        <option value="TRPs">TRP</option>
                        <option value="budgets">Budget</option>
                    </select>
                </div>}
                {options && dimensionCount.length < 3 ? (
                    <div ref={chartRef}>
                        <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef}  {...props} allowChartUpdate={true} />

                    </div>
                ) : (
                    <MidRow style={{ textAlign: "center" }}>
                        <div className='text'>
                            <h2>It is not possible to display graphs with multiple dimensions</h2>
                            <h1>Please hide dimensions that you don't need and remember you can use them to filter your data first.</h1>
                        </div>
                    </MidRow>
                )}

                {props.page === 6 && props.componentID !== "TV1.1" && props.componentID !== "TV1.3" && <div style={{ display: "inline-block" }}>
                    <label>Change chart type: </label>

                    {props.chartMetrics.map((metric: any, index: any) => (
                        <select key={index} defaultValue={metric.chartType} value={chartValue !== "stacked" ? metric.chartType : "stacked"} onChange={(e) => handleChartType(index, e.target.value)} >
                            <option value="column">Column</option>
                            {metricsCount.length === 1 && <option value="pie">Pie</option>}
                            <option value="line">Line</option>
                            <option value="stacked">Stacked</option>
                        </select>
                    ))}
                    <br />

                    {chartValue !== "stacked" && metricsCount.length > 1 &&
                        <>
                            <label>Change Y Axis used: </label>
                            {props.chartMetrics.map((metric: any, index: any) => (
                                <select key={index} defaultValue={metric.axis} onChange={(e) => handleYAxis(index, e.target.value)} >
                                    {props.chartMetrics.map((count: any, i: any) =>
                                        <option key={i} value={i}>{i + 1}</option>)}
                                </select>
                            ))}</>}
                </div>}





            </div>

            {
                hidden &&
                <PreviewModal
                    hidden={hidden}
                    openClose={openClose}
                    activeObject={activeObject}
                    data={props.data}
                    clickedHeader={clickedHeader}
                    currentQueryCOPY={currentQueryCOPY}
                    currentQuery={props.currentQuery}
                    setCurrentQueryCOPY={(data: any) => setCurrentQueryCOPY(data)}
                    testObject={testObject}
                    mainReference={mainReference}
                    currentConversionsQueryCOPY={currentConversionsQueryCOPY}
                    currentConversionsQuery={props.currentConversionsQuery}
                    conversionsData={props.conversionsData}
                    setChartMetrics={props.setChartMetrics}
                    chartMetrics={props.chartMetrics}
                ></PreviewModal>
            }


        </GraphsContainer >
    );
}

export default ChartsContainer;