import styled, { useTheme, keyframes } from 'styled-components';
import Highcharts from 'highcharts/highcharts-gantt';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import { useClientContext } from 'context/ClientContext';
import { set } from 'lodash';
import { useGetGanttOptions } from 'customHooks/useGanttOptions';
import CustomEvents from 'highcharts-custom-events';

// CustomEvents(Highcharts);

export const GanttContainerStyled = styled.div`
  width: calc(100vw - 4.5vw);
  padding: 10px;
  grid-column: span 3;
  background-color: ${({ theme }) => theme.placeholderBackground};
  border: 1px solid ${({ theme }) => theme.border};
  z-index: 0;
  /* overflow-x: scroll; */
  /* overflow-y: auto; */
  max-height: 800px;

  path {
    stroke: ${({ theme }) => theme.border};
  }
  .highcharts-xaxis-grid {
    path:not(:last-child) {
      stroke: none;
    }
  }
`;

type Props = {
  // campaigns: ACdetails[];
};

export const SkeletonPulse = keyframes`
  0% { background-position: 100% 0; }
  100% { background-position: -200% 0; }
`;

export const Skeleton = styled.div`
  animation: ${SkeletonPulse} 1.5s ease-in-out infinite;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 200% 100%;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
`;

const GanttContainer = (props: Props) => {
  const { campaignList } = useCampaignContext();
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [series, setSeries] = useState<any[] | null>(null);
  const [o] = useGetGanttOptions(series);
  const [seter, setSeter] = useState<any | null>(null);


  useEffect(() => {
    const handleClick = (type: any, campaignId: number) => {
      const foundFlowchartCampaign = campaignList?.find((element: any) => element.campaignId === campaignId);
      if (foundFlowchartCampaign?.campaignId) {
        searchParams.set('campaignID', foundFlowchartCampaign?.campaignId.toString());
        searchParams.set('tab', type);
        setSearchParams(searchParams);
      }
    };
    let seriesArray = [] as any[];
    const groupedPerClientCode =
      campaignList !== undefined &&
      campaignList?.length > 0 &&
      campaignList.reduce(
        (result: any, item: any) => ({
          ...result,
          [item['ClientCode']]: [...(result[item['ClientCode']] || []), item],
        }),
        {},
      );
    // PREP DATA FOR GANTT CHART
    if (groupedPerClientCode) {
      for (const [index, [key, value]] of Object.entries(groupedPerClientCode).entries()) {
        const ran = Math.random().toString(36).substring(7);
        // CREATE PARENT DATA AND ADD THEM TO A SERIES ARRAY
        seriesArray.push({
          name: key,
          id: key + ran,
          pointWidth: 3,
        });
        // CREATE CHILD DATA AND ADD THEM TO A SERIES ARRAY
        const childData = (value as []).map((element: ACdetails) => {
          if (element)
            return {
              parent: key + ran,
              borderColor: 'transparent',
              name: element.campaign,
              start: new Date(element.startDate as string).getTime(),
              end: new Date(element.endDate as string).getTime(),
              events: {
                click: function (event: any) {
                  // Handle the click event here
                  // handleClick(Date.now() > new Date(element.endDate as string).getTime() ? "summary" : Date.now() < new Date(element.startDate as string).getTime() ? "brief" : "plan", element.campaignId)
                  if (
                    Date.now() > new Date(element.endDate as string).getTime() ||
                    (Date.now() > new Date(element.startDate as string).getTime() &&
                      Date.now() < new Date(element.endDate as string).getTime())
                  ) {
                    handleClick('summary', element.campaignId);
                  } else {
                    if (element.status === 'CREATED' || element.status === 'NOT_BRIEFED') {
                      handleClick('brief', element.campaignId);
                    } else if (element.status === 'BRIEF_COMPLETED' || element.status === 'BRIEFED') {
                      handleClick('plan', element.campaignId);
                    } else if (
                      element.status === 'PLANNING' ||
                      element.status === 'PLANNING_DONE' ||
                      element.status === 'PLANNING_STARTED' ||
                      element.status === 'PLANNED'
                    ) {
                      if (element.authorized) {
                        handleClick('summary', element.campaignId);
                      } else if (!element.authorized) {
                        handleClick('plan', element.campaignId);
                      }
                    } else if (
                      element.status === 'CAMPAIGN_PUBLISHED' ||
                      element.status === 'CAMPAIGN_PARTLY_PUBLISHED'
                    ) {
                      handleClick('summary', element.campaignId);
                    }
                  }
                },
              },
              dataLabels: {
                enabled: true,
                useHTML: true,
                format:
                  element.authorized &&
                  element.status === 'CAMPAIGN_PARTLY_PUBLISHED' &&
                  Date.now() > new Date(element.endDate as string).getTime()
                    ? `<div style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center; width="20px !important, pointer-event:none";"><svg width="15" height="15"><circle cx="7" cy="7" r="5"  stroke-width="1" fill="#ffad9b" /></svg></div>`
                    : element.authorized &&
                      element.status ===
                        'PLANNING_STARTED' /* && Date.now() > new Date(element.endDate as string).getTime() */
                    ? `<div style="display: flex; flex-direction: row; justify-content: flex-end; align-items: center; width="20px !important, pointer-event:none";"><svg width="15" height="15"><circle cx="7" cy="7" r="5"  stroke-width="1" fill="#E2FFE8" /></svg></div>`
                    : '',
                style: {
                  color: '#000000',
                  fontWeight: 'normal',
                  textOutline: 'none',
                },

                // x: Number(scaledWidth),
              },
              //  color: Date.now() > new Date(element.endDate as string).getTime() ? "green" : Date.now() < new Date(element.startDate as string).getTime() ? "pink" : "yellow"
              color:
                (element.status === 'NOT_BRIEFED' || element.status === 'CREATED') &&
                Date.now() < new Date(element.startDate as string).getTime() //IF THE CAMPAIGN IS NOT BRIEFED AND THE START DATE IS IN THE FUTURE
                  ? '#BFBFBF'
                  : (element.status === 'BRIEFED' ||
                      element.status === 'BRIEF_COMPLETED' ||
                      element.status === 'BRIEFED_FROM_CLIENT' ||
                      element.status === 'PLANNING_STARTED') &&
                    Date.now() < new Date(element.startDate as string).getTime() //IF THE CAMPAIGN IS BRIEFED AND THE START DATE IS IN THE FUTURE
                  ? '#EBC57D'
                  : (element.status === 'UNPUBLISHED' ||
                      element.status === 'PLANNING_DONE' ||
                      element.status === 'PLANNED') &&
                    Date.now() < new Date(element.startDate as string).getTime() //IF THE CAMPAIGN IS PLANNED AND THE START DATE IS IN THE FUTURE
                  ? '#E2FFE8'
                  : Date.now() > new Date(element.startDate as string).getTime() &&
                    Date.now() < new Date(element.endDate as string).getTime() //IF THE CAMPAIGN IS ACTIVE
                  ? '#A9C8C0'
                  : Date.now() > new Date(element.endDate as string).getTime() &&
                    element.status !== 'CAMPAIGN_PARTLY_PUBLISHED' &&
                    element.status !== 'CAMPAIGN_PUBLISHED' //IF THE CAMPAIGN IS COMPLETED
                  ? '#7D98AB'
                  : Date.now() > new Date(element.endDate as string).getTime() &&
                    element.status === 'CAMPAIGN_PARTLY_PUBLISHED' //IF THE CAMPAIGN IS PARTLY PUBLISHED
                  ? /* '#8BAEDF' */ '#7D98AB'
                  : Date.now() > new Date(element.endDate as string).getTime() &&
                    element.status === 'CAMPAIGN_PUBLISHED' //IF THE CAMPAIGN IS PUBLISHED
                  ? '#AFA2C3'
                  : '#000000',
              // color: '#BFBFBF',
            };
        });

        seriesArray.push(...childData);
      }
      setSeter(seriesArray);
      // setSeries([]);
      // setSeries(null)
    } 
    // else {
    //   setSeries(null);
    // }
  }, [campaignList, searchParams]);

  useEffect(() => {
    if (seter !== null) {
      setSeries(seter);
    }
  }, [seter]);

  return (
    <GanttContainerStyled ref={chartContainerRef}>
      {o !== null ? (
        <div style={{ paddingTop: '50px' }}>
          {campaignList !== undefined && (
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={'ganttChart'}
              options={o}
              allowChartUpdate={true}
              styledMode={true}
            />
          )}
        </div>
      ) : (
        <div>
          {Array(20)
            .fill(0)
            .map((_, index) => (
              <Skeleton key={index} style={{ height: '35px', marginTop: index > 0 ? '10px' : '0' }} />
            ))}
        </div>
      )}
    </GanttContainerStyled>
  );
};

export default GanttContainer;
