import DigitalKPIs from 'components/Main/DigitalKPIs';
import { AudienceReport, Graphs, MainContainer, Tables } from 'components/Main/Main.styles';
import { Add, TxtContainer2 } from 'components/Main/table/TableStyles';
import { useCampaignContext } from 'context/CampaignContext';
import ClientStatementsContext from 'context/ClientStatementsContext';
import { useDataSources } from 'customHooks/useDataSources';
import { useGetStoredQueriesDetails } from 'customHooks/useGetStoredQueriesDetails';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTemplateContext } from 'context/TemplateContext';
import ARContainer from 'components/Main/Gallery/ARContainer';
import { PublishContext } from 'context/PublishContext';
import SortingPlaceHolderList from './SortingPlaceHolderList';
import PreviewTV from 'assets/Preview/PreviewTV';
import { StyledGraphContainer } from 'components/Main/graf/GraphStyles';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';
import ClientPlaceholderContainer from './CLIENTCOMPONENTS/ClientPlaceholderContainer';
import InputContext from 'context/InputContext';
import NoCampaignSelected from './NoCampaignSelected';
import { useClientContext } from 'context/ClientContext';

type Props = {};

export type PlaceholderType = {
  type: 'Chart' | 'Table';
  minor: number;
  id: string;
  query_id?: string;
  duplicated?: boolean;
  original_id?: string;
  original_minor?: string;
  saved?: boolean;
  isChangedFromOriginal?: boolean;
};

const Main = (props: Props) => {
  const { activeCampaign } = useCampaignContext();

  const { statements } = useContext(ClientStatementsContext);
  const [newPlaceholder, setNewPlaceholder] = useState<PlaceholderType | undefined>(undefined);
  const { fetchedPlaceholders } = useTemplateContext();
  const { publishedData } = useContext(PublishContext);
  const theme = useTheme();
  const [dataSources] = useDataSources(activeCampaign);
  const [addingNewTable, setAddingNewTable] = useState(false as boolean)
  const [
    storedQueries,
    setStoredQueries,
    tablePlaceholderList,
    setTablePlaceholderList,
    setGraphPlaceholderList,
    graphPlaceholderList,
    publishedQueries,
  ] = useGetStoredQueriesDetails(activeCampaign, newPlaceholder, fetchedPlaceholders, publishedData, setAddingNewTable);
  const { order2Table, order2Chart, setCapture } = useContext(InputContext);
  const [newPlaceholderItem, setNewPLaceholderItem] = useState(undefined as PlaceholderType | undefined)
  const { anyCreate } = useClientContext();
  const isAgency = anyCreate;


  const componentIDs = [
    ['3.1', '3.2', '3.3', '3.4', '3.5'],
    ['4.1', '4.2', '4.3', '4.4', '4.5'],
    ['5.1', '5.2', '5.3', '5.4', '5.5'],
  ];

  const clickedAddNewElement = async (
    newElement: string,
    type?: string,
    componentQueryId?: string,
    componentParentId?: string,
  ): Promise<PlaceholderType | undefined> => {
    const isTable = newElement === 'Table';
    const isChart = newElement === 'Chart';

    if (isTable || isChart) {
      const placeholderList = isTable ? tablePlaceholderList : graphPlaceholderList;
      const lastNumber = placeholderList.at(-1);
      const nextNumber = lastNumber ? lastNumber.minor + 1 : storedQueries.filter((el) => el?.position?.startsWith(isTable ? '1' : '2')).length + 10;
      const newPosition = (isTable ? 1 : 2) * 100 + nextNumber;
      const query_id = newPosition;

      const newPlaceholder: PlaceholderType = {
        type: newElement,
        minor: nextNumber,
        id: newPosition.toString(),
        query_id: componentQueryId ?? query_id.toString(),
        duplicated: type === 'duplicated',
        original_id: type === 'duplicated' && componentParentId ? componentParentId : newPosition.toString(),
        saved: false,
      };


      await setNewPlaceholder(newPlaceholder);
      return newPlaceholder;
    }
  };


  const itemCreated = async () => {
    const thisIsNewItem = await clickedAddNewElement('Table')
    setNewPLaceholderItem(thisIsNewItem)
  }


  const findStoredQueryProp = (position: string): any => {

    return storedQueries.find((el) => el?.position === position);
  };

  const findOriginalElement = (query_id: string): PlaceholderType | undefined => {
    const originalElement = tablePlaceholderList.find((el) => el.query_id === query_id);
    if (originalElement) {
      return originalElement;
    } else return undefined;
  };

  const findStoriedQuery = (query_id: string) => {
    return storedQueries.find((el) => el?.query_id === query_id);
  };

  const findPublishedQueryProp = (position: string): any => {
    return publishedQueries.find((el) => el?.position === position);
  };









  return (
    <>
      {activeCampaign?.authorized && <button className='PDFbutton' onClick={() => setCapture(true)}>Download PPT</button>}


      {activeCampaign ? (
        <>
          {activeCampaign.authorized ? (
            <MainContainer>
              {/*     <DigitalKPIs></DigitalKPIs> */}
              {dataSources.length > 0 /* && storedQueries.length > 0  */ ? (
                <>
                  <div >
                    <DigitalKPIs></DigitalKPIs>
                    <Tables >
                      <SortingPlaceHolderList
                        tablePlaceholderList={tablePlaceholderList}
                        setTablePlaceholderList={setTablePlaceholderList}
                        findStoredQueryProp={findStoredQueryProp}
                        dataSources={dataSources}
                        clickedAddNewElement={clickedAddNewElement}
                        isGraph={false}
                        componentIDPrefix={'1'}
                        setStoredQueries={setStoredQueries}
                        storedQueries={storedQueries}
                        findStoriedQuery={findStoriedQuery}
                        findOriginalElement={findOriginalElement}
                        statements={statements}
                        addingNewTable={addingNewTable}
                        setAddingNewTable={setAddingNewTable}
                        newPlaceholderItem={newPlaceholderItem}
                        setNewPLaceholderItem={setNewPLaceholderItem}
                        type="Table"
                      />
                      {activeCampaign && statements?.Queries?.CREATE && (
                        <div className="addMoreButton" onClick={() => itemCreated()}>
                          + Add Placeholder
                        </div>
                      )}
                    </Tables>
                    <>
                      <SortingPlaceHolderList
                        tablePlaceholderList={graphPlaceholderList}
                        setTablePlaceholderList={setGraphPlaceholderList}
                        findStoredQueryProp={findStoredQueryProp}
                        dataSources={dataSources}
                        clickedAddNewElement={clickedAddNewElement}
                        isGraph={true}
                        componentIDPrefix={'2'}
                        setStoredQueries={setStoredQueries}
                        storedQueries={storedQueries}
                        findStoriedQuery={findStoriedQuery}
                        findOriginalElement={findOriginalElement}
                        statements={statements}
                        type="Chart"
                      />
                    </>
                  </div>
                  {componentIDs.map((ids, index) => (
                    <AudienceReport key={index}>
                      {ids.map((id) => (
                        <ARContainer key={id} componentID={id} />
                      ))}
                    </AudienceReport>
                  ))}
                </>
              ) : (
                <>
                  <StyledGraphContainer expand={0}>
                    <TxtContainer2 isGraph={false} displayTxt={0}>
                      <Add isGraph={true}>
                        <div style={{ paddingTop: '50%' }} className="text">
                          <ReactLoading className="loaderCentered" type="spinningBubbles" color={theme.publishButton} />
                        </div>
                      </Add>
                    </TxtContainer2>
                  </StyledGraphContainer>
                </>
              )}
            </MainContainer>
          ) : (
            <MainContainer key={publishedQueries[0]?.outputlocation ?? '123'}>
              <DigitalKPIs></DigitalKPIs>
              <Tables>
                {publishedQueries &&
                  tablePlaceholderList
                    .sort((a, b) => {
                      const orderA = order2Table.find(item => item.QueryId === a.id)?.Order || 0;
                      const orderB = order2Table.find(item => item.QueryId === b.id)?.Order || 0;
                      return orderA - orderB;
                    })
                    .map(
                      (el) =>
                        publishedQueries.some((query) => query.position === el.id) && (
                          <ClientPlaceholderContainer
                            key={el.id}
                            publishedQueriesDetails={findPublishedQueryProp(el.id)}
                            isGraph={false}
                            componentID={el.id}
                          ></ClientPlaceholderContainer>
                        ),
                    )}
              </Tables>
              <Graphs dashboard={'online'} columnsNumber={2}>
                {publishedQueries &&
                  graphPlaceholderList
                    .sort((a, b) => {
                      const orderA = order2Chart.find(item => item.QueryId === a.id)?.Order || 0;
                      const orderB = order2Chart.find(item => item.QueryId === b.id)?.Order || 0;
                      return orderA - orderB;
                    })
                    .map(
                      (el) =>
                        publishedQueries.some((query) => query.position === el.id) && (
                          <ClientPlaceholderContainer
                            key={el.id}
                            publishedQueriesDetails={findPublishedQueryProp(el.id)}
                            isGraph={true}
                            componentID={el.id}
                          />
                        ),
                    )}
              </Graphs>

              {componentIDs.map((ids, index) => (
                <AudienceReport key={index}>
                  {ids.map((id) => (
                    <ARContainer key={id} componentID={id} />
                  ))}
                </AudienceReport>
              ))}
            </MainContainer>
          )}
        </>
      ) : (
        <NoCampaignSelected page={"Digital"}></NoCampaignSelected>
      )}
    </>
  );
};

export default Main;
