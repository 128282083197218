import { IActiveOrganization, useClientContext } from "context/ClientContext";
import { useEffect, useState } from "react";

interface Props {
    activeOrganization: IActiveOrganization;
  }

export const useUserAccess = (props: Props): (boolean | undefined)[] => {
    const { activeOrganization } = props
    const { fetchMultipleAuthorizations } = useClientContext();
    const [userAccess, setUserAccess] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        const fetchAuthorizations = async () => {
          const authorizationResults = (await fetchMultipleAuthorizations('PUBLISH')) as any;
    
          const authorizedList = authorizationResults.map((item: any) => item.Authorized);
          const isAnyFalse = authorizedList.some((item: any) => item === false);
          setUserAccess(!isAnyFalse);
        };
    
        if (activeOrganization) {
          fetchAuthorizations();
        }
      }, [activeOrganization]);
  
  
    return [userAccess];
  };
  