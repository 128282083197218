import { queryInput } from "API";
import { useCampaignContext } from "context/CampaignContext";
import { useEffect, useState } from "react";

export const useGetMainTotalQuery = (mainQuery: queryInput, which: string): readonly [queryInput | undefined] => {
    const { REACT_APP_AGENCY } = process.env;
    const { activeCampaign } = useCampaignContext()
    const [mainTotalQuery, setMainTotalQuery] = useState<queryInput>()

    useEffect(() => {


        if (mainQuery) {
            const isFacebook = mainQuery.views[0]?.viewName.includes("facebook") ? true : false
            const isAdform = mainQuery.views[0]?.viewName.includes("adform") ? true : false
            const isDV360 = mainQuery.views[0]?.viewName.includes("dv360") ? true : false

            const dimMain = [
                {
                    column: 'campaign_id',
                    name: 'campaign_id',
                    hidden: false,
                    id: 'pero',
                },
            ]
            const dimCC = [
                {
                    column: 'campaign_id',
                    name: 'campaign_id',
                    hidden: false,
                    id: 'pero',
                },
                {
                    column: isFacebook ? 'conversion_name'
                        : isAdform ? 'filter_name'
                            : isDV360 ? 'floodlight_activity_name'
                                : 'activity',
                    // column: 'conversion_name',
                    name: 'CC_name',
                    hidden: false,
                    id: "CC_name",
                }
            ]


            const totalQuery = structuredClone(mainQuery);
            totalQuery.query.detailedDimensions?.forEach((element: any) => {
                const filteredOrder = totalQuery.query.orderBy?.filter((remove: any) => remove !== element.id);
                totalQuery.query.orderBy = filteredOrder;
            });
            totalQuery.query.detailedDimensions = which === "MAIN" ? dimMain : dimCC;
            if (totalQuery.query.detailedDimensions[0]?.id) {
                totalQuery.query.orderBy?.unshift(totalQuery.query.detailedDimensions[0].id);
            }
            totalQuery.query.metrics = totalQuery.query.metrics?.map((element: any) => (element.column.endsWith("reach") && isFacebook) ? { ...element, column: "campaign_mapping_reach" } : element)
            if (mainQuery.query.metrics?.some((el: any) => el.column.endsWith("reach") && isFacebook)) {
                if (mainQuery.query.filters?.some((el: any) => el.column !== "campaign_id")) {
                    totalQuery.query.nullColumns = ["campaign_mapping_reach"]
                }
                if (!totalQuery.views.some((el: any) => el.viewName === `fozzie_facebook_frequency_campaign_${REACT_APP_AGENCY}`)) {
                    totalQuery.views.push({
                        viewName: `fozzie_facebook_frequency_campaign_${REACT_APP_AGENCY}`, database: activeCampaign?.ClientCountry === "DK" ? "data_lake_denmark" : activeCampaign?.ClientCountry === "SE" ? "data_lake_sweden" :
                            activeCampaign?.ClientCountry === "FI" ? "data_lake_finland" : "data_lake_norway"
                    })
                }
            }

            totalQuery.query.orderBy = which === "MAIN" ? totalQuery.query.orderBy ?? [] : [...totalQuery.query.orderBy ?? [], "CC_name"];
            totalQuery.query.calculatedMetrics = totalQuery.query.calculatedMetrics?.map((element: any) => element.query.includes("/MAX") ? { ...element, query: element.query.slice(0, element.query.indexOf("/")).concat("/MAX(#campaign_mapping_reach)") } : element)
            if (mainQuery.query.calculatedMetrics?.some((el: any) => el.query.includes("/MAX"))) {
                if (mainQuery.query.filters?.some((el: any) => el.column !== "campaign_id")) {
                    totalQuery.query.nullColumns = ["campaign_mapping_reach"]
                }
                if (!totalQuery.views.some((el: any) => el.viewName === `fozzie_facebook_frequency_campaign_${REACT_APP_AGENCY}`)) {
                    totalQuery.views.push({
                        viewName: `fozzie_facebook_frequency_campaign_${REACT_APP_AGENCY}`, database: activeCampaign?.ClientCountry === "DK" ? "data_lake_denmark" : activeCampaign?.ClientCountry === "SE" ? "data_lake_sweden" :
                            activeCampaign?.ClientCountry === "FI" ? "data_lake_finland" : "data_lake_norway"
                    })
                }
            }
            setMainTotalQuery(totalQuery)
        }
    }, [REACT_APP_AGENCY, activeCampaign?.ClientCountry, mainQuery, which])



    return [mainTotalQuery]

}