






import React, { useContext, useEffect, useState } from 'react'
import { Modal } from './AddCampaignModal'
import { HeadModul, ModulContainer, Selections } from 'components/Main/table/TableStyles'
import { IoMdClose } from 'react-icons/io'
import styled from 'styled-components'
import { FormInputContainer } from './FormInput'
import { /* ClientCodesExtended, */ IconRepository, selectedLink } from './LinksContainer'
import { DarkButton } from 'components/style/Global'
import { ClientCodes, GetPreSignedUrlQuery, S3Method, linkResponse } from 'API'
import { FileUploader } from 'react-drag-drop-files'
import { API } from 'aws-amplify'
import { getPreSignedUrl } from 'graphql/queries'
import { useCampaignContext } from 'context/CampaignContext'
import APIContext from 'context/APIContext'
import { v4 as uuidv4 } from 'uuid';
import { useClientContext } from 'context/ClientContext'
import { iconList } from 'assets/newAssets/SVG/additionalIconsForLinks/iconList'


type Props = {
    handleManageLinkModal: (a: any) => void
    selectedLink: linkResponse
    saveLink: (a: linkResponse) => void
    availableClientCodes: ClientCodes[] | undefined
    handleUpdateLink: (a: linkResponse) => void
    // handleClientCodeClick: (clientCode: string) => void
}

export const Form = styled.div`
display: flex;
flex-direction: column;
align-items: center;




`



const ManageLinkModal = (props: Props) => {
    const [serchTerm, setSearchTerm] = useState<string>('');
    const { getEnvironment } = useContext(APIContext);
    const { activeCampaign } = useCampaignContext()
    const { activeOrganization } = useClientContext()
    const { handleManageLinkModal, selectedLink, saveLink, availableClientCodes/* , handleClientCodeClick */, handleUpdateLink } = props
    const isItEdit = selectedLink ? true : false
    const [values, setValues] = useState(selectedLink ??
    {
        Id: Math.floor(Math.random()).toString(),
        title: '',
        linkValue: '',
        icon: '',
        image: "",
        clientCodes: []
    })

    useEffect(() => {
        if (selectedLink?.image) {
            const type = selectedLink?.image?.split(".")[1]
            getPresigned(selectedLink?.image, `image/${type}`)
        }
    }, [selectedLink?.image])


    const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined)

    const handleClientCodeClick = (e: React.FormEvent<HTMLInputElement>) => {
        const clientCode = e.currentTarget.value
        if (values.clientCodes?.some(el => el?.ClientCode === clientCode)) {
            setValues({ ...values, clientCodes: values.clientCodes?.filter(el => el?.ClientCode !== clientCode) })
        } else {
            setValues({ ...values, clientCodes: [...values.clientCodes ?? [], { __typename: "ClientCodes", ClientCode: clientCode }] })
        }
    }


    const putPresigned = async (selectedImage: any) => {
        try {
            const fileN = `${uuidv4()}_${selectedImage.name}`;
            console.group("Adding to S3", {
                code: activeOrganization?.OrganizationId.split("#")[0],
                country: activeOrganization?.OrganizationId.split("#")[1],
            })
            setValues({ ...values, image: fileN })
            const preSignedURL = (await API.graphql({
                query: getPreSignedUrl,
                variables: {
                    ClientMethod: S3Method.put_object,
                    Client: {
                        code: activeOrganization?.OrganizationId.split("#")[0],
                        country: activeOrganization?.OrganizationId.split("#")[1],
                    },
                    Environment: getEnvironment,
                    FileName: fileN,
                    ContentType: selectedImage.type,
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: GetPreSignedUrlQuery };
            console.group("Adding to S3", preSignedURL)

            const url = preSignedURL.data.getPreSignedUrl?.url;

            addToS3(url, selectedImage);
        } catch (e) {
            console.log('Error getting presigned URL', e);
        }

    };

    async function addToS3(url: any, selectedImage: any) {
        // console.log('Adding to S3', url)
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': `${selectedImage.type}`,
                },
                body: selectedImage,
            });
            setSelectedImage(selectedImage);
        } catch (e) {
            console.log('Error adding to S3,', e);
        }
    }


    const getPresigned = async (name: any, cType: any) => {
        try {
            const preSignedURL = (await API.graphql({
                query: getPreSignedUrl,
                variables: {
                    ClientMethod: S3Method.get_object,
                    Client: {
                        code: activeOrganization?.OrganizationId.split("#")[0],
                        country: activeOrganization?.OrganizationId.split("#")[1],
                    },
                    Environment: getEnvironment,
                    FileName: name,
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: GetPreSignedUrlQuery };
            const url = preSignedURL.data.getPreSignedUrl?.url;

            getFromS3(url, name, cType);
        } catch (e) {
            console.log('Error getting presigned URL', e);
        }

    };


    async function getFromS3(url: any, name: string, cType: string) {
        try {
            const response = fetch(url, {
                method: 'GET',
            });
            const test = createFile((await response).url, name, cType);
            test.then((data) => {
                setSelectedImage(data);

            });
        } catch (err) {
            console.log({ err });
        }
    }


    async function createFile(url: any, name: string, cType: string) {
        if (typeof window === 'undefined') return; // make sure we are in the browser
        const response = await fetch(url);
        const data = await response.blob();
        const metadata = {
            type: cType || 'image/png',
        };
        return new File([data], name, metadata);
    }

    const [urlError, setURLError] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [iconError, setIconError] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [clientCodeError, setClientCodeError] = useState(false);

    const saveFunction = (values: linkResponse) => {
        if (values.linkValue !== "" && values.title !== "" && values.icon !== "" && values.clientCodes?.length !== 0 /* && values.image !== "" */) {
            saveLink(values);
            setURLError(false);
        }
        else {
            if (values.linkValue === "") {
                setURLError(true);
            }
            if (values.title === "") {
                setTitleError(true);
            }
            if (values.icon === "") {
                setIconError(true);
            }
            /*     if (values.image === "") {
                    setImageError(true);
                } */
            if (values.clientCodes?.length === 0) {
                setClientCodeError(true);
            }
        }
    }


    return (
        <Modal >
            <HeadModul style={{ height: "fit-content", maxHeight: "80vh" }}>
                <button type='button' className="x" onClick={() => handleManageLinkModal(undefined)}> <IoMdClose className='closeIcon' /></button>
                <ModulContainer>
                    <h1>Manage Links</h1>

                    <Form style={{ paddingLeft: "3vw", paddingRight: "3vw" }}>
                        <FormInputContainer >
                            <label htmlFor="">Display Name </label>
                            <input
                                type='text'
                                className={titleError ? 'error-input' : ''}
                                value={values.title as string}
                                onChange={(e) => {
                                    setValues({ ...values, title: e.target.value })
                                    if (titleError) {
                                        setTitleError(false);
                                    }
                                }} />
                            {titleError && <div className="error">Please fill in the Display Name field</div>}
                        </FormInputContainer>
                        <FormInputContainer >
                            <label htmlFor="">URL</label>
                            <input
                                type='text'
                                className={urlError ? 'error-input' : ''}
                                onChange={(e) => {
                                    setValues({ ...values, linkValue: e.target.value });
                                    if (urlError) {
                                        setURLError(false);
                                    }
                                }}
                                value={values.linkValue as string} />
                            {urlError && <div className="error">Please fill in the URL field</div>}
                        </FormInputContainer>





                        <FormInputContainer>
                            <label htmlFor="">Select Client Codes:</label>
                            <div className='iconContainer'>
                                {availableClientCodes?.map(code => (
                                    <Selections key={code.ClientCode} className='checkboxLabel'>
                                        <input
                                            type="checkbox"
                                            className={clientCodeError ? 'error-input' : ''}
                                            name="clientCodes"
                                            checked={values.clientCodes?.some(el => el?.ClientCode === code.ClientCode) ?? false}
                                            value={code.ClientCode as string}
                                            onChange={(e) => {
                                                handleClientCodeClick(e)
                                                if (clientCodeError) {
                                                    setClientCodeError(false);
                                                }
                                            }}
                                        />
                                        <div className='span'>
                                            {code.ClientCode}
                                        </div>
                                    </Selections>
                                ))}
                            </div>
                            {clientCodeError && <div className="error">Please select at least one Client Code</div>}
                        </FormInputContainer>

                        <FormInputContainer>
                            <label htmlFor="">Icons</label>
                            <input
                                className="searchBar"
                                type="text"
                                placeholder="Search.."
                                onChange={(event) => {
                                    setSearchTerm(event.target.value);
                                }}
                            ></input>
                            <div style={{ height: "fit-content", maxHeight: "300px", overflowY: "scroll" }} className='iconContainer' >
                                {/* {
                                    Object.values(iconRepository).map(iconObject => (
                                        <label>
                                            <input
                                                key={iconObject.value}
                                                type="radio"
                                                name="platforms"
                                                value={iconObject.value}
                                                onChange={(event) => setValues({ ...values, icon: event.target.value })}
                                                checked={values.icon === iconObject.value}
                                            />
                                            <div className="checked">

                                                {iconObject.icon}
                                            </div>
                                        </label>
                                    ))
                                } */}
                                {iconList
                                    ?.filter((val) => val.toLowerCase().includes(serchTerm.toLowerCase()))
                                    .map(icon => (
                                        // <span className="material-icons">{icon}</span>

                                        <label>
                                            <input
                                                key={icon}
                                                className={iconError ? 'error-input' : ''}
                                                type="radio"
                                                name="platforms"
                                                value={icon}
                                                onChange={(event) => {
                                                    setValues({ ...values, icon: event.target.value })
                                                    if (iconError) {
                                                        setIconError(false);
                                                    }
                                                }

                                                }
                                                checked={values.icon === icon}
                                            />
                                            <div className="checked material-icons">

                                                {icon}
                                            </div>
                                        </label>
                                    ))}
                            </div>
                            {iconError && <div className="error">Please select an Icon</div>}
                        </FormInputContainer>

                        <FormInputContainer>
                            <label htmlFor="">Upload image </label>
                            <div className='iconContainer' style={{ flexDirection: "column", display: "flex" }}>
                                <FileUploader
                                    clasName="drop_area"
                                    name="file"
                                    types={['JPG', 'PNG', 'JPEG']}
                                    handleChange={(file: any) => {
                                        putPresigned(file);

                                    }}
                                >
                                </FileUploader>
                                {selectedImage &&
                                    <div style={{ border: "1px solid, gray", borderRadius: "7px", padding: "10px" }}>
                                        <img style={{ maxHeight: "100px" }} src={URL.createObjectURL(selectedImage)} alt="uploaded" />
                                    </div>
                                }
                                <div className='imageExplanation'>Images uploaded here will be used on a home page. In case you don't provide an image, agency logo will be used as default.  </div>
                            </div>
                        </FormInputContainer>


                    </Form>
                    <div style={{ alignSelf: "flex-end" }} className="btnz">
                        <button onClick={() => handleManageLinkModal(undefined)} style={{ marginRight: ".3vw" }} type='submit'>Cancel</button>
                        <DarkButton onClick={() => { isItEdit ? handleUpdateLink(values) : saveFunction(values) }}>Save</DarkButton>
                    </div>
                </ModulContainer>

            </HeadModul>
        </Modal>
    )
}

export default ManageLinkModal