import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import FontRegular from '../../assets/GTWalsheimProRegular.ttf';
import FontMedium from '../../assets/GTWalsheimProMedium.ttf';
import FontBold from '../../assets/GTWalsheimProBold.ttf';

const GlobalStyles = createGlobalStyle`
 @font-face {
    font-family: 'FontRegular';
    src: url(${FontRegular}) format('truetype');
    font-style: normal;
    font-weight: normal;
  }
 @font-face {
    font-family: 'FontMedium';
    src: url(${FontMedium}) format('truetype');
    font-style: normal;
    font-weight: normal;
  }
 @font-face {
    font-family: 'FontBold';
    src: url(${FontBold}) format('truetype');
    font-style: normal;
    font-weight: normal;
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}
body, html {
    /* background: ${({ theme }) => theme.background}; */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
   // position: relative;
    font-family: 'FontRegular', sans-serif;
    margin: 0;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
    display: none;
}


}

h1 {
    color: ${({ theme }) => theme.primaryGray};  
    font-family: 'FontMedium', sans-serif; 
}

h2 {
    font-family: 'FontRegular', sans-serif; 
    color: ${({ theme }) => theme.secondaryGray};
}
h4{
    font-family: 'FontRegular', sans-serif; 
}


a {
    font-family: 'FontRegular', sans-serif;
    font-weight: 800;
    font-size: 1vw;
    text-decoration: none;
    font-size: 3vw;
    color: ${({ theme }) => theme.primaryGray};  
}



button, .someButton {     
cursor: pointer;
background: transparent;
border: none;
box-shadow: 0 0 0 1px  ${({ theme }) => theme.primaryGray}; 
border-radius: 7px;
width: fit-content;
margin-bottom: 8px;
font-family: 'FontMedium', sans-serif;
color: ${({ theme }) => theme.primaryGray};
padding: 9px 12px;
height: fit-content;
}

button:hover, .someButton:hover {
  box-shadow: 0 0 0 2px  ${({ theme }) => theme.primaryGray}; 
}

.searchBar {
    display: block !important;
    max-width: 200px;
    border: 1px solid ${({ theme }) => theme.border};
    background-color: rgba(0, 0, 0, 0);
    border-radius: 7px; 
    padding: 5px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.primaryGray};
    font-family: 'FontMedium', sans-serif;
}


.flex {
    display: flex;
}


.adformicon {
    margin-bottom: -5px;
    width: 2.8rem;
}
.cmicon {
    margin-bottom: -5px;
    width: 2.8rem;
}

p {
    color: ${({ theme }) => theme.primaryGray}; 
}

.containerBox {
  border-radius: 7px;
  padding: 20px;
  color: ${({ theme }) => theme.primaryGray};
  height: fit-content;

  border: 1px solid  ${({ theme }) => theme.border};;
}

input, select {
font-size: 12px;
padding: 8px;
border-radius: 7px;
border: 1px solid ${({ theme }) => theme.border};
color: ${({ theme }) => theme.primaryGray};
background-color:${({ theme }) => theme.background};
  }
  textarea:focus,input:focus {
    outline: 1px solid ${({ theme }) => theme.border};
}

option {
    font-size: 12px;
  }


  .loaderCentered {
//center the loader in the middle of the parent
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
 }

 .react-datepicker{
  font-family: "fontRegular";
       button {
    box-shadow: none;
   }
   .react-datepicker__day, .react-datepicker__day-name {
      width: 1.3rem;
    line-height: 1.4rem;
    }
  }

  .PDFbutton {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 100;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    /* border: 1px solid ${({ theme }) => theme.primaryGray}; */
    background-color: ${({ theme }) => theme.border};
  }

  .material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

`;

export default GlobalStyles;

export const IconButton = styled.button`
  font-size: 12px;
  font-family: 'FontBold', sans-serif;
  display: flex;
  gap: 2px;
  white-space: nowrap;
  margin: 0;
  color: ${({ theme }) => theme.primaryGray};
  border: none;
  box-shadow: none;
  margin-bottom: 3px;
  & > * {
    font-size: 14px;
  }
  &:hover {
    box-shadow: none;
  }
`;

export const DarkButton = styled.button`
  background-color: ${({ theme }) => theme.publishButton};
  white-space: nowrap;
  box-shadow: none;
  color: white;
  padding: 9px 48px;
  border: none;
  &.publish {
    background-color: ${({ theme }) => theme.publishButton};
    border: 1px solid ${({ theme }) => theme.publishButton};
  }
  &:hover {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.publishButton};
  }
`;
