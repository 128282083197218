import {
  AddDashboardAccessMutation,
  AddExternalUserMutation,
  AddInternalUserMutation,
  RemoveDashboardAccessMutation,
  getUserType,
  getUserTypeV2,
} from 'API';
import { API } from 'aws-amplify';
import { HeadModul, ModulContainer } from 'components/Main/table/TableStyles';
import { DarkButton } from 'components/style/Global';
import APIContext from 'context/APIContext';
import ClientContext, { useClientContext } from 'context/ClientContext';
import { IoMdClose } from 'react-icons/io';


import {
  addInternalUser,
  addExternalUser,
  updateUserAccess,
  addDashboardAccess,
  removeDashboardAccess,
} from 'graphql/mutations';
import { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import { useTheme } from 'styled-components'
const {
  REACT_APP_AGENCY
} = process.env;
const {
  REACT_APP_AGENCY_URL,
} = process.env;

const Modal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  display: flex;
  justify-content: center;
  align-items: center;
  

  .wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.4vw;
  }

  .filterTitle {
    margin: 0;
    padding: 0.4vw 0;
  }
  h1 {
    text-align: center;
   font-size: 32px;
   font-weight: normal;
  }

  .form {
    margin-top: 2vh;
    border: 2px solid ${({ theme }) => theme.border};
    margin-left: 4vw;
    margin-right: 4vw;
    margin-bottom: 1vw;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    padding: 0.5vw 0.9vw;
   border: 2px solid ${({ theme }) => theme.border};
  }

  .namesForm {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.5vw;
  }
  .selectorsForm {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    padding: 0 4vw;
  }
  input,
  label,
  h3 {
    display: block;
    margin-bottom: 1vh;
    margin-top: 0.3vh;
    font-weight: normal;
    color: ${({ theme }) => theme.primaryGray};  
    font-family: 'FontRegular', sans-serif; 
    font-size: 12px;
  }
  input {
    width: 17vw;
    font-size: 12px;
padding: 8px;
border-radius: 7px;
border: 1px solid ${({ theme }) => theme.border};
color: ${({ theme }) => theme.primaryGray};
background-color:${({ theme }) => theme.background};
  }

  .toggler {

    border: 2px solid ${({ theme }) => theme.border};
    border-radius: 7px;
    padding: 0.9vw;
    height: 40vh;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.border};
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.scrollThumb};
      border-radius: 10px;
    }

    legend {
      font-family: 'FontRegular', sans-serif;
      font-weight: 800;
      font-size: 0.7vw;
      margin-right: 1vw;
      color: ${({ theme }) => theme.primaryGray};
      border-bottom: 1px solid ${({ theme }) => theme.border};
    }
  }
  .onoff {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0.5vw;
    height: fit-content;

    button {
      all: initial;
      font-family: 'FontRegular', sans-serif;
      font-weight: 800;
      font-size: 1vw;
      color: red;
    }

    section {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid ${({ theme }) => theme.border};
      width: 100%;
      padding: 5px 15px 0px 15px;
      margin-bottom: 0;
      align-items: center;
      // border-radius: 50px;
      justify-content: space-between;

      legend {
        font-family: 'FontRegular', sans-serif;
        font-weight: 800;
        font-size: 0.7vw;
        margin-right: 1vw;
        color: ${({ theme }) => theme.primaryGray};
        border-bottom: none;

      }
      input[type='radio'] {
        opacity: 0;
        position: fixed;
        width: 0;
      }
      input[type='checkbox'] {
        opacity: 0;
        position: fixed;
        width: 0;
      }
      label {
        display: flex;
        align-items: center;
        /* display: inline-block; */
        padding: 6px;
        margin: 0rem !important;
        font-size: 0.6vw;
        color: ${({ theme }) => theme.secondaryGray};
    
        
        /*         cursor: pointer; */
      }
      input[type='radio']:checked + label {
        color: #20C933;
      }
      input[type='checkbox']:checked + label {
        color: #20C933;
      }

    }
    .dashboards {
      border: 1px solid ${({ theme }) => theme.border};
      border-radius: 5px;
      padding: 0px 15px 0px 15px;

    }
    .midChild {
      position: relative;
      padding-right: 0.6vw;
    }
    .midChild:after {
      content: ' ';
      position: absolute;
      border-left: 1px solid ${({ theme }) => theme.border};
      top: 25%;
      right: 0;
      height: 50%;
      margin-top: auto;
      margin-bottom: auto;
    }
  }


  .dashboardGrid {
    /*   display: grid;
  grid-template-columns: repeat(3, max-content); */
    display: flex;
    flex-wrap: wrap;
 

    input[type='checkbox'] {
      opacity: 0;
      position: fixed;
      width: 0;
    }
    .checked {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      margin: 4px 4px 2px 0px;
      font-size: 0.6vw;
      border: 1px solid ${({ theme }) => theme.border};;
      border-radius: 6px;
      width: fit-content;
      font-family: 'FontRegular', sans-serif;
    }

    input[type='checkbox']:checked + label {
      color: #5cdb5c;
    }


  }

  .dashboards {
    margin-bottom: 20px;
    max-width: 800px;
    legend {
        font-family: 'FontRegular', sans-serif;
        font-weight: 800;
        font-size: 0.7vw;
        margin-right: 1vw;
        color: ${({ theme }) => theme.primaryGray};
        border-bottom: none;

      }
  }

  .loaderDiv {
    position: absolute;
    margin-top: -50px;
    margin-left: -50px;
    top: 50%;
    left: 50%;
    width: 400px ;
    height:400px ;
  }
`;
type Props = {
  setSeeModal(seeModal: boolean): any;
  callBothUsers(): any;
  setModifyUser(modfyUser: any): any;
  setModifyUserInsights(par: any): any;
  setExternalUsers(props: any): any;
  setInternalUsers(props: any): any;
  seeModal: boolean;
  modifyUser: getUserTypeV2;
  userType: string;
  modifyUserInsights: any;
  typeOfReadAccess: any[];
};
const UserAdminModal2 = (props: Props) => {
  const { checkPaddington, insightsDashboards, activeOrganization } = useClientContext();
  const { getEnvironment } = useContext(APIContext);
  const [firstName, setFirstName] = useState<any>();
  const [lastName, setLastName] = useState<any>();
  const [mail, setMail] = useState<any>();
  const [selectedDashboards] = useState([] as string[]);
  const [fullList, setFullList] = useState<any>(
    checkPaddington?.Access?.filter(e => e?.Level?.includes("FULL"))[0]?.Clients?.map((el: any) => {
      delete el.__typename;
      return { ...el, AccessType: 'NONE' };
    }),
  );
  const [readList, setReadList] = useState<any>(
    checkPaddington?.Access?.filter(e => e?.Level?.includes("READ"))[0]?.Clients?.map((el: any) => {
      delete el.__typename;
      return { ...el, AccessType: 'NONE' };
    }),
  );


  const [userInsights, setUserInsights] = useState(
    props.modifyUserInsights.length > 0
      ? props.modifyUserInsights[0].Statements.map((element: any) => {
        return { ...element, active: true };
      })
      : [],
  );

  useEffect(() => {
    if (checkPaddington && props.modifyUser) {
      // eslint-disable-next-line array-callback-return
      const full = checkPaddington?.Access?.filter(e => e?.Level?.includes("FULL"))[0]?.Clients?.map((el: any) => {
        // if (props.modifyUser.HasFullAccess.some((modifyEl: any) => modifyEl.ClientCode === el.ClientCode))
        //   return { ...el, AccessType: 'FULL' };
        // else if (props.modifyUser.HasNoAccess.some((modifyEl: any) => modifyEl.ClientCode === el.ClientCode))
        //   return { ...el, AccessType: 'NONE' };
        // else if (props.modifyUser.HasReadAccess.some((modifyEl: any) => modifyEl.ClientCode === el.ClientCode))
        //   return { ...el, AccessType: 'READ' };
        const fullAccess = props.modifyUser.Access?.filter(al => al?.Level?.includes("FULL"))[0]
        const readAccess = props.modifyUser.Access?.filter(al => al?.Level?.includes("READ"))[0]
        const noAccess = props.modifyUser.Access?.filter(al => al?.Level?.includes("NO"))[0]
        if (fullAccess?.Clients?.some((modifyEl: any) => modifyEl.ClientCode === el.ClientCode))
          return { ...el, AccessType: 'FULL' };
        else if (readAccess?.Clients?.some((modifyEl: any) => modifyEl.ClientCode === el.ClientCode))
          return { ...el, AccessType: 'READ' };
        else if (noAccess?.Clients?.some((modifyEl: any) => modifyEl.ClientCode === el.ClientCode))
          return { ...el, AccessType: 'NONE' };
        else return { ...el, AccessType: 'NONE' };
        // accessLevels?.forEach(aL => {
        //   if (aL?.Clients?.some(client => client?.ClientCode === el.ClientCode)) {
        //     if (aL?.Level === 'FULL_ACCESS') {
        //       return { ...el, AccessType: 'FULL' };
        //     } else if (aL?.Level === 'READ_ACCESS') {
        //       return { ...el, AccessType: 'READ' };
        //     } else if (aL?.Level === 'NO_ACCESS') {
        //       return { ...el, AccessType: 'NONE' };
        //     }
        //   }
        // })
      });
      setFullList(full);


      //-------------------READ LIST-----------//
      // eslint-disable-next-line array-callback-return
      const read = checkPaddington?.Access?.filter(e => e?.Level?.includes("READ"))[0]?.Clients?.flatMap((el: any) => {
        // if (props.modifyUser.HasNoAccess.some((modifyEl: any) => modifyEl.ClientCode === el.ClientCode))
        //   return [{ ...el, AccessType: 'NONE' }];
        // else if (props.modifyUser.HasReadAccess.some((modifyEl: any) => modifyEl.ClientCode === el.ClientCode))
        //   return [{ ...el, AccessType: 'READ' }];
        // else
        //   return [];

        const readAccess = props.modifyUser.Access?.filter(al => al?.Level?.includes("READ"))[0]
        const noAccess = props.modifyUser.Access?.filter(al => al?.Level?.includes("NO"))[0]

        if (readAccess?.Clients?.some((modifyEl: any) => modifyEl.ClientCode === el.ClientCode))
          return { ...el, AccessType: 'READ' };
        else if (noAccess?.Clients?.some((modifyEl: any) => modifyEl.ClientCode === el.ClientCode))
          return { ...el, AccessType: 'NONE' };
        else return { ...el, AccessType: 'NONE' };


        // const accessLevels = props.modifyUser.Access
        // accessLevels?.forEach(aL => {
        //   if (aL?.Clients?.some(client => client?.ClientCode === el.ClientCode)) {
        //     if (aL?.Level === 'READ_ACCESS') {
        //       return { ...el, AccessType: 'READ' };
        //     } else if (aL?.Level === 'NO_ACCESS') {
        //       return { ...el, AccessType: 'NONE' };
        //     }
        //   }
        // })

      });
      setReadList(read);
    }
  }, [checkPaddington, props.modifyUser]);

  useEffect(() => {
    if (props.modifyUser !== undefined) {
      setFirstName(props.modifyUser.Name);
      setLastName(props.modifyUser.Family_name);
      setMail(props.modifyUser.Email);
    } else if (props.modifyUser === undefined) {
      setFirstName(undefined);
      setLastName(undefined);
      setMail(undefined);
    }
  }, [props.modifyUser]);

  async function handleSaveButton() {
    const agencies = ['@annalect', '@phd', '@omd', '@hearts-science', '@omnicommediagroup'];

    // Check if any agency is included in the mail for EXTERNAL users
    const isExternalInvalid = props.userType === "EXTERNAL" && agencies.some(agency => mail.includes(agency));

    // Check if all agencies are not included in the mail for INTERNAL users
    const isInternalInvalid = props.userType === "INTERNAL" && agencies.every(agency => !mail.includes(agency));

    if (isExternalInvalid || isInternalInvalid) {
      alert('You cannot add this user. Please check the email address.');
      return;
    }

    setLoaderState(false);
    props.setExternalUsers(null);
    props.setInternalUsers(null);
    //  props.setSeeModal(!props.seeModal);



    if (props.modifyUser !== undefined) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await API.graphql({
          query: updateUserAccess,
          variables: {
            Environment: getEnvironment,
            Username: props.modifyUser.Username,
            Clients: [...fullList, ...readList],
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });

        // CALL ADD WITH EXISTING USER
        const updatedAddList = addList.map((element: any) => {
          return { ...element, Username: props.modifyUser.Username };
        });
        updatedAddList.forEach((el: any) => addInsights(el));
        // // CALL REMOVE WITH EXISTING USER
        const updatedRemoveList = removeList.map((element: any) => {
          return { ...element, Username: props.modifyUser.Username };
        });
        updatedRemoveList.forEach((el: any) => removeInsights(el));
        props.setModifyUser(undefined);
        props.setModifyUserInsights([]);
        props.setSeeModal(!props.seeModal);
        props.callBothUsers();
      } catch (e) {
        console.error('Error update user access', e);
      }
    } else {
      if (props.userType === 'INTERNAL') {
        addNewInternalUser();
      } else if (props.userType === 'EXTERNAL') {
        addNewExternalUser();
      }
    }
  }

  async function addNewInternalUser() {
    try {
      const response = (await API.graphql({
        query: addInternalUser,
        variables: {
          Email: mail,
          Clients: [...fullList, ...readList],
          Environment: getEnvironment,
          Dashboards: selectedDashboards,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: AddInternalUserMutation };

      props.callBothUsers();
      // GET RESPONSE AND CALL ADD DASHBOARDS
      const updatedAddList = addList.map((element: any) => {
        return { ...element, Username: response.data.addInternalUser?.username };
      });

      updatedAddList.forEach((el: any) => addInsights(el));
      // GET RESPONSE AND CALL  REMOVE DASHBOARDS
      const updatedRemoveList = removeList.map((element: any) => {
        return { ...element, Username: response.data.addInternalUser?.username };
      });
      updatedRemoveList.forEach((el: any) => removeInsights(el));
      props.setSeeModal(!props.seeModal);
      props.callBothUsers();
      props.setSeeModal(!props.seeModal);
    } catch (e) {
      console.log('ERROR IN ADDING USER', e);
      alert('Something went wrong, please try again');
    }
  }

  async function addNewExternalUser() {
    try {
      const response = (await API.graphql({
        query: addExternalUser,
        variables: {
          Email: mail,
          Name: firstName,
          Family_name: lastName,
          Organization_id: activeOrganization?.OrganizationId,
          Clients: [...fullList, ...readList],
          Environment: getEnvironment, // Environment.DEMO
          Agency: `${REACT_APP_AGENCY?.toUpperCase()}`,
          WelcomeUrl: `https://nordics.${REACT_APP_AGENCY_URL}.com`,
          Dashboards: selectedDashboards,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: AddExternalUserMutation };
      props.setSeeModal(!props.seeModal);
      props.callBothUsers();
      // GET RESPONSE AND CALL ADD DASHBOARDS
      const updatedAddList = addList.map((element: any) => {
        return { ...element, Username: response.data.addExternalUser?.username };
      });
      updatedAddList.forEach((el: any) => addInsights(el));
      // GET RESPONSE AND CALL  REMOVE DASHBOARDS
      const updatedRemoveList = removeList.map((element: any) => {
        return { ...element, Username: response.data.addExternalUser?.username };
      });
      updatedRemoveList.forEach((el: any) => removeInsights(el));
      props.callBothUsers();
      props.setSeeModal(!props.seeModal);
    } catch (e) {
      console.log('ERROR IN ADDING USER', e);
      alert('Something went wrong, please try again');
    }
  }

  // HANDLING CLIENT CODES TO DISPLAY (to be finished)
  const handleCodes = (e: any, event: any) => {
    setFullList((ps: any) =>
      ps.map((obj: any) => {
        if (obj.ClientCode === e.ClientCode) {
          return { ...obj, AccessType: event.target.value };
        }
        return obj;
      }),
    );

    setReadList((ps: any) =>
      ps.map((obj: any) => {
        if (obj.ClientCode === e.ClientCode) {
          return { ...obj, AccessType: event.target.value };
        }
        return obj;
      }),
    );
  };

  const [addList, setAddList] = useState<Array<any>>([]);
  const [removeList, setRemoveList] = useState<Array<any>>([]);
  const handleDashoboards = (e: any, dashName: string, CCobject: any) => {
    if (e.target.checked) {
      const found = userInsights.findIndex(
        (el: any) => el.Resource === dashName && el.ClientCode === CCobject.ClientCode,
      );
      if (found === -1) {
        setUserInsights((ps: any) => [
          ...ps,
          {
            Action: ['READ'],
            ClientCode: CCobject.ClientCode,
            Service: 'Dashboards',
            Resource: dashName,
            active: true,
          },
        ]);
      } else {
        const nextList = [...userInsights];
        const singleObject = nextList.find(
          (el: any) => el.Resource === dashName && el.ClientCode === CCobject.ClientCode,
        );
        singleObject.active = true;
        setUserInsights(nextList);
      }
      setAddList((ps: any) => [
        ...ps,
        {
          Clients: {
            ClientCode: CCobject.ClientCode,
            ClientCountry: CCobject.ClientCountry,
          },
          Resource: dashName,
          Environment: getEnvironment,
          //  Username: props.modifyUser.Username,
        },
      ]);
      setRemoveList((ps: any) => [
        ...ps.filter((el: any) => el.Resource !== dashName && el.ClientCode === CCobject.ClientCode),
      ]);
    } else {
      const nextList = [...userInsights];
      const singleObject = nextList.find(
        (el: any) => el.Resource === dashName && el.ClientCode === CCobject.ClientCode,
      );
      singleObject.active = false;
      setUserInsights(nextList);

      setRemoveList((ps: any) => [
        ...ps,
        {
          Clients: {
            ClientCode: CCobject.ClientCode,
            ClientCountry: CCobject.ClientCountry,
          },
          Resource: dashName,
          Environment: getEnvironment,
        },
      ]);
      setAddList((ps: any) => [
        ...ps.filter((el: any) => el.Resource !== dashName && el.ClientCode === CCobject.ClientCode),
      ]);
    }
  };

  const closeModal = () => {
    props.setModifyUser(undefined);
    props.setSeeModal(!props.seeModal);
  };

  async function addInsights(varData: any) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = (await API.graphql({
        query: addDashboardAccess,
        variables: varData,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: AddDashboardAccessMutation };

    } catch (e) {
      console.log('Error add insights access', e);
    }
  }

  async function removeInsights(varData: any) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = (await API.graphql({
        query: removeDashboardAccess,
        variables: varData,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: RemoveDashboardAccessMutation };

    } catch (e) {
      console.log('Error add insights access', e);
    }
  }
  const [loaderState, setLoaderState] = useState(true);
  const themes = useTheme()


  const isItReadPlus = (code: string) => {
    const getLevel = props.typeOfReadAccess.find(e => e.ClientCode === code)
    if (getLevel?.Level === 'READ_PLUS_ACCESS') return '#DAA520'
    else return '#FFEE00'
  }

  // console.log("LISTE", fullList, readList)
  return (
    <Modal onClick={() => closeModal()}>
      {loaderState ? (
        <HeadModul onClick={e => e.stopPropagation()} >


          <button type='button' className="x" onClick={() => closeModal()}> <IoMdClose className='closeIcon' /></button>

          <ModulContainer onClick={e => e.stopPropagation()}
          >
            <h1>Add User</h1>
            <div className="form">
              <div className="namesForm">
                <div className="inputFieldName" style={{ float: 'left' }}>
                  <label htmlFor="">
                    First Name
                    <input defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" />
                  </label>
                </div>
                <div className="inputFieldLastName" style={{ float: 'left', alignSelf: 'flex-end' }}>
                  <label htmlFor="">
                    Last Name
                    <input defaultValue={lastName} onChange={(e) => setLastName(e.target.value)} type="text" />
                  </label>
                </div>
              </div>
              <div className="inputFieldLastMail" style={{ float: 'left' }}>
                <label htmlFor="">
                  Email
                  <input
                    defaultValue={mail}
                    /* style={{ width: '100%' }} */ onChange={(e) => setMail(e.target.value)}
                    type="text"
                  />
                </label>
              </div>
            </div>
            <div className="selectorsForm">
              <div className="toggler">
                <h3 style={{ width: '200px' }}>Select access type on client code:</h3>
                <div className="onoff">
                  {fullList.map((e: any, i: any) => (
                    <section key={e.ClientCode}>
                      <legend>{e.ClientCode ?? ""}</legend>
                      <div style={{ display: 'flex' }}>
                        <input
                          type="radio"
                          id={i + 'full'}
                          name={e.ClientCode}
                          value="FULL"
                          onChange={(event) => handleCodes(e, event)}
                        />
                        <label
                          style={{ color: e.AccessType === 'FULL' ? '#20C933' : '' }}
                          htmlFor={i + 'full'}
                          className="midChild"
                        >
                          Full access{' '}
                        </label>

                        <input
                          type="radio"
                          id={i + 'read'}
                          name={e.ClientCode}
                          value="READ"
                          onChange={(event) => handleCodes(e, event)}
                        />
                        <label
                          style={{ color: e.AccessType === 'READ' ? isItReadPlus(e.ClientCode) : '' }}
                          htmlFor={i + 'read'}
                          className="midChild"
                        >
                          Read access
                        </label>

                        <input
                          type="radio"
                          id={i + 'no'}
                          name={e.ClientCode}
                          value="NONE"
                          onChange={(event) => handleCodes(e, event)}
                        />
                        <label style={{ color: e.AccessType === 'NONE' ? '#FF0021' : '' }} htmlFor={i + 'no'}>
                          No access
                        </label>
                      </div>
                    </section>
                  ))}

                  {readList.map((e: any, i: any) => (
                    <section key={e.ClientCode}>
                      <legend>{e.ClientCode}</legend>
                      <div style={{ display: 'flex' }}>
                        <input
                          type="radio"
                          id={i + 'read'}
                          name={e.ClientCode}
                          value="READ"
                          onChange={(event) => handleCodes(e, event)}
                        />
                        <label
                          style={{ color: e.AccessType === 'READ' ? '#FFEE00' : 'initial' }}
                          htmlFor={i + 'read'}
                          className="midChild"
                        >
                          Read access
                        </label>

                        <input
                          type="radio"
                          id={i + 'no'}
                          name={e.ClientCode}
                          value="NONE"
                          onChange={(event) => handleCodes(e, event)}
                        />
                        <label style={{ color: e.AccessType === 'NONE' ? '#FF0021' : 'initial' }} htmlFor={i + 'no'}>
                          No access
                        </label>
                      </div>
                    </section>
                  ))}
                </div>
              </div>

              {/* <div className="toggler">
                <h3 style={{ width: '200px' }}>Select dashboard access:</h3>
                <div>
                  {insightsDashboards?.map((CCobject: any, i: any) => (
                    <section key={i} className="dashboards">
                      <legend className="header">{CCobject.ClientCode}</legend>
                      <div className="dashboardGrid">
                        {CCobject.Resources.map((element: any) => (
                          <div key={element.Dashboard}>
                            <input
                              type="checkbox"
                              name="checkbox"
                              id={element.Dashboard + CCobject.ClientCode}
                              value={element.Dashboard}
                              disabled={element.Access ? false : true}
                              onChange={(e) =>
                                handleDashoboards(e, element.Dashboard, CCobject)
                              } 
                              checked={
                                userInsights.some(
                                  (el: any) =>
                                    el.Resource === element.Dashboard &&
                                    el.ClientCode === CCobject.ClientCode &&
                                    el.active,
                                )
                                  ? true
                                  : false
                              }
                            />
                            <label
                              style={{
                                color: userInsights.some(
                                  (el: any) =>
                                    el.Resource === element.Dashboard &&
                                    el.ClientCode === CCobject.ClientCode &&
                                    el.active,
                                )
                                  ? '#5CDB5C'
                                  : '',
                                backgroundColor: element.Access ? '' : '#e7212190',
                              }}
                              className="checked"
                              htmlFor={element.Dashboard + CCobject.ClientCode}
                            >
                              {element.Dashboard}
                            </label>
                          </div>
                        ))}
                      </div>
                    </section>
                  ))}
                </div>
              </div> */}
            </div>


          </ModulContainer>

          <DarkButton
            style={{ alignSelf: "flex-end" }} type="button"
            onClick={handleSaveButton}
          >
            SAVE
          </DarkButton>
        </HeadModul>)



        : (

          <div className="loaderDiv">
            <ReactLoading type="spinningBubbles" color={themes.publishButton}></ReactLoading>
          </div>

        )}

    </Modal>
  );
};

export default UserAdminModal2;
