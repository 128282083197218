import { useContext } from 'react';
import styled from 'styled-components';
import { Clients, RemoveUserMutation, getUserTypeV2 } from 'API';
import { removeUser } from 'graphql/mutations';
import { API } from 'aws-amplify';
import { UserData } from 'types/userAdminTypes';
import APIContext from 'context/APIContext';
import { VscEdit } from 'react-icons/vsc';
import { AiOutlineDelete } from 'react-icons/ai';

const Card = styled.div`
  height: 24.47vh;
  background-color: ${({ theme }) => theme.placeholderBackground};
  border-radius: 7px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3.6vh;
  h2 {
    font-weight: normal;
  }
  h3 {
    font-weight: normal;
    font-size: 12px;
    font-family: 'FontMedium', sans-serif;
    color: ${({ theme }) => theme.primaryGray}; 
  }
  .details {
    position: absolute;
    top: 2.38vh;
    left: 2.34vw;
    h1{
      color: ${({ theme }) => theme.primaryGray};
      font-weight: normal;
    }
  }
  .action {
    position: absolute;
    bottom: 3.38vh;
    left: 2.34vw;
    display: flex;
    flex-direction: column;
   // gap: 0.6vh;
  }
  .onoff {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    width: 26vw;
    overflow-x: auto;
    /* width */
    ::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.border};
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.scrollThumb};
      border-radius: 10px;
    }
    
    button {
      all: initial;
      font-family: 'FontMedium', sans-serif;

      font-size: .8vw;
      margin-bottom: 0.6vh;
      //    color: red;
      line-height: .8;
    }
  }

  h4 {
    font-weight: 800;
    font-size: 1vw;
    color:#20C933;
  }
  .editing {
    position: absolute;
    right: 2.34vw;
    bottom: 2.38vh;
    display: flex;
    gap: 5px;
    color: ${({ theme }) => theme.primaryGray};
  }
  .premium {
    position: absolute;
    background: rgb(218,165,32);
    background: linear-gradient(149deg, rgba(218,165,32,1) 0%, rgba(255,255,255,1) 24%);
    /* border-radius: 7px 50px 50% 200px; */
    width: 100%;
    height:100%;
    top: 10px;
    left:0;
    filter: blur(10px);
  }
`;

type Props = {
  data: getUserTypeV2;
  typeOfReadAccess: any[];
  userType: any;
  callFetchUsers(): any;
  modalModify(user: any, usrTyp: any, insightsData: any): any;
  setExternalUsers(props: any): any;
  setInternalUsers(props: any): any;
  setUserType(props: any): any;
  insightsData: any

};


const UserAdminCard2 = (props: Props) => {
  const { getEnvironment } = useContext(APIContext);



  // HANDLE REMOVE USER ENTIRELY
  const handleDeleteUser = () => {
    const fullAccess = props.data.Access?.filter(e => e?.Level === 'FULL_ACCESS').map(e => e?.Clients).flat();
    const readAccess = props.data.Access?.filter(e => e?.Level === 'READ_ACCESS').map(e => e?.Clients).flat();
    const joined = fullAccess?.concat(readAccess)
    const removedTypename = joined?.map(e => {
      const { __typename, ...rest } = e || {};
      return rest;
    });
    const vars = {
      Username: props.data.Username,
      //    UserType: UserType[props.userType as keyof typeof UserType],
      // Clients: fullAccess?.concat(readAccess),
      Clients: removedTypename,
      Environment: getEnvironment, // Environment.DEMO,
    };
    removeTheUser(vars);
  };

  // MUTATION REMOVE USER
  async function removeTheUser(variables: any) {


    props.setExternalUsers(undefined)
    props.setInternalUsers(undefined)

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = (await API.graphql({
        query: removeUser,
        variables: variables,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: RemoveUserMutation };
      props.callFetchUsers();

    } catch (e) {
      console.error('ERROR IN REMOVING USER', e);
    }
  }

  const isItReadPlus = (code: string) => {
    const getLevel = props.typeOfReadAccess.find(e => e.ClientCode === code)
    if (getLevel?.Level === 'READ_PLUS_ACCESS') return '#DAA520'
    else return '#FFEE00'
  }





  return (
    <Card >
      {/* <div className='premium'>   </div> */}
      <div className="details">
        <h1>{props.data.Name + ' ' + props.data.Family_name} </h1>
        <h2>{props.data.Email}</h2>
      </div>
      <div className="action">
        {props.data.Access?.map(e =>
          <div key={e?.Level}>
            <h3>{e?.Level}</h3>
            <div className='onoff'>
              {e?.Clients?.map(client =>
                <button
                  value={client?.ClientCode as string}
                  type="button"
                  // onClick={(event) => handleToggleClientCode(event)}
                  key={client?.ClientCode}
                  style={{ color: e?.Level === 'FULL_ACCESS' ? '#20C933' : e?.Level === 'READ_ACCESS' ? isItReadPlus(client?.ClientCode as string) : '#FF0021' }}
                >
                  {client?.ClientCode}
                </button>
              )}
            </div>

          </div>

        )}

      </div>
      <div className="editing">
        <VscEdit style={{ cursor: 'pointer' }} onClick={() => { props.modalModify(props.data, props.userType, props.insightsData);/*  props.setUserType(props.userType) */ }} />
        <AiOutlineDelete style={{ cursor: 'pointer' }} onClick={handleDeleteUser} />
      </div>

    </Card>
  );
};

export default UserAdminCard2;
