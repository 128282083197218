import { LogActivityMutation } from 'API';
import { API, Auth, Hub } from 'aws-amplify';
import { logActivity } from 'graphql/mutations';
import { get } from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';
import APIContext from './APIContext';
import { useLoggingActivity } from 'customHooks/useLogging';


interface ICognitoUser {
  attributes: any;
  username: any;
  signInUserSession: {
    idToken: {
      payload: {
        email: any,
        family_name: any,
        name: any
      }
    }
  }
}
export type NullableUser = ICognitoUser | null;
const AuthContext = createContext<{
  isAuthorized: boolean | null;
  user: NullableUser;
}>({
  isAuthorized: false,
  user: null,
});

export const AuthContextProvider = (props: any) => {
  const [isAuthorized, setIsAuthorized] = useState(null as boolean | null);
  const [user, setUser] = useState<NullableUser>(null);
  const { getEnvironment } = useContext(APIContext);
  const [logStatus, logLoginActivity] = useLoggingActivity();




  const [ioAddress, setIpAddress] = useState<string>("");
  // console.log(ioAddress);







  const logActivityMutation = async (userInfo: ICognitoUser) => {
    // const ajpi = await fetch('https://api.ipify.org?format=json')
    //   .then(response => response.json())
    //   .then(data => {
    //     // console.log(data.ip);
    //     setIpAddress(data.ip);
    //   })
    //   .catch(error => {
    //     console.log('Error:', error);
    //   });
    try {
      const mutation = (await API.graphql({
        query: logActivity,
        variables: {
          Username: userInfo.username,
          Action: "LOGIN",
          Environment: getEnvironment,
          Service: "OMG PORTAL",
          Url: window.location.href,
          // Ip: ajpi,
        },
        authMode: 'AWS_IAM',
      })) as { data: LogActivityMutation };
      console.log(mutation);





      setUser(userInfo)
      setIsAuthorized(true)



      return true

    } catch (e) {
      console.error("Error logging activity", e)
    }

  }




  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        // case "":
        // case 'signIn':
        case 'cognitoHostedUI':
          getUser().then((userData) => {/*  setUser(userData);  */logActivityMutation(userData) });
          break;
        case 'signOut':
          setUser(null);

          break;
        case "customOAuthState":
          if (data) {
            setTimeout(() => {
              window.location.href = data;
            }, 2000);
          }
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          setIsAuthorized(false);
          break;
      }
    });
  }, []);
  const getUser = () => {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => {
        setIsAuthorized(false)

      });
  };
  useEffect(() => {
    getUser().then((userData) => setUser(userData));
  }, []);

  useEffect(() => {
    if (user) {
      setIsAuthorized(true);
    }
  }, [user]);



  return (
    <AuthContext.Provider
      value={{
        isAuthorized,
        user,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );


};
export default AuthContext;
