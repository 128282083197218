import { useCampaignContext } from 'context/CampaignContext';
import NoCampaignSelected from '../NoCampaignSelected';
import { AudienceReport, Graphs, Kpis, MainContainer, TxtContainer } from 'components/Main/Main.styles';
import { OOHPRINT, useDataQuery } from 'customHooks/useDataQuery';
import { useContext, useEffect, useState } from 'react';
import {
  datatype,
  filterDetail,
  filtertype,
  metric,
  metricFunction,
  queryInput,
  localQuery,
  countryEnum,
  agencyEnum,
} from 'API';
import { PublishContext } from 'context/PublishContext';
import { useClientContext } from 'context/ClientContext';
import AuthContext from 'context/AuthContext';
import { getEndDate, getStartDate } from 'methods/dateMethods';
import { KPIholder } from '../TV/TV.styles';
import ReactLoading from 'react-loading';
import { KPItoS3 } from 'components/KPItoS3';
import { useTheme } from 'styled-components';
import { useTemplateContext } from 'context/TemplateContext';
import { useGetStoredQueriesDetails } from 'customHooks/useGetStoredQueriesDetails';
import PlaceholderContainer from '../PlaceholderContainer';
import { useDataSources } from 'customHooks/useDataSources';
import ClientPlaceholderContainer from '../CLIENTCOMPONENTS/ClientPlaceholderContainer';
import ARContainer from 'components/Main/Gallery/ARContainer';
import KPIComponent from '../KPIComponent';
import InputContext from 'context/InputContext';

const CinemaMain = () => {
  const { activeCampaign, campaignList } = useCampaignContext();
  const { activeOrganization, organizationList } = useClientContext();
  const { updateTemplatePlaceholderList, fetchedPlaceholders } = useTemplateContext();
  const { setCapture } = useContext(InputContext)
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const newPlaceholder = undefined;

  const [query, setQuery] = useState<queryInput>();
  const { updateOLArray, publishedData } = useContext(PublishContext);

  const [dataSources] = useDataSources(activeCampaign);
  const [storedQueries, , , , , , publishedQueries] = useGetStoredQueriesDetails(
    activeCampaign,
    newPlaceholder,
    fetchedPlaceholders,
    publishedData,
  );

  const { REACT_APP_AGENCY } = process.env;

  const publishedKPI = publishedData?.find((element: any) => element.position === 'CINEMA0');
  const publishedOutputLocation = publishedKPI?.outputlocation;
  // const [data, queryStatus, error, queryId, outputLocation] = useDataQuery({ query: query, querySource: 'CINEMA KPI', publishedOutputLocation: publishedOutputLocation })
  const [data, queryStatus, error, queryId, outputLocation] = useDataQuery({
    query: query,
    querySource: 'CINEMA KPI',
    publishedOutputLocation: publishedOutputLocation,
  });

  const Organization = activeOrganization?.Organization;
  const username = user?.username;
  const ClientCountry = activeCampaign?.ClientCountry;
  const isDemo = Organization === 'DEMO-Organization';

  useEffect(() => {
    if (activeCampaign && Object.keys(activeCampaign).length > 0 && activeCampaign?.authorized === true) {
      //METRICS DATA
      const metrics: metric[] = [
        {
          column: 'ctc',
          name: 'ctc',
          aggregation: metricFunction.SUM,
          id: '1',
        },
        {
          column: 'date',
          name: 'Startdate',
          aggregation: metricFunction.MIN,
          decimals: -1,
          id: '2',
        },
        {
          column: 'date',
          name: 'Enddate',
          aggregation: metricFunction.MAX,
          decimals: -1,
          id: '3',
        },
      ];

      // FILTER(CAMPAIGN DATA)
      const filter: filterDetail = {
        column: 'campaign_id',
        filter: {
          filtertype: filtertype.EQUAL,
          datatype: datatype.NUMBER,
          value: activeCampaign?.campaignId?.toString(),
        },
      };

      const localQuery: localQuery = {
        filters: [filter],
        metrics: metrics,
        // dimensions: ['campaign_id'],
        detailedDimensions: [
          {
            column: 'campaign_id',
            name: 'campaign_id',
            hidden: false,
            id: '4',
          },
        ],
        orderBy: ['1', '2', '3', '4'],
        preview: 100,
      };

      const newQuery: queryInput = {
        query: localQuery,
        views: [
          {
            viewName: Organization === 'DEMO-Organization' ? 'fozzie_cinema_demo' : `fozzie_cinema_${REACT_APP_AGENCY}`,
            database:
              activeCampaign?.ClientCountry === 'DK'
                ? 'data_lake_denmark'
                : activeCampaign?.ClientCountry === 'SE'
                  ? 'data_lake_sweden'
                  : activeCampaign?.ClientCountry === 'FI'
                    ? 'data_lake_finland'
                    : 'data_lake_norway',
          },
        ],
        jobnumber: 'ANNA0027',
        clients: [
          {
            country: countryEnum[activeCampaign?.ClientCountry as keyof typeof countryEnum],
            client: activeCampaign?.ClientCode as string,
          },
        ],
        date: { from: getStartDate(), to: getEndDate() },
        user: {
          name: username,
          agency: agencyEnum[`${REACT_APP_AGENCY?.toUpperCase()}` as keyof typeof agencyEnum],
          country: countryEnum.DK,
        },
      };
      setQuery(newQuery);
    }
  }, [activeCampaign, REACT_APP_AGENCY, isDemo, ClientCountry, username, Organization]);

  useEffect(() => {

    if (outputLocation) {
      const placeholdersObject = {
        position: 'Cinema0',
        outputlocation: outputLocation,
      };



      updateOLArray(placeholdersObject);
    }
  }, [outputLocation, updateOLArray])


  useEffect(() => {
    if (queryId) {
      const a = {
        Placeholder: 'Cinema0',
        Location: queryId,
        Placeholder_titel: '',
      };
      updateTemplatePlaceholderList(a);
    }
  }, [queryId]);


  const jsonDataObject = data as OOHPRINT;
  const ctc = jsonDataObject ? jsonDataObject.ctc : NaN;


  const componentIDs = [
    ['Cinema3.1', 'Cinema3.2', 'Cinema3.3', 'Cinema3.4'],
    ['Cinema3.5', 'Cinema3.6', 'Cinema3.7', 'Cinema3.8'],
  ];

  const kpiData = [
    { title: "Budget", subtitle: "Realized", ctc: ctc, componentID: "CinemaKPI1" },
    { title: "Reach", subtitle: "Realized", location: "Cinema", componentID: "CinemaKPI2" },
    { title: "Persons", subtitle: "Total Number", location: "Cinema", componentID: "CinemaKPI3" },
    { title: "Frequency", subtitle: "Average", location: "Cinema", componentID: "CinemaKPI4" },
  ];


  return (
    <div>
      {activeCampaign?.authorized && <button className='PDFbutton' onClick={() => setCapture(true)}>Download PPT</button>}
      {activeCampaign ? (
        <MainContainer key={activeCampaign?.campaign}>
          {error !== undefined ? <h1>Something went wrong, please reload the page and try again</h1> : null}
          {queryStatus === 'FAILED' ? (
            <Kpis>
              <KPIholder style={{ padding: '1vw' }}>
                <p>Something went wrong, please reload the page and try again </p>
              </KPIholder>
            </Kpis>
          ) : (
            <Kpis>
              {kpiData.map((kpi, index) => (
                <KPIComponent
                  key={index}
                  queryStatus={queryStatus}
                  title={kpi.title}
                  subtitle={kpi.subtitle}
                  location={kpi.location}
                  componentID={kpi.componentID}
                  ctc={kpi.ctc}
                  theme={theme}
                />
              ))}
            </Kpis>
          )}
          {activeCampaign?.authorized ? (
            <Graphs dashboard={'CINEMA'} columnsNumber={1}>
              {storedQueries
                .filter((el) => el?.position?.startsWith('Cinema1'))
                .sort((a, b) => (Number(a.position) > Number(b.position) ? 1 : -1))
                .map((e) => (
                  <PlaceholderContainer
                    componentID={e.position as string}
                    placeholderId={e.position as string}
                    dataSources={dataSources}
                    storedQueryDetails={e}
                    isGraph={true}
                  ></PlaceholderContainer>
                ))}
            </Graphs>
          ) : (
            <Graphs key={publishedQueries[0]?.outputlocation ?? ''}
              dashboard={'CINEMA'} columnsNumber={1}>
              {publishedQueries
                .filter((el) => el?.position?.startsWith('Cinema1'))
                .sort((a, b) => (Number(a.position) > Number(b.position) ? 1 : -1))
                .map((e) => (
                  <ClientPlaceholderContainer
                    key={e.position}
                    publishedQueriesDetails={e}
                    componentID={e.position as string}
                    isGraph={true}
                  ></ClientPlaceholderContainer>
                ))}
            </Graphs>
          )}
          {componentIDs.map((ids, index) => (
            <AudienceReport key={index}>
              {ids.map((id) => (
                <ARContainer key={id} componentID={id} />
              ))}
            </AudienceReport>
          ))}
        </MainContainer>
      ) : (
        <NoCampaignSelected page={"Cinema"}></NoCampaignSelected>
      )}
    </div>
  );
};

export default CinemaMain;
