import React from 'react';

type PreviewProps = {
  value: string;
  column: any;
  typeColumn: string;
  itIsGrouped?: boolean;
  grouping?: any;
  row?: any;
  cell?: any;
  CommunicationTaskKey?: string;
};

const PreviewContainer: React.FC<PreviewProps> = (props) => {
  const { column, typeColumn, CommunicationTaskKey } = props;
  const widthColumn = column?.name === 'Comments' || column?.name === 'Reasons for Touchpoint' ? 280 : 75;
  let { value } = props;



  if (typeColumn === 'DATE') {
    value = value.split('T')[0];
    const [year, month, day] = value.split('-');
    value = `${day}-${month}-${year}`;
  }

  return (
      <>
        {column.accessorKey !== CommunicationTaskKey ? (
          <div style={{
            // width: widthColumn,
            height: "auto",
            marginLeft: '5px'
          }}>
            {(value === column.header ? '' : value).split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {isNaN(Number(line)) ? (
                  <span style={{
                    width: '100%',
                    display: 'block',
                    whiteSpace: 'pre-wrap'
                  }}>{line}</span>
                ) : Number(line) !== 0 ? (
                  <span>{Number(line).toLocaleString()}</span>
                ) : null}

                {index !== (value === column.header ? '' : value).split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div></div>
        )}
      </>
  );
};

export default PreviewContainer;
