import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from "highcharts/highcharts-3d";
import { useTheme } from 'styled-components';


Highcharts.SVGRenderer.prototype.symbols['c-rect'] = function (x: any, y: any, w: any, h: any) {
    return ['M', x, y + h / 2, 'L', x + w, y + h / 2];
};

highcharts3d(Highcharts);
type datatype = {
    media_source: string,
    actual_cost: number
}
type Props = {
    data: datatype[];
    title: string
};

const colorArray = ["#7D98AB", "#DBBC8E", "#A9C8C0", "#C6ADAE", "#698296", "#A3B6C4", "#E5D0AF", "#C2D8D3", "#95A7B5", "#AE8A8C"]


function SummaryPerMediaStacked(props: Props) {
    const theData = [] as any;
    const theme = useTheme()
    const lookupPrimary = ["TV", "RADIO", "OOH", "PRINT", "DIGITAL", "CINEMA", "PRINT", "OOH"]
    const lookupSecondary = ["BVOD", "DAB"]


    const cat = props.data.map(e => {
        if (e.media_source === "TV" || e.media_source === "BVOD") {
            return "VIDEO"
        } else if (e.media_source === "RADIO" || e.media_source === "DAB") {
            return "RADIO"
        } else {
            return e.media_source
        }
    })
    const categories = [...new Set(cat)]

    const primaryElements = props.data.filter(e => lookupPrimary.includes(e.media_source))
    const secondaryElements = props.data.filter(e => lookupSecondary.includes(e.media_source))
    const primarySeries = categories.map(e => {
        const changedE = e === "VIDEO" ? "TV" : e === "AUDIO" ? "RADIO" : e
        const foundElement = primaryElements.find(el => el.media_source === changedE)
        if (foundElement) {
            return foundElement.actual_cost
        } else {
            return 0
        }
    }
    )
    const secondarySeries = categories.map(e => {
        const changedE = e === "VIDEO" ? "BVOD" : e === "AUDIO" ? "DAB" : e
        const foundElement = secondaryElements.find(el => el.media_source === changedE)
        if (foundElement) {
            return foundElement.actual_cost
        } else {
            return 0
        }
    }
    )



    const [options, setOptions] = useState<any>()


    const setOptionsObject = () => {
        return {
            chart: {
                backgroundColor: 'transparent',
                zoomType: 'xy',
                type: "column",
                style: {
                    fontFamily: "fontRegular"
                }
            },
            title: {
                text: props.title,
                align: 'left',
                style: {
                    color: theme.primaryGray,
                    fontFamily: "fontRegular",
                    fontSize: "14px"
                }
            },

            yAxis: {
                min: 0,
                labels: {
                    format: '{value}', // bilo je value
                    style: {
                        color: colorArray[theData.length],
                    },
                    enabled: false
                },
                title: {
                    text: "TRP",
                    style: {
                        color: colorArray[theData.length],
                    },
                    enabled: false,
                },
                stackLabels: {
                    enabled: true,
                    align: 'center',
                    formatter: function (this: any) {
                        return Intl.NumberFormat('en-DK', { notation: 'compact' }).format(Math.round(this.total))
                    },
                    style: {
                        color: theme.primaryGray,
                        textOutline: 'transparent'
                    }

                },
                gridLineWidth: 0,


            },
            xAxis: {
                categories: categories,
                crosshair: true,
                gridLineWidth: 0,
                labels: {
                    style: {
                        color: theme.primaryGray,
                    }
                },
            },
            shared: true,
            followPointer: true,
            tooltip: {
                useHTML: true,
                followPointer: true,
                shared: false,
                // Highcharts.TooltipFormatterContextObject
                formatter: function (this: any) {
                    let amount = this.y
                    let name = props.data.find(e => e.actual_cost === amount)?.media_source
                    return '<b>' + name + ' :</b><br>' + this.y

                },
            },

            plotOptions: {
                column: {
                    groupPadding: 0.1,
                    pointPadding: 0,
                    dataLabels: {
                        enabled: false,
                        crop: false,
                        overflow: 'none',
                        formatter: function (this: any) {
                            return Intl.NumberFormat('en-DK', { notation: 'compact' }).format(Math.round(this.y))
                        },
                        style: {
                            color: theme.primaryGray,
                            textOutline: 'transparent'
                        }
                    }
                },
                series: {
                    stacking: "normal"
                }


            },
            series: [
                {
                    name: "Primary",
                    data: primarySeries,
                    color: colorArray[0],
                    showInLegend: true
                },
                {
                    name: "Secondary",
                    data: secondarySeries,
                    color: colorArray[4],
                    showInLegend: true
                }
            ],
            credits: { enabled: false },
            legend: {
                enabled: false,
                labelFormatter: function (this: any) {
                    return this.name
                },
                itemStyle: {
                    color: theme.primaryGray,
                    fontWeight: "normal",
                    font: "fontMedium"
                }
            },

        }
    }





    useEffect(() => {
        setOptions(null)
        setTimeout(() => {
            setOptions(setOptionsObject())
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    return (
        <HighchartsReact containerProps={{ style: { height: "100%", width: "100%" } }} highcharts={Highcharts} options={options} ref={chartComponentRef} {...props} allowChartUpdate={true} />
    );
}

export default SummaryPerMediaStacked;