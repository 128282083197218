/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createComment = /* GraphQL */ `mutation CreateComment($comment: CreateCommentInput!) {
  createComment(comment: $comment) {
    ... on CommentWithStatus {
      statusCode
      comment {
        campaign
        sub_campaign
        placeholder
        media_source
        comment_type
        comment
        created_at
        last_edited_at
        created_by
        last_edited_by
        state
        __typename
      }
    }
    ... on Error {
      type
      message
      statusCode
    }
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentMutationVariables,
  APITypes.CreateCommentMutation
>;
export const editComment = /* GraphQL */ `mutation EditComment($comment: EditCommentInput!) {
  editComment(comment: $comment) {
    ... on CommentWithStatus {
      statusCode
      comment {
        campaign
        sub_campaign
        placeholder
        media_source
        comment_type
        comment
        created_at
        last_edited_at
        created_by
        last_edited_by
        state
        __typename
      }
    }
    ... on Error {
      type
      message
      statusCode
    }
  }
}
` as GeneratedMutation<
  APITypes.EditCommentMutationVariables,
  APITypes.EditCommentMutation
>;
export const removeComment = /* GraphQL */ `mutation RemoveComment($comment: RemoveCommentInput!) {
  removeComment(comment: $comment) {
    ... on CommentWithStatus {
      statusCode
      comment {
        campaign
        sub_campaign
        placeholder
        media_source
        comment_type
        comment
        created_at
        last_edited_at
        created_by
        last_edited_by
        state
        __typename
      }
    }
    ... on Error {
      type
      message
      statusCode
    }
  }
}
` as GeneratedMutation<
  APITypes.RemoveCommentMutationVariables,
  APITypes.RemoveCommentMutation
>;
export const startQuery = /* GraphQL */ `mutation StartQuery($query: queryInput!) {
  startQuery(query: $query) {
    id
    status
    submitted
    outputlocation
    bytes_scanned
    user_name
    source
    bucket
    query
    error
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartQueryMutationVariables,
  APITypes.StartQueryMutation
>;
export const setStatus = /* GraphQL */ `mutation SetStatus($id: String!, $status: statusEnum) {
  setStatus(id: $id, status: $status) {
    id
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetStatusMutationVariables,
  APITypes.SetStatusMutation
>;
export const storeQueries = /* GraphQL */ `mutation StoreQueries(
  $campaign_id: String!
  $position: String!
  $query_id: String!
  $query_id_cc: String
  $title: String
  $metrics: [MetricsInfo]
) {
  storeQueries(
    campaign_id: $campaign_id
    position: $position
    query_id: $query_id
    query_id_cc: $query_id_cc
    title: $title
    metrics: $metrics
  ) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StoreQueriesMutationVariables,
  APITypes.StoreQueriesMutation
>;
export const publishOutputLocation = /* GraphQL */ `mutation PublishOutputLocation(
  $publishInput: PublishObject!
  $Environment: Environment!
) {
  publishOutputLocation(publishInput: $publishInput, Environment: $Environment)
}
` as GeneratedMutation<
  APITypes.PublishOutputLocationMutationVariables,
  APITypes.PublishOutputLocationMutation
>;
export const publishOutputLocation_v2 = /* GraphQL */ `mutation PublishOutputLocation_v2(
  $publishInput: PublishObject!
  $Environment: Environment!
) {
  publishOutputLocation_v2(
    publishInput: $publishInput
    Environment: $Environment
  )
}
` as GeneratedMutation<
  APITypes.PublishOutputLocation_v2MutationVariables,
  APITypes.PublishOutputLocation_v2Mutation
>;
export const unPublish = /* GraphQL */ `mutation UnPublish($unPublishInput: UnPublishObject!) {
  unPublish(unPublishInput: $unPublishInput)
}
` as GeneratedMutation<
  APITypes.UnPublishMutationVariables,
  APITypes.UnPublishMutation
>;
export const unPublishCampaign = /* GraphQL */ `mutation UnPublishCampaign(
  $campaign_id: String!
  $dashboardType: DashboardTypes!
  $clientCode: String
  $clientCountry: String
  $subCampaignId: String
) {
  unPublishCampaign(
    campaign_id: $campaign_id
    dashboardType: $dashboardType
    clientCode: $clientCode
    clientCountry: $clientCountry
    subCampaignId: $subCampaignId
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UnPublishCampaignMutationVariables,
  APITypes.UnPublishCampaignMutation
>;
export const addExternalUser = /* GraphQL */ `mutation AddExternalUser(
  $Email: String!
  $Name: String!
  $Family_name: String!
  $Organization_id: String!
  $Clients: [addUserClientInput]!
  $Environment: Environment!
  $Agency: String
  $WelcomeUrl: String
  $Dashboards: [String]
) {
  addExternalUser(
    Email: $Email
    Name: $Name
    Family_name: $Family_name
    Organization_id: $Organization_id
    Clients: $Clients
    Environment: $Environment
    Agency: $Agency
    WelcomeUrl: $WelcomeUrl
    Dashboards: $Dashboards
  ) {
    status
    message
    username
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddExternalUserMutationVariables,
  APITypes.AddExternalUserMutation
>;
export const addInternalUser = /* GraphQL */ `mutation AddInternalUser(
  $Email: String!
  $Environment: Environment!
  $Clients: [addUserClientInput]!
  $Dashboards: [String]
) {
  addInternalUser(
    Email: $Email
    Environment: $Environment
    Clients: $Clients
    Dashboards: $Dashboards
  ) {
    status
    message
    username
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddInternalUserMutationVariables,
  APITypes.AddInternalUserMutation
>;
export const removeUser = /* GraphQL */ `mutation RemoveUser(
  $Username: String!
  $Clients: [ClientsInput]!
  $Environment: Environment!
) {
  removeUser(
    Username: $Username
    Clients: $Clients
    Environment: $Environment
  ) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveUserMutationVariables,
  APITypes.RemoveUserMutation
>;
export const storeAudienceReport = /* GraphQL */ `mutation StoreAudienceReport($campaign_id: String!, $outputlocation: String!) {
  storeAudienceReport(
    campaign_id: $campaign_id
    outputlocation: $outputlocation
  )
}
` as GeneratedMutation<
  APITypes.StoreAudienceReportMutationVariables,
  APITypes.StoreAudienceReportMutation
>;
export const deleteStoredQuery = /* GraphQL */ `mutation DeleteStoredQuery(
  $campaign_id: String!
  $Environment: Environment!
  $placeholder: String
) {
  deleteStoredQuery(
    campaign_id: $campaign_id
    Environment: $Environment
    placeholder: $placeholder
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStoredQueryMutationVariables,
  APITypes.DeleteStoredQueryMutation
>;
export const updateUserAccess = /* GraphQL */ `mutation UpdateUserAccess(
  $Environment: Environment!
  $Username: String!
  $Clients: [addUserClientInput]!
) {
  updateUserAccess(
    Environment: $Environment
    Username: $Username
    Clients: $Clients
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserAccessMutationVariables,
  APITypes.UpdateUserAccessMutation
>;
export const addDashboardAccess = /* GraphQL */ `mutation AddDashboardAccess(
  $Clients: [ClientsInput]!
  $Resource: String!
  $Environment: Environment!
  $Username: String!
) {
  addDashboardAccess(
    Clients: $Clients
    Resource: $Resource
    Environment: $Environment
    Username: $Username
  ) {
    Message
    StatusCode
    StatementId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddDashboardAccessMutationVariables,
  APITypes.AddDashboardAccessMutation
>;
export const removeDashboardAccess = /* GraphQL */ `mutation RemoveDashboardAccess(
  $Clients: [ClientsInput]!
  $Resource: String!
  $Environment: Environment!
  $Username: String!
) {
  removeDashboardAccess(
    Clients: $Clients
    Resource: $Resource
    Environment: $Environment
    Username: $Username
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveDashboardAccessMutationVariables,
  APITypes.RemoveDashboardAccessMutation
>;
export const saveTemplates = /* GraphQL */ `mutation SaveTemplates(
  $ClientCode: String!
  $ClientCountry: String!
  $Environment: Environment!
  $TemplateName: String!
  $Placeholders: [TemplatePlaceholderInput]!
) {
  saveTemplates(
    ClientCode: $ClientCode
    ClientCountry: $ClientCountry
    Environment: $Environment
    TemplateName: $TemplateName
    Placeholders: $Placeholders
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SaveTemplatesMutationVariables,
  APITypes.SaveTemplatesMutation
>;
export const saveTemplates_v2 = /* GraphQL */ `mutation SaveTemplates_v2(
  $ClientCode: String!
  $ClientCountry: String!
  $Environment: Environment!
  $TemplateName: String!
  $Placeholders: [TemplatePlaceholderInput]!
) {
  saveTemplates_v2(
    ClientCode: $ClientCode
    ClientCountry: $ClientCountry
    Environment: $Environment
    TemplateName: $TemplateName
    Placeholders: $Placeholders
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SaveTemplates_v2MutationVariables,
  APITypes.SaveTemplates_v2Mutation
>;
export const updateTemplate = /* GraphQL */ `mutation UpdateTemplate(
  $TemplateId: String!
  $TemplateName: String!
  $Placeholders: [TemplatePlaceholderInput]!
) {
  updateTemplate(
    TemplateId: $TemplateId
    TemplateName: $TemplateName
    Placeholders: $Placeholders
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTemplateMutationVariables,
  APITypes.UpdateTemplateMutation
>;
export const updateTemplate_v2 = /* GraphQL */ `mutation UpdateTemplate_v2(
  $TemplateId: String!
  $TemplateName: String!
  $Placeholders: [TemplatePlaceholderInput]!
) {
  updateTemplate_v2(
    TemplateId: $TemplateId
    TemplateName: $TemplateName
    Placeholders: $Placeholders
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTemplate_v2MutationVariables,
  APITypes.UpdateTemplate_v2Mutation
>;
export const deleteTemplate = /* GraphQL */ `mutation DeleteTemplate($TemplateId: String!) {
  deleteTemplate(TemplateId: $TemplateId) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTemplateMutationVariables,
  APITypes.DeleteTemplateMutation
>;
export const saveCustomValue = /* GraphQL */ `mutation SaveCustomValue($CustomValue: CustomPlaceholderInput!) {
  saveCustomValue(CustomValue: $CustomValue) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SaveCustomValueMutationVariables,
  APITypes.SaveCustomValueMutation
>;
export const createFlowchartCampaign = /* GraphQL */ `mutation CreateFlowchartCampaign(
  $campaignId: String!
  $campaignName: String!
  $startDate: AWSDate!
  $endDate: AWSDate!
  $clientCode: String!
  $clientCountry: String!
  $status: FlowchartCampaignStatus
) {
  createFlowchartCampaign(
    campaignId: $campaignId
    campaignName: $campaignName
    startDate: $startDate
    endDate: $endDate
    clientCode: $clientCode
    clientCountry: $clientCountry
    status: $status
  ) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFlowchartCampaignMutationVariables,
  APITypes.CreateFlowchartCampaignMutation
>;
export const setOverviewStatus = /* GraphQL */ `mutation SetOverviewStatus(
  $source: String!
  $severity: Severity!
  $status: OverviewStatus!
  $message: String
  $client: String
  $campaignId: String
) {
  setOverviewStatus(
    source: $source
    severity: $severity
    status: $status
    message: $message
    client: $client
    campaignId: $campaignId
  ) {
    source
    severity
    status
    message
    client
    campaignId
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetOverviewStatusMutationVariables,
  APITypes.SetOverviewStatusMutation
>;
export const updateBriefedBudget = /* GraphQL */ `mutation UpdateBriefedBudget(
  $clientCode: String!
  $clientCountry: String!
  $campaignId: Int!
  $budget: Float!
) {
  updateBriefedBudget(
    clientCode: $clientCode
    clientCountry: $clientCountry
    campaignId: $campaignId
    budget: $budget
  ) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBriefedBudgetMutationVariables,
  APITypes.UpdateBriefedBudgetMutation
>;
export const updateBriefedBudget_v2 = /* GraphQL */ `mutation UpdateBriefedBudget_v2(
  $campaignId: Int!
  $amount: Float!
  $currency: String!
  $brief_type: String!
  $vat_info: Boolean!
  $Environment: Environment!
) {
  updateBriefedBudget_v2(
    campaignId: $campaignId
    amount: $amount
    currency: $currency
    brief_type: $brief_type
    vat_info: $vat_info
    Environment: $Environment
  ) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBriefedBudget_v2MutationVariables,
  APITypes.UpdateBriefedBudget_v2Mutation
>;
export const createCampaign = /* GraphQL */ `mutation CreateCampaign(
  $name: String!
  $startDate: AWSDate!
  $endDate: AWSDate!
  $clientCode: String!
  $clientCountry: String!
  $comments: String
  $additionalInfo: String
  $additionalInfo2: String
) {
  createCampaign(
    name: $name
    startDate: $startDate
    endDate: $endDate
    clientCode: $clientCode
    clientCountry: $clientCountry
    comments: $comments
    additionalInfo: $additionalInfo
    additionalInfo2: $additionalInfo2
  ) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCampaignMutationVariables,
  APITypes.CreateCampaignMutation
>;
export const updateCampaign = /* GraphQL */ `mutation UpdateCampaign(
  $campaignId: Int!
  $clientCode: String!
  $clientCountry: String!
  $name: String
  $startDate: AWSDate
  $endDate: AWSDate
  $budget: Int
  $comments: String
  $additionalInfo: String
  $additionalInfo2: String
) {
  updateCampaign(
    campaignId: $campaignId
    clientCode: $clientCode
    clientCountry: $clientCountry
    name: $name
    startDate: $startDate
    endDate: $endDate
    budget: $budget
    comments: $comments
    additionalInfo: $additionalInfo
    additionalInfo2: $additionalInfo2
  ) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCampaignMutationVariables,
  APITypes.UpdateCampaignMutation
>;
export const updateCampaign_V2 = /* GraphQL */ `mutation UpdateCampaign_V2(
  $CampaignId: Int!
  $Name: String
  $StartDate: AWSDate
  $EndDate: AWSDate
  $Comments: String
  $AdditionalInfo: String
  $AdditionalInfo2: String
) {
  updateCampaign_V2(
    CampaignId: $CampaignId
    Name: $Name
    StartDate: $StartDate
    EndDate: $EndDate
    Comments: $Comments
    AdditionalInfo: $AdditionalInfo
    AdditionalInfo2: $AdditionalInfo2
  ) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCampaign_V2MutationVariables,
  APITypes.UpdateCampaign_V2Mutation
>;
export const switchUserMode = /* GraphQL */ `mutation SwitchUserMode(
  $Organization_id: String!
  $Environment: Environment!
  $UserMode: UserMode!
) {
  switchUserMode(
    Organization_id: $Organization_id
    Environment: $Environment
    UserMode: $UserMode
  ) {
    Client
    Status {
      Message
      StatusCode
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SwitchUserModeMutationVariables,
  APITypes.SwitchUserModeMutation
>;
export const createBriefTemplate = /* GraphQL */ `mutation CreateBriefTemplate(
  $TemplateName: String!
  $OrganizationId: String!
  $Environment: Environment!
  $Columns: [BriefColumn]!
) {
  createBriefTemplate(
    TemplateName: $TemplateName
    OrganizationId: $OrganizationId
    Environment: $Environment
    Columns: $Columns
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBriefTemplateMutationVariables,
  APITypes.CreateBriefTemplateMutation
>;
export const createBrief = /* GraphQL */ `mutation CreateBrief(
  $CampaignId: Int!
  $TemplateId: String!
  $Environment: Environment!
  $Values: [BriefValue]!
) {
  createBrief(
    CampaignId: $CampaignId
    TemplateId: $TemplateId
    Environment: $Environment
    Values: $Values
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBriefMutationVariables,
  APITypes.CreateBriefMutation
>;
export const createDetailedPlanningTemplate = /* GraphQL */ `mutation CreateDetailedPlanningTemplate(
  $TemplateName: String!
  $OrganizationId: String!
  $Environment: Environment!
  $Columns: [DetailedPlanningColumn]!
) {
  createDetailedPlanningTemplate(
    TemplateName: $TemplateName
    OrganizationId: $OrganizationId
    Environment: $Environment
    Columns: $Columns
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDetailedPlanningTemplateMutationVariables,
  APITypes.CreateDetailedPlanningTemplateMutation
>;
export const createDetailedPlanning = /* GraphQL */ `mutation CreateDetailedPlanning(
  $CampaignId: Int!
  $TemplateId: String!
  $Environment: Environment!
  $Values: [PlanningSectionsInput]!
) {
  createDetailedPlanning(
    CampaignId: $CampaignId
    TemplateId: $TemplateId
    Environment: $Environment
    Values: $Values
  ) {
    tableOrder {
      rows {
        section
        values {
          rowId
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDetailedPlanningMutationVariables,
  APITypes.CreateDetailedPlanningMutation
>;
export const createDetailedPlanningOrder = /* GraphQL */ `mutation CreateDetailedPlanningOrder(
  $CampaignId: Int!
  $Environment: Environment!
  $tableOrder: tableOrderInput
) {
  createDetailedPlanningOrder(
    CampaignId: $CampaignId
    Environment: $Environment
    tableOrder: $tableOrder
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDetailedPlanningOrderMutationVariables,
  APITypes.CreateDetailedPlanningOrderMutation
>;
export const deleteDetailedPlanning = /* GraphQL */ `mutation DeleteDetailedPlanning(
  $CampaignId: Int!
  $Environment: Environment!
  $RowIds: [String]!
) {
  deleteDetailedPlanning(
    CampaignId: $CampaignId
    Environment: $Environment
    RowIds: $RowIds
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDetailedPlanningMutationVariables,
  APITypes.DeleteDetailedPlanningMutation
>;
export const updateDetailedPlanning = /* GraphQL */ `mutation UpdateDetailedPlanning(
  $CampaignId: Int!
  $Environment: Environment!
  $Columns: [updateDetailedPlanningInput]!
) {
  updateDetailedPlanning(
    CampaignId: $CampaignId
    Environment: $Environment
    Columns: $Columns
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDetailedPlanningMutationVariables,
  APITypes.UpdateDetailedPlanningMutation
>;
export const updateCampaignStatus = /* GraphQL */ `mutation UpdateCampaignStatus(
  $CampaignId: Int!
  $Status: FlowchartCampaignStatus!
) {
  updateCampaignStatus(CampaignId: $CampaignId, Status: $Status) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCampaignStatusMutationVariables,
  APITypes.UpdateCampaignStatusMutation
>;
export const updateCampaignFee = /* GraphQL */ `mutation UpdateCampaignFee(
  $CampaignId: Int!
  $PlannedFee: Float
  $ActualFee: Float
) {
  updateCampaignFee(
    CampaignId: $CampaignId
    PlannedFee: $PlannedFee
    ActualFee: $ActualFee
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCampaignFeeMutationVariables,
  APITypes.UpdateCampaignFeeMutation
>;
export const updateStoredQueriesOrder = /* GraphQL */ `mutation UpdateStoredQueriesOrder(
  $CampaignId: Int!
  $DashboardType: mediaSource!
  $DashboardSection: DashboardSection!
  $Order: [QueryOrderInput]!
) {
  updateStoredQueriesOrder(
    CampaignId: $CampaignId
    DashboardType: $DashboardType
    DashboardSection: $DashboardSection
    Order: $Order
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStoredQueriesOrderMutationVariables,
  APITypes.UpdateStoredQueriesOrderMutation
>;
export const upgradeClientAccess = /* GraphQL */ `mutation UpgradeClientAccess(
  $Clients: [ClientsInput]!
  $Current_Level: AccessType!
  $Upgraded_Level: AccessType!
  $Environment: Environment
) {
  upgradeClientAccess(
    Clients: $Clients
    Current_Level: $Current_Level
    Upgraded_Level: $Upgraded_Level
    Environment: $Environment
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpgradeClientAccessMutationVariables,
  APITypes.UpgradeClientAccessMutation
>;
export const downgradeClientAccess = /* GraphQL */ `mutation DowngradeClientAccess(
  $Clients: [ClientsInput]!
  $Environment: Environment
) {
  downgradeClientAccess(Clients: $Clients, Environment: $Environment) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DowngradeClientAccessMutationVariables,
  APITypes.DowngradeClientAccessMutation
>;
export const createLink = /* GraphQL */ `mutation CreateLink(
  $input: CreateLinkInput!
  $Organization_id: String!
  $Environment: Environment!
  $clientCodes: [String]
) {
  createLink(
    input: $input
    Organization_id: $Organization_id
    Environment: $Environment
    clientCodes: $clientCodes
  ) {
    ... on linkWithStatus {
      statusCode
      link {
        Id
        title
        linkValue
        icon
        image
        clientCodes {
          ClientCode
          ClientCountry
          __typename
        }
        __typename
      }
    }
    ... on Error {
      type
      message
      statusCode
    }
  }
}
` as GeneratedMutation<
  APITypes.CreateLinkMutationVariables,
  APITypes.CreateLinkMutation
>;
export const deleteLink = /* GraphQL */ `mutation DeleteLink(
  $Organization_id: String!
  $Id: String!
  $Environment: Environment!
) {
  deleteLink(
    Organization_id: $Organization_id
    Id: $Id
    Environment: $Environment
  ) {
    ... on linkWithStatus {
      statusCode
      link {
        Id
        title
        linkValue
        icon
        image
        clientCodes {
          ClientCode
          ClientCountry
          __typename
        }
        __typename
      }
    }
    ... on Error {
      type
      message
      statusCode
    }
  }
}
` as GeneratedMutation<
  APITypes.DeleteLinkMutationVariables,
  APITypes.DeleteLinkMutation
>;
export const updateLink = /* GraphQL */ `mutation UpdateLink(
  $input: UpdateLinkInput!
  $Organization_id: String!
  $Id: String!
  $Environment: Environment!
) {
  updateLink(
    input: $input
    Organization_id: $Organization_id
    Id: $Id
    Environment: $Environment
  ) {
    ... on linkWithStatus {
      statusCode
      link {
        Id
        title
        linkValue
        icon
        image
        clientCodes {
          ClientCode
          ClientCountry
          __typename
        }
        __typename
      }
    }
    ... on Error {
      type
      message
      statusCode
    }
  }
}
` as GeneratedMutation<
  APITypes.UpdateLinkMutationVariables,
  APITypes.UpdateLinkMutation
>;
export const removeClientCode = /* GraphQL */ `mutation RemoveClientCode(
  $Organization_id: String!
  $id: String!
  $clientCodes: [String]
  $Environment: Environment
) {
  removeClientCode(
    Organization_id: $Organization_id
    id: $id
    clientCodes: $clientCodes
    Environment: $Environment
  ) {
    ... on linkWithStatus {
      statusCode
      link {
        Id
        title
        linkValue
        icon
        image
        clientCodes {
          ClientCode
          ClientCountry
          __typename
        }
        __typename
      }
    }
    ... on Error {
      type
      message
      statusCode
    }
  }
}
` as GeneratedMutation<
  APITypes.RemoveClientCodeMutationVariables,
  APITypes.RemoveClientCodeMutation
>;
export const addClientCode = /* GraphQL */ `mutation AddClientCode(
  $Organization_id: String!
  $id: String!
  $Environment: Environment
  $clientCodes: [String]
) {
  addClientCode(
    Organization_id: $Organization_id
    id: $id
    Environment: $Environment
    clientCodes: $clientCodes
  ) {
    statusCode
    link {
      Id
      title
      linkValue
      icon
      image
      clientCodes {
        ClientCode
        ClientCountry
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddClientCodeMutationVariables,
  APITypes.AddClientCodeMutation
>;
export const updateOrderList = /* GraphQL */ `mutation UpdateOrderList(
  $Organization_id: String!
  $Environment: Environment
  $orderList: [String]
) {
  updateOrderList(
    Organization_id: $Organization_id
    Environment: $Environment
    orderList: $orderList
  ) {
    statusCode
    link {
      Id
      title
      linkValue
      icon
      image
      clientCodes {
        ClientCode
        ClientCountry
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderListMutationVariables,
  APITypes.UpdateOrderListMutation
>;
export const updatePlannedMediaBudget = /* GraphQL */ `mutation UpdatePlannedMediaBudget($campaignId: Int!, $budget: Int!) {
  updatePlannedMediaBudget(campaignId: $campaignId, budget: $budget) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlannedMediaBudgetMutationVariables,
  APITypes.UpdatePlannedMediaBudgetMutation
>;
export const logActivity = /* GraphQL */ `mutation LogActivity(
  $Username: String!
  $Action: String!
  $Environment: Environment!
  $Service: String!
  $Url: String
  $Ip: String
) {
  logActivity(
    Username: $Username
    Action: $Action
    Environment: $Environment
    Service: $Service
    Url: $Url
    Ip: $Ip
  ) {
    Message
    StatusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.LogActivityMutationVariables,
  APITypes.LogActivityMutation
>;
