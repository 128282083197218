import { DeleteTemplateMutation, GetQueriesQuery, GetTemplateByIdQuery, ListTemplateResponse, ListTemplatesQuery, ListTemplates_v2Query, SaveTemplatesMutation, SaveTemplates_v2Mutation, TemplatePlaceholder, UpdateTemplateMutation, UpdateTemplate_v2Mutation, UserQuery } from "API";
import { API } from "aws-amplify";
import { deleteTemplate, saveTemplates, saveTemplates_v2, updateTemplate, updateTemplate_v2 } from "graphql/mutations";
import { getQueries, getTemplateById, listTemplates, listTemplates_v2 } from "graphql/queries";
import { createContext, useContext, useEffect, useState } from "react";
import APIContext from "./APIContext";
import { CampaignContext, useCampaignContext } from "./CampaignContext";
import ClientContext, { useClientContext } from "./ClientContext";
import { EnrichedContext } from "./EnrichedContext";
import { set } from "lodash";
import { useGetStoredQueriesDetails } from "customHooks/useGetStoredQueriesDetails";
import { useDragAndDrop } from "customHooks/useDragAndDrop";



type TemplateContextProps = {
    templateSave: (args: any) => void,
    updateTemplatePlaceholderList: (args: any) => void,
    fetchTemplateById: (args: any) => void,
    templateDelete: (args: any) => void,
    templatesList: any[],
    fetchedPlaceholders: TemplatePlaceholder[],
    saveTemplateMessage: string,
    templateName: string,
    setTemplateName: (args: any) => void,
    fetchedTemplateData: fetchedTemplateData,
    templateUpdate: () => void,
    toggleDrag: () => void,
    isDragEnabled: boolean
    /*     fetchedTemplateData: {
            Name: null,
            Id: null,
            CreatedBy: null,
            CreatedAt: null,
            LastEditedBy: null,
            LastEditedAt: null,
        } */
}






export const TemplateContext = createContext<TemplateContextProps | undefined>(undefined)


type fetchedTemplateData = {
    Name: string
    Id: any

} | undefined

interface Metric {
    chartType: string;
    metric: string;
    axis: number;
}

export const TemplateContextProvider = (props: any) => {
    const { activeCampaign, dashType } = useCampaignContext()
    const { activeOrganization } = useClientContext()
    const { getEnvironment } = useContext(APIContext);
    const [templatesList, setTemplatesList] = useState<any>([])
    // const placeholders: any[] = [];
    const [placeholders, setPlaceholders] = useState<any>([])
    const [fetchedPlaceholders, setFetchedPlaceholders] = useState<TemplatePlaceholder[]>([])
    const [saveTemplateMessage, setSaveTemplateMessage] = useState<any>()
    const [templateName, setTemplateName] = useState<string>("")
    const [fetchedTemplateData, setFetchedTemplateData] = useState<fetchedTemplateData>()
    const { REACT_APP_AGENCY } = process.env;
    const [
        storedQueries,
        setStoredQueries,
        tablePlaceholderList,
        setTablePlaceholderList,
        setGraphPlaceholderList,
        graphPlaceholderList,
        publishedQueries,
    ] = useGetStoredQueriesDetails(activeCampaign, undefined, fetchedPlaceholders);
    const { getOrderList } = useDragAndDrop({ tablePlaceholderList });

    useEffect(() => {
        if (activeCampaign) {
            setTemplatesList([])
            setPlaceholders([])
            setFetchedPlaceholders([])
            setSaveTemplateMessage(null)
            setTemplateName("")
            setFetchedTemplateData(undefined)

            activeOrganization !== undefined && fetchTemplateList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCampaign])

    useEffect(() => {
        if (saveTemplateMessage) {
            setTimeout(() => {
                setSaveTemplateMessage(undefined)
            }, 2000)
        }

    }, [saveTemplateMessage])

    useEffect(() => {
        setFetchedPlaceholders([])
        if (activeCampaign && dashType === "TV") {
            fetchTemplateById("c9614b9a-ed63-4a1c-9227-7d2c62247469")
        } else if (activeCampaign && dashType === "RADIO") {
            fetchTemplateById("26126d4e-f347-4441-b36c-7ab7d3598f47")
        } else if (activeCampaign && dashType === "OOH") {
            fetchTemplateById("7974c060-bad2-4048-9ce8-f9dbcc1a0753")
        } else if (activeCampaign && dashType === "PRINT") {
            fetchTemplateById("fd723399-b637-408c-a77a-d69104022dde")
        } else if (activeCampaign && dashType === "CINEMA") {
            fetchTemplateById("fd723399-b637-408c-a77a-kdfkgmsdfkgmsdf")
        }
    }, [activeCampaign, dashType])


    const updateTemplatePlaceholderList = (locationObject: any) => {
        if (dashType === "TV" || dashType === "RADIO") {
            delete locationObject.metrics
        } else {
            if (locationObject.metrics) {
                locationObject.metrics.forEach((e: any) => {
                    delete e.__typename
                })
            }
        }

        const index = placeholders.findIndex((e: any) => e.Placeholder === locationObject.Placeholder);
        if (index === -1) {

            setPlaceholders((ps: any) => [...ps, locationObject])
        } else {
            const newArray = [...placeholders]
            newArray.splice(index)
            newArray.push(locationObject)
            setPlaceholders(newArray)
        }
        //    console.log(placeholders)
    };


    async function templateSave(name: any) {
        try {
            const response = (await API.graphql({
                query: getQueries,
                variables: {
                    campaign_id: activeCampaign?.campaignId?.toString()
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })) as { data: GetQueriesQuery }
            const storedQueries = response.data.getQueries
            const fetchedTableOrder = await getOrderList('Table');
            const fetchedChartOrder = await getOrderList('Chart');
            const orderListBenchmark = fetchedTableOrder?.concat(fetchedChartOrder?.map(e => {
                return {
                    ...e,
                    Order: fetchedTableOrder?.length + e.Order
                }
            }) as any)

            storedQueries?.forEach(e => {

            })
            const placeholders = storedQueries?.filter(e => !e?.position?.startsWith("Summary") && !e?.position?.startsWith("Brief"))
                .map(e => {

                    if (e?.query_id_cc) {
                        return {
                            Location: e?.query_id,
                            Location_cc: e?.query_id_cc,
                            Placeholder: e?.position,
                            Placeholder_titel: e?.title,
                            OrderIndex: orderListBenchmark?.find((el: any) => el.QueryId === e?.position)?.Order,
                            metrics: e?.metrics?.reduce<Metric[]>((acc, current) => {
                                const x = acc.findIndex(item => item.chartType === current?.chartType && item.metric === current.metric && item.axis === current.axis);
                                if (x <= -1) {
                                    const obj = { metric: current?.metric, chartType: current?.chartType, axis: current?.axis }
                                    acc.push(obj as Metric);
                                }
                                return acc;
                            }, [])

                        }
                    } else {
                        return {
                            Location: e?.query_id,
                            Placeholder: e?.position,
                            Placeholder_titel: e?.title,
                            OrderIndex: orderListBenchmark?.find((el: any) => el.QueryId === e?.position)?.Order,
                            metrics: e?.metrics?.reduce<Metric[]>((acc, current) => {
                                const x = acc.findIndex(item => item.chartType === current?.chartType && item.metric === current.metric && item.axis === current.axis);
                                if (x <= -1) {
                                    const obj = { metric: current?.metric, chartType: current?.chartType, axis: current?.axis }
                                    acc.push(obj as Metric);
                                }
                                return acc;
                            }, [])
                        }
                    }

                })
            if (placeholders) {
                saveTemplateAfeterCallingGetStoredQ(name, placeholders)
            }



        } catch (e) {
            console.log("ERROR FETCHING QUARIES", e);
        }
    }






    async function saveTemplateAfeterCallingGetStoredQ(name: any, placeholders: any) {

        try {
            const response = (await API.graphql({
                query: saveTemplates_v2,
                variables: {
                    ClientCode: activeCampaign?.ClientCode,
                    ClientCountry: activeCampaign?.ClientCountry,
                    Environment: getEnvironment,
                    TemplateName: name,
                    Placeholders: placeholders,
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })) as { data: SaveTemplates_v2Mutation }
            setSaveTemplateMessage(response.data.saveTemplates_v2?.Message);
            fetchTemplateList()

        } catch (e) {
            console.log("ERROR SAVING TEMPLATE", e);

        }
    }



    async function templateUpdate() {
        try {
            const response = (await API.graphql({
                query: getQueries,
                variables: {
                    campaign_id: activeCampaign?.campaignId?.toString()
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })) as { data: GetQueriesQuery }
            const storedQueries = response.data.getQueries
            storedQueries?.forEach(e => {

            })
            const placeholders = storedQueries?.filter(e => !e?.position?.startsWith("Summary") && !e?.position?.startsWith("Brief"))
                .map(e => {

                    if (e?.query_id_cc) {
                        return {
                            Location: e?.query_id,
                            Location_cc: e?.query_id_cc,
                            Placeholder: e?.position,
                            Placeholder_titel: e?.title,
                            metrics: e?.metrics?.reduce<Metric[]>((acc, current) => {
                                const x = acc.findIndex(item => item.chartType === current?.chartType && item.metric === current.metric && item.axis === current.axis);
                                if (x <= -1) {
                                    const obj = { metric: current?.metric, chartType: current?.chartType, axis: current?.axis }
                                    acc.push(obj as Metric);
                                }
                                return acc;
                            }, [])

                        }
                    } else {
                        return {
                            Location: e?.query_id,
                            Placeholder: e?.position,
                            Placeholder_titel: e?.title,
                            metrics: e?.metrics?.reduce<Metric[]>((acc, current) => {
                                const x = acc.findIndex(item => item.chartType === current?.chartType && item.metric === current.metric && item.axis === current.axis);
                                if (x <= -1) {
                                    const obj = { metric: current?.metric, chartType: current?.chartType, axis: current?.axis }
                                    acc.push(obj as Metric);
                                }
                                return acc;
                            }, [])
                        }
                    }

                })
            if (placeholders) {
                templateUpdateAfterCAllingStoredQ(templateName, placeholders)
            }



        } catch (e) {
            console.log("ERROR FETCHING QUARIES", e);
        }

    }





    const getCounrty = activeOrganization?.OrganizationId.split("#")[1]
    const countriesList = [
        { name: "Sweden", code: "SE" },
        { name: "Norway", code: "NO" },
        { name: "Denmark", code: "DK" },
        { name: "Finland", code: "FI" },
        { name: "Global", code: "GLOBAL" },
    ]
    const country = countriesList.find(e => e.code === getCounrty)?.name
    const agencyTemplate = {
        Name: `${REACT_APP_AGENCY?.toUpperCase()} ${country} Template`,
        Id: "72d402eb-26c4-4f1e-81c4-62b58ba811a5",
        CreatedBy: `${REACT_APP_AGENCY?.toUpperCase()} ${country}`,
        CreatedAt: "2024-08-06 08:22:33",
        LastEditedBy: "",
        LastEditedAt: "",
        __typename: "ListTemplateResponse"
    } as ListTemplateResponse

    async function fetchTemplateList() {

        try {
            const response = (await API.graphql({
                query: listTemplates_v2,
                variables: {
                    Organization_id: activeOrganization?.OrganizationId,
                    /*     ClientCode: activeCampaign?.ClientCode,
                        ClientCountry: activeCampaign?.ClientCountry, */
                    Environment: getEnvironment,
                    Client: {
                        client: activeCampaign?.ClientCode,
                        country: activeCampaign?.ClientCountry
                    }
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })) as { data: ListTemplates_v2Query }



            const newTemplatesList = response.data.listTemplates_v2?.concat(agencyTemplate)
            setTemplatesList(response.data.listTemplates_v2);
            // setTemplatesList(newTemplatesList);
            // console.log("TEMPLATES LIST", response.data.listTemplates_v2)

        } catch (e) {
            console.log("ERROR FETCHING TEMPLATE LIST", e);

        }
    }


    async function fetchTemplateById(templateID: any) {
        setStoredQueries([])
        try {
            const response = (await API.graphql({
                query: getTemplateById,
                variables: {
                    TemplateId: templateID
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })) as { data: GetTemplateByIdQuery }

            if (response.data.getTemplateById?.Name) {
                setTemplateName(response.data.getTemplateById?.Name)
            }
            const placeholders = response.data.getTemplateById?.Placeholders
            if (placeholders) {
                setFetchedPlaceholders(response.data.getTemplateById?.Placeholders as TemplatePlaceholder[])

            }
            if (response.data.getTemplateById) {
                const { Name, Id } = response.data.getTemplateById
                const newTemplate = { Name: Name ?? "unknown", Id: Id ?? "unknown" }

                setFetchedTemplateData(newTemplate)
            }

            //  setTemplateID(response.data.getTemplateById?.Id)

        } catch (e) {
            console.log("ERROR FETCHING TEMPLATE BY ID", e);

        }
    }

    async function templateUpdateAfterCAllingStoredQ(name: any, placeholders: any) {

        try {
            const response = (await API.graphql({
                query: updateTemplate_v2,
                variables: {
                    TemplateId: fetchedTemplateData?.Id,
                    TemplateName: name,
                    Placeholders: placeholders,
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })) as { data: UpdateTemplate_v2Mutation }
            setSaveTemplateMessage(response.data.updateTemplate_v2?.Message);
            fetchTemplateList()


        } catch (e) {
            console.log("ERROR SAVING TEMPLATE", e);

        }
    }


    async function templateDelete(id: any) {
        try {
            const response = (await API.graphql({
                query: deleteTemplate,
                variables: {
                    TemplateId: id,
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })) as { data: DeleteTemplateMutation }
            setSaveTemplateMessage(response.data.deleteTemplate?.Message);
            /*             setTimeout(() => {
                            setSaveTemplateMessage(undefined)
                        }, 2000) */
            setTemplatesList([])
            activeOrganization !== null && activeOrganization !== undefined && fetchTemplateList()

        } catch (e) {
            console.log("ERROR SAVING TEMPLATE", e);

        }
    }

    useEffect(() => {
        activeOrganization !== null && activeOrganization !== undefined && fetchTemplateList()
    },
        [activeOrganization])

    const [isDragEnabled, setIsDragEnabled] = useState(false);

    const toggleDrag = () => {
        setIsDragEnabled(!isDragEnabled);
    };


    return (
        <TemplateContext.Provider
            value={{
                templateSave,
                updateTemplatePlaceholderList,
                templatesList,
                fetchTemplateById,
                fetchedPlaceholders,
                saveTemplateMessage,
                templateName,
                setTemplateName,
                fetchedTemplateData,
                templateUpdate,
                templateDelete,
                toggleDrag,
                isDragEnabled
            }}>
            {props.children}
        </TemplateContext.Provider>
    )

}

export const useTemplateContext = () => {
    const context = useContext(TemplateContext)
    if (context === undefined) {
        throw new Error("context needs to be provided in template context")
    }
    return context

}