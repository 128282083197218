import OOHLogo from "assets/OOH";
import PrintLogo from "assets/PRINT";
import AdFormLogo from "assets/adform";
import CampaignManagerLogo from "assets/cmLogo";
import RadioLogo from "assets/radio";
import TVLogo from "assets/tv";
import { FaSnapchatSquare } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { SiFacebook, SiYoutube, SiTiktok } from "react-icons/si";
import LinkedinLogo from "assets/linkedin"
import ReadpeakLogo from "assets/readpeak";
import TaboolaLogo from "assets/taboola";
import CinemaLogo from "assets/cinema";

const logoArray = [
    {
        name: 'Adform',
        icon: <AdFormLogo className="adformicon" />,
        metaName: 'DATASOURCE#ADF',

    },
    {
        name: 'DV360',
        icon: <FcGoogle style={{ fontSize: '1.5rem', marginBottom: '-5px', marginRight: `8px` }} />,
        metaName: 'DATASOURCE#DV360',

    },

    {
        name: 'CM',
        // icon: <FcVoicemail style={{ fontSize: '1.5rem', marginBottom: '-5px', marginRight: `8px`, color: `#FF0000` }} />,
        icon: <CampaignManagerLogo className="cmicon" height="1.5rem" />,
        // icon: <SVGComponent className="cmicon" height="5rem" />,
        metaName: 'DATASOURCE#CM',

    },
    {
        name: 'Facebook',
        icon: <SiFacebook style={{ fontSize: '1.5rem', marginBottom: '-5px', marginRight: `8px`, color: `#4267B2` }} />,
        metaName: 'DATASOURCE#FB',

    }, {
        name: 'Youtube',
        icon: <SiYoutube style={{ fontSize: '1.5rem', marginBottom: '-5px', marginRight: `8px`, color: `#FF0000` }} />,
        metaName: 'DATASOURCE#GAD',

    }, {
        name: 'TikTok',
        icon: <SiTiktok style={{ fontSize: '1.5rem', marginBottom: '-5px', marginRight: `8px` }} />,
        metaName: 'DATASOURCE#TIK',

    }, {
        name: 'Snapchat',
        icon: (
            <FaSnapchatSquare style={{ fontSize: '1.5rem', marginBottom: '-5px', marginRight: `8px`, color: `	#FFFC00` }} />
        ),
        metaName: 'DATASOURCE#SNAP',

    }, {
        name: "LinkedIn",
        icon: (
            <LinkedinLogo className="adformicon" />
        ),
        metaName: "DATASOURCE#LI",

    }, {
        name: 'TV',
        icon: <TVLogo />,
        metaName: 'DATASOURCE#ADE',

    }, {
        name: 'RADIO',
        icon: <RadioLogo />,
        metaName: "DATASOURCE#ADR",

    }, {
        name: 'OOH',
        icon: <OOHLogo />,
        metaName: 'DATASOURCE#OOH',

    }, {
        name: 'PRINT',
        icon: <PrintLogo />,
        metaName: 'DATASOURCE#PRINT',

    }, {
        name: 'readpeak',
        icon: <ReadpeakLogo></ReadpeakLogo>,
        metaName: 'DATASOURCE#RP',


    }, {
        name: 'Pinterest',
        icon: <FaPinterest style={{ fontSize: '1.5rem', marginBottom: '-5px', marginRight: `8px`, color: `	#E60023` }} />,
        metaName: 'DATASOURCE#PI',
    },
    {
        name: 'CINEMA',
        icon: <CinemaLogo />,
        metaName: 'DATASOURCE#CINEMA',

    },
    {
        name: 'DV360(YouTube)',
        icon: <FcGoogle style={{ fontSize: '1.5rem', marginBottom: '-5px', marginRight: `8px` }} />,
        metaName: 'DATASOURCE#DV360_YOUTUBE',

    },
    {
        name: 'Taboola',
        icon: <TaboolaLogo ></TaboolaLogo>,
        metaName: 'DATASOURCE#TAB',
    }

]

export const platformLogoSelector = (platform: string) => {
    const logo = logoArray.find(el => el.name === platform)
    return logo?.icon
}