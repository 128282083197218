import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DarkButton, IconButton } from 'components/style/Global';
import BriefInput2 from './2.1.BriefInput';
import { BriefContainer } from './style/BriefInputStyle';
import { useClientContext } from 'context/ClientContext';
import { API } from 'aws-amplify';
import { getBrief_v2, getQueries, getStoredQuery } from 'graphql/queries';
import {
  GetBriefQuery,
  BriefValue,
  GetQueriesQuery,
  GetStoredQueryQuery,
  UserQuery,
  GetBrief_v2Query,
  briefTemplateSection,
  briefTemplateSectionColumns,
  ValueResponse,
  FlowchartCampaignStatus,
  UpdateCampaignStatusMutation,
} from 'API';
import { useSearchParams, useBlocker, useActionData } from 'react-router-dom';
import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import APIContext from 'context/APIContext';
import { createBrief, updateBriefedBudget, updateCampaignStatus } from 'graphql/mutations';
import BriefBulletin from './BriefBulletin';
import { VscEdit } from 'react-icons/vsc';
import UploadFile3 from './UploadFile/2.2.UploadFile';
import { getFilesFromS3 } from './UploadFile/getFilesfromS3';
import { updateBriefBudgetNumber } from 'methods/updateBriefBudget';
import { usePortalContext } from 'context/PortalContext';
import { useCampaignId } from 'customHooks/useParameters';
import { useUpdateCampaignStatus } from './CustomHook/useUpdateCampaignStatus';
import { object } from 'prop-types';
import { useCheckRollbackStatus } from 'customHooks/useCheckRollbackStatus';
import useUnsavedChangesWarning from 'customHooks/useUnsavedChangesWarning';

import type {
  Blocker as Blocker,
  BlockerFunction as BlockerFunction,
} from "react-router-dom";

import { HeadModul, Modal } from 'components/NewPortal/DrawerModal Styled';
import { ModulContainer } from 'components/Main/table/TableStyles';


type Props = {
};
export interface QueryInfo {
  id: string;
  position: string;
  name: string;
  file?: File; // optional
}

interface BudgetCurrencyType {
  Id: string;
  Value?: {
    IntValue?: any;
    StringValue?: any;
  };
}

const Brief = (props: Props) => {
  const { getEnvironment } = useContext(APIContext);
  // const { budgeBrief, setBudgetBrief} = useContext(PortalContext)
  const { activeCampaign, campaignList, setPermanentCampaignList, updatePermanentCampaignList } = useCampaignContext();
  const { briefTemplate, briefTemplateID, fetchBriefTemplate, activeOrganization } = useClientContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [briefValues, setBriefValues] = useState<(BriefValue & { Id: string }[]) | null>(null);
  // const [/* dataFromClient, setDataFromClient, */ enrichedBriefTemplate/* , changeValueInput, showSavedButton, checkRequired, briefValues */] = useBriefDataFromClient(briefTemplate, storedBrief)
  const [uploadedFiles, setUploadedFiles] = useState<QueryInfo[]>([]);
  const [editBrief, setEditBrief] = useState(false);
  const [fetchedBrief, setFetchedBrief] = useState<briefTemplateSection[] | undefined>(undefined);
  const [briefExists, setBriefExists] = useState<boolean>(false);
  const { setBudgetBrief, setBudgetListPerRender } = usePortalContext();
  const [rollbackStatus] = useCheckRollbackStatus(activeCampaign)
  const [isDirty, setIsDirty] = useState(false);

  const createValuesArray = (brief: briefTemplateSection[]) => {
    const v = brief
      ?.map((section) => {
        const b = section.columns?.map((column) => {
          if (column?.subColumns && column?.subColumns?.length > 0) {
            const s = column.subColumns?.map((subColumn) => {
              return {
                Section: section.sectionName,
                Label: subColumn?.name,
                Value: subColumn && {
                  [subColumn?.valueType as keyof typeof subColumn.value]:
                    subColumn.name === 'StartDate'
                      ? activeCampaign?.startDate
                      : subColumn.name === 'EndDate'
                        ? activeCampaign?.endDate
                        : (subColumn?.value as ValueResponse)[subColumn?.valueType as keyof typeof subColumn.value],
                },
                Type: subColumn?.type,
                Placeholder: subColumn?.placeholder,
                Id: subColumn?.id,
              };
            });
            return s;
          } else {
            return {
              Section: section.sectionName,
              Label: column?.name,
              Value: column && {
                [column?.valueType as keyof typeof column.value]:
                  column.name === 'CampaignName'
                    ? activeCampaign?.campaign
                    : (column?.value as ValueResponse)[column?.valueType as keyof typeof column.value],
              },
              Type: column?.type,
              Placeholder: column?.placeholder,
              Id: column?.id,
            };
          }
        });
        return b;
      })
      .flat(2);
    return v;
  };

  const uploadNewFile = (id: string, position: string, file: File) => {
    setUploadedFiles((prev) => [...prev, { id: id, position: position, name: id.split('_')[1], file: file }]);
  };

  useEffect(() => {
    async function fetchUploadedFiles(): Promise<QueryInfo[]> {
      try {
        const response = (await API.graphql({
          query: getQueries,
          variables: {
            campaign_id: activeCampaign?.campaignId?.toString(10),
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: GetQueriesQuery };
        // console.log('this is in the end what I am looking after', response);
        if (response.data.getQueries) {
          const res = response.data.getQueries;

          const queries = res.filter((query) => query?.position?.includes('BriefUploadFile'));
          return queries.map((q) => {
            return { id: q?.query_id ?? '', position: q?.position ?? '', name: q?.query_id?.split('_')[1] ?? '' };
          });
        }
        return [] as QueryInfo[];
      } catch (e) {
        console.error('ERROR FETCHING QUARIES', e);
        return [] as QueryInfo[];
      }
    }

    const addToFileList = async () => {
      const queryInfos = await fetchUploadedFiles();

      setUploadedFiles(queryInfos);
    };

    const getBriefNEW = async () => {
      try {
        const response = (await API.graphql({
          query: getBrief_v2,
          variables: {
            Environment: getEnvironment,
            CampaignId: activeCampaign?.campaignId,
            OrganizationId: activeOrganization?.OrganizationId,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: GetBrief_v2Query };
        // console.log("response", response)

        const brief = response.data.getBrief_v2?.sections as briefTemplateSection[];

        const sortedBrief = brief.map((section) => {
          const sortedColumns = section.columns?.filter(col => col?.name !== "CampaignName").sort((a, b) => {
            if (a?.placeholder && b?.placeholder) {
              if (Number(a.placeholder) > Number(b.placeholder)) {
                return 1;
              } else {
                return -1;
              }
            } else {
              return 0;
            }
          });
          const sortedSubColumns = sortedColumns?.map((column) => {
            if (column?.subColumns && column?.subColumns?.length > 0) {
              const sortedSubColumns = column.subColumns?.filter(sub => sub?.name !== "StartDate" && sub?.name !== "EndDate").sort((a, b) => {
                if (a?.placeholder && b?.placeholder) {
                  if (Number(a.placeholder) > Number(b.placeholder)) {
                    return 1;
                  } else {
                    return -1;
                  }
                } else {
                  return 0;
                }
              });
              return { ...column, subColumns: sortedSubColumns };
            } else {
              return column;
            }
          });
          return { ...section, columns: sortedSubColumns };
        });

        //

        setFetchedBrief(sortedBrief);
        const a = sortedBrief
          .map((section) => {
            const c = section.columns
              ?.map((column) => {
                if (column?.subColumns && column?.subColumns?.length > 0) {
                  const s = column.subColumns
                    ?.map((subColumn) => {
                      // if any property inside the value object is different from null return true
                      const value = subColumn?.value as ValueResponse;
                      const { __typename, ...rest } = value;
                      const valueIsNotNull = Object.values(rest).some((value) => value !== null);

                      return valueIsNotNull;
                    })
                    .some((value) => value === true);
                  return s;
                } else {
                  const value = column?.value as ValueResponse;
                  const { __typename, ...rest } = value;
                  const valueIsNotNull = Object.values(rest).some((value) => value !== null);
                  return valueIsNotNull;
                }
              })
              .some((value) => value === true);
            return c;
          })
          .some((value) => value === true);

        setBriefExists(a);
        if (a) {
          setEditBrief(false);
        } else {
          setEditBrief(true);
        }

        if (sortedBrief.length > 0) {
          setBriefValues(
            createValuesArray(sortedBrief as briefTemplateSection[]) as unknown as BriefValue & { Id: string }[],
          );
        } else {
          setBriefValues(
            createValuesArray(sortedBrief as briefTemplateSection[]) as unknown as BriefValue & { Id: string }[],
          );
        }
      } catch (e) {
        console.log(`ERROR FETCHING THE NEW BRIEF WAS THIS:`, e);
      }
    };

    // HERE IS CALLED THE FIRST TIME ADDTOFILELIST AND THAT WILL TRIGGER ANTHER FUNCTION THAT WILL UPDATE setUploadedFiles
    if (activeCampaign && searchParams.get('tab') === 'brief') {
      addToFileList();
      getBriefNEW();
    }
  }, [activeCampaign, briefTemplate, getEnvironment, searchParams]);

  type bV = {
    Section: string;
    Label: string;
    Value: any;
    Type: string;
    Placeholder: string;
  };

  const [UpdateCampaign] = useUpdateCampaignStatus()

  const saveBrief = async () => {


    // if (activeCampaign?.status === "CREATED") {
    //   const result = UpdateCampaign(activeCampaign, FlowchartCampaignStatus.BRIEF_COMPLETED) //TODO: AFTER API UPDATE: change to BRIEFED
    // }



    // try {
    //   const response = (await API.graphql({
    //     query: updateCampaignStatus,
    //     variables: {
    //       CampaignId: activeCampaign?.campaignId,
    //       Status: FlowchartCampaignStatus.BRIEF_COMPLETED,
    //       // Environment: "PRODUCTION",
    //     },
    //     authMode: 'AMAZON_COGNITO_USER_POOLS',
    //   })) as { data: UpdateCampaignStatusMutation };

    //   console.log('This is the response for the Campaign', response);
    // } catch (e) {
    //   console.log('error saving brief status', e);
    // }


    const budget = briefValues?.find((briefValue: any) => briefValue.Label === 'Amount') as BudgetCurrencyType;
    const currency2 = briefValues?.find((briefValue: any) => briefValue.Label === 'Currency') as BudgetCurrencyType;

    const budgetValue = budget?.Value?.IntValue;
    const currencyValue = currency2?.Value?.StringValue;






    const bV = briefValues?.map((briefValue: any) => {
      const vv = briefValue.Value;
      const value = Object.values(vv)[0] === activeCampaign?.campaign || Object.values(vv)[0] === activeCampaign?.startDate || Object.values(vv)[0] === activeCampaign?.endDate ? null : Object.values(vv)[0]
      return {
        Section: briefValue.Section,
        Label: briefValue.Label,
        Value: {
          [Object.keys(vv)[0]]: value
        },
        Type: briefValue.Type,
        Placeholder: briefValue.Placeholder,
      };


      // return {
      //   Section: briefValue.Section,
      //   Label: briefValue.Label,
      //   Value: briefValue.Value,
      //   Type: briefValue.Type,
      //   Placeholder: briefValue.Placeholder,
      // };
    });
    // check if bv.Value is an object and if it is an object check if it has a property that is different from null
    // if all properties are null then return "downgrade"
    // if one of the properties is different from null then return "upgrade"
    const status = activeCampaign?.status === "PLANNING_STARTED" ? "PLANNING_STARTED" : "PLANNING_DONE"
    const briefStatus = bV?.flatMap((bv) => {
      const vv = bv.Value;
      if (vv === null) {
        return null
      } else {
        if (Object.values(vv).some((value) => value !== null)) {
          return "upgrade"
        } else {
          return null
        }
        // if (Object.values(vv !== null)) {
        //   return "upgrade"
        // } else {
        //   return null
        // }
      }
    }).some((value) => value === "upgrade") ? rollbackStatus?.hasPlan ? status : "BRIEF_COMPLETED" : rollbackStatus?.hasPlan ? "PLANNING_STARTED" : "CREATED"






    try {
      const response = (await API.graphql({
        query: createBrief,
        variables: {
          TemplateId: briefTemplateID,
          CampaignId: activeCampaign?.campaignId,
          Environment: getEnvironment,
          Values: bV,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetBriefQuery };


      UpdateCampaign(activeCampaign, FlowchartCampaignStatus[briefStatus])
      const campaign = campaignList?.find((campaign: any) => campaign.campaignId === activeCampaign?.campaignId);
      const newCampaign = {
        ...campaign,
        briefed_amount: Number(budgetValue),
        briefed_currency: currencyValue,
        status: FlowchartCampaignStatus[briefStatus],
      } as ACdetails;

      const newCampaignList = campaignList?.map((campaign: ACdetails) => {
        if (campaign?.campaignId === activeCampaign?.campaignId) {
          return newCampaign;
        } else {
          return campaign;
        }
      }) as ACdetails[];

      setPermanentCampaignList(newCampaignList);

      setBriefValues(null);
      fetchBriefTemplate();
      setEditBrief(false);
    } catch (e) {
      console.log('error saving brief', e);
    }

    setIsDirty(false);
  };

  const updateBriefValues = (e: { e: any; id: string }) => {
    const kopi = briefValues?.map((briefValue) => {
      if (briefValue.Id === e.id) {
        return {
          ...briefValue,
          Value: {
            [Object.keys((briefValue as BriefValue & { Id: string }).Value)[0] as string]: e.e === ''
              || e.e === activeCampaign?.campaign
              || e.e === activeCampaign?.startDate
              || e.e === activeCampaign?.endDate
              ? null : e.e
          },
        };
      } else {
        return briefValue;
      }
    });

    // if one of the items items in budget has changed tha call updateBriefBudgetNumber

    setBriefValues(kopi as unknown as BriefValue & { Id: string }[]);
    setIsDirty(true);
  };

  useEffect(() => {
    // setBudgetBrief()
    const budget = briefValues?.find((briefValue: any) => briefValue.Label === 'Amount') as BudgetCurrencyType;
    const currency = briefValues?.find((briefValue: any) => briefValue.Label === 'Currency') as BudgetCurrencyType;

    const budgetValue = budget?.Value?.IntValue;
    const currencyValue = currency?.Value?.StringValue;

    setBudgetBrief({ budget: budgetValue, currency: currencyValue });
  }, [briefValues]);

  const getValueProps = (id: string) => {
    const foundElement = briefValues?.find((brief) => brief.Id === id) as BriefValue & { Id: string };
    if (foundElement) {
      return foundElement.Value[Object.keys(foundElement.Value)[0] as keyof typeof foundElement.Value];
    } else {
      return '';
    }
  };

  const openFile = async (queryInfo: QueryInfo) => {
    console.count('is this one open when it is refreshed ??');
    const file = queryInfo.file ?? (await fetchStoredQueriesID(queryInfo.position));

    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  };

  async function fetchStoredQueriesID(position: string): Promise<File> {
    try {
      const response = (await API.graphql({
        query: getStoredQuery,
        variables: {
          campaign_id: activeCampaign?.campaignId?.toString(),
          position: position,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetStoredQueryQuery };
      const responseID = response.data.getStoredQuery;

      const file = await getFilesFromS3(
        responseID as UserQuery,
        activeCampaign?.ClientCode as string,
        activeCampaign?.ClientCountry as string,
        getEnvironment,
      );

      if (file) {
        setUploadedFiles((prev) =>
          prev.map((e) => {
            if (e.id === responseID?.query_id) {
              return { ...e, file: file };
            } else {
              return e;
            }
          }),
        );
      }

      return file as File;
    } catch (e) {
      console.log(`ERROR WAS THIS: ${e}`);
      return {} as File;
    }
  }
  /*   useUnsavedChangesWarning(editBrief, "You have unsaved changes, are you sure you want to leave?") */
  // Allow the submission navigation to the same route to go through
  let shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return isDirty && currentLocation.search !== nextLocation.search;
    },
    [isDirty]
  );
  let blocker = useBlocker(shouldBlock);



  function ConfirmNavigation({ blocker }: { blocker: Blocker }) {
    if (blocker.state === "blocked") {
      return (
        <Modal>
          <HeadModul style={{ height: "fit-content", position: "relative", minWidth: "0", justifyContent: "space-around", alignItems: "center", margin: "auto" }}>
            <ModulContainer>
              <h1 style={{ color: "red" }}>
                You have unsaved changes, are you sure you want to leave?
              </h1>
            </ModulContainer>
            <ModulContainer style={{ flexDirection: "row", gap: "5px" }}>

              <button onClick={() => blocker.proceed?.()}>Let me through</button>
              <button onClick={() => blocker.reset?.()}>Keep me here</button>
            </ModulContainer>
          </HeadModul>
        </Modal>
      );
    }

    if (blocker.state === "proceeding") {
      return (
        <p style={{ color: "orange" }}>Proceeding through blocked navigation</p>
      );
    }

    return null;
  }

  return (
    <BriefContainer>
      {fetchedBrief && briefValues ? (
        <>
          {
            /* !briefExists &&  */ editBrief ? (
              <form>

                <div>
                  {fetchedBrief?.map((briefSection) => (
                    <div className="headerSection" key={briefSection.sectionName}>
                      <h1>{briefSection.sectionName}</h1>
                      <div>
                        {briefSection.columns?.map((inputProps) => (
                          <BriefInput2
                            savedValue={getValueProps(inputProps?.id as string)}
                            briefValues={briefValues}
                            inputProps={inputProps as briefTemplateSectionColumns}
                            onChange={updateBriefValues}
                            key={inputProps?.name}
                          ></BriefInput2>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>

                <UploadFile3
                  openFile={openFile}
                  setUploadedFiles={setUploadedFiles}
                  editBrief={editBrief}
                  uploadedFiles={uploadedFiles}
                  uploadNewFile={uploadNewFile}
                  position="Brief"
                ></UploadFile3>

                <div style={{ marginTop: '30px ' }}>
                  <button onClick={() => setEditBrief(false)} type="button">
                    Cancel
                  </button>
                  <DarkButton type="button" onClick={() => saveBrief()}>
                    Save
                  </DarkButton>
                </div>

              </form>
            ) : (
              <div>
                <IconButton style={{ position: 'absolute', right: '60px' }} onClick={() => setEditBrief(true)}>
                  <VscEdit />
                  Edit brief
                </IconButton>
                {fetchedBrief?.map((briefSection) => (
                  <div className="headerSection" key={briefSection.sectionName}>
                    <h1>{briefSection.sectionName}</h1>
                    <div>
                      {briefSection.columns?.map((inputProps) => (
                        <div key={inputProps?.id}>
                          <BriefBulletin
                            key={inputProps?.id}
                            inputProps={inputProps as briefTemplateSectionColumns}
                          ></BriefBulletin>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                <UploadFile3
                  openFile={openFile}
                  setUploadedFiles={setUploadedFiles}
                  editBrief={editBrief}
                  uploadedFiles={uploadedFiles}
                  uploadNewFile={uploadNewFile}
                  position="Brief"
                ></UploadFile3>
              </div>
            )
          }
        </>
      ) : (
        <div>
          <p>
            The data is loading
            <span className="loadingDot">.</span>
            <span className="loadingDot">.</span>
            <span className="loadingDot">.</span>
          </p>
        </div>
      )}

      {blocker ? <ConfirmNavigation blocker={blocker} /> : null}


    </BriefContainer>
  );
};




export default Brief;
