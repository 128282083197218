import React, { memo, useEffect, useRef, useState } from 'react';
import { EditedList } from '../PlanItem';
import { useUpdateRow } from '../useFetchPlanData';
import { Environment } from 'API';
import { ACdetails } from 'context/CampaignContext';
import { IActiveOrganization } from 'context/ClientContext';
import useAutosizeTextArea from 'customHooks/useAutosizeTextArea';
import { set } from 'lodash';

type TextAreaProps = {
  value: string;
  column: any;
  setValue: (value: React.SetStateAction<string>) => void;
  setEditedList: (value: React.SetStateAction<EditedList>) => void;
  row: any;
  getEnvironment?: Environment;
  activeCampaign?: ACdetails;
  activeOrganization?: IActiveOrganization;
  setIsRowAdded?: React.Dispatch<React.SetStateAction<boolean>>;
  setData?: React.Dispatch<React.SetStateAction<any[]>>;
  setError?: React.Dispatch<React.SetStateAction<string | null>>;
  error?: string | null;
  textAreaRef?: HTMLTextAreaElement | null;
  setTextAreaRef?: React.Dispatch<React.SetStateAction<HTMLTextAreaElement | null>>
};

const TextAreaComponent: React.FC<TextAreaProps> = memo((props) => {
  const {
    column,
    setValue,
    value,
    setEditedList,
    row,
    getEnvironment,
    activeCampaign,
    activeOrganization,
    setIsRowAdded,
    setData,
    setError,
    setTextAreaRef,
    textAreaRef,
    error
  } = props;
  const [localValue, setLocalValue] = useState(props.value);
  const [prevLocalValue, setPrevLocalValue] = useState(props.value);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const getEnv = getEnvironment ? getEnvironment : Environment.PRODUCTION;
  const actCamp: ACdetails | any = activeCampaign ? activeCampaign : {};
  const actOrg: IActiveOrganization | any = activeOrganization ? activeOrganization : { OrganizationId: '' };

  const [callUpdateApi] = useUpdateRow(getEnv, actCamp, actOrg);

  const textAreaRefOriginal = useRef<HTMLTextAreaElement>(null);
  const [height, setHeight] = useState("10px")
  // useAutosizeTextArea(textAreaRef.current, localValue);
  // useEffect(()=> {
  //   if(textAreaRef !== undefined) {
  //     setTextAreaRef !== undefined && setTextAreaRef(textAreaRefOriginal.current)
  //   }
  // },[])

  useEffect(() => {
    if (textAreaRefOriginal !== undefined && textAreaRefOriginal !== null) {
      if (textAreaRefOriginal.current !== null) {
        const scrollHeight = textAreaRefOriginal.current.scrollHeight;
        // textAreaRef.style.height = scrollHeight + "px";
        setHeight(scrollHeight + "px")
      }
    }
  }, [localValue])


  const updateTheRow = async (value: string, editedColumn: string, rowId: string) => {

    setData !== undefined &&
      setData((prevData) => {
        const updatedData = prevData.map((item) => {
          if (item.rowId === rowId) {
            return {
              ...item,
              [editedColumn]: value,
            };
          }
          return item;
        });

        return updatedData;
      });

    setIsRowAdded !== undefined && setIsRowAdded(true);

    let newColumn = [];

    const newValue = {
      RowId: rowId,
      ColumnId: editedColumn,
      Value: {
        StringValue: value,
      },
    };

    newColumn.push(newValue);



    const updateResult = await callUpdateApi(newColumn);
    if (updateResult === null) {
      setError !== undefined && setError("There has been an error on the server, please try again later");
    }


    setPrevLocalValue(localValue);
  };

  useEffect(() => {
    if (textAreaRef !== null && textAreaRef !== undefined) {
      textAreaRef.focus();
    }
  }, [textAreaRef])

  return (
    <textarea
      value={localValue}
      ref={textAreaRefOriginal}
      style={{
        border: 'none',
        fontFamily: 'FontRegular',
        height: height,
        resize: 'none',
        backgroundColor: 'transparent',
        color: localValue === column.header ? 'lightgrey' : 'black',
        marginLeft: '5px',
        width: column?.header === 'Reasons for Touchpoint' || column?.header === 'Comments' ? '96.5%' : '',

      }}
      onClick={(event: any) => {
        const target = event.target as HTMLInputElement;
        if (localValue === column.header) {
          target.select();
        }
      }}
      onInput={(event: any) => {
        if (localValue === column.header) {
          setValue('');
        }
      }}
      onChange={(event: any) => {
        const newValue = event.target.value;
        setLocalValue(newValue);

        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        // Set a new timeout
        const newTimeoutId = setTimeout(() => {
          const editedColumn = column.accessorKey as string;
          const rowId = row['rowId'];

          if (newValue !== prevLocalValue) {
            updateTheRow(newValue, editedColumn, rowId);

            if (textAreaRef !== null && textAreaRef !== undefined) {
              textAreaRef.focus();
            }
          }
        }, 2000);

        setTimeoutId(newTimeoutId);
      }}
      onBlur={() => {
        const editedColumn = column.accessorKey as string;
        // const rowId = row.original['rowId'];
        const rowId = row['rowId'];

        setValue(localValue);

        if (localValue.trim() !== '') {
          setEditedList((prev) => ({
            ...prev,
            [rowId]: {
              ...prev[rowId],
              [editedColumn]: localValue,
              rowId: rowId,
            },
          }));
        }

        if (localValue !== prevLocalValue) {
          updateTheRow(localValue, editedColumn, rowId);
        }
      }}
    />
  );
});

export default TextAreaComponent;
